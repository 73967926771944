import Axios from "axios";

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

axios.interceptors.request.use(
  function(config) {
    try {
      return config;
    } catch (error) {
      return config;
    }
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    console.log({error});
    // return error.response || error;
    return Promise.reject(error);
  }
);

export default axios;
