import axios from "@/api/entradaslima";

const state = () => ({
  departments: [],
  provinces: [],
  districts: []
});

const getters = {
  getDepartments(state) {
    return state.departments;
  },
  getProvinces: state => departmentId => {
    if (!departmentId) return [];
    return state.provinces.filter(
      prov => prov.CODIGO.indexOf(`${departmentId}`) == 0
    );
  },
  getDistricts: state => provinceId => {
    if (!provinceId) return [];
    return state.districts.filter(
      dis => dis.CODIGO.indexOf(`${provinceId}`) == 0
    );
  }
};

const mutations = {
  setDepartments(state, departments) {
    state.departments = departments;
  },
  setProvinces(state, provinces) {
    state.provinces = provinces;
  },
  setDistricts(state, districts) {
    state.districts = districts;
  }
};

const actions = {
  async fetchDepartments({ rootState, commit }) {
    try {
      const { status, data } = await axios.get("/ubigeo", {
        params: {
          // accion: "consultarUbigeo",
          idioma: rootState.lang,
          opcion: 1 // 1 => departments
        }
      });
      if (status != 200) return;
      const response = data.data;
      commit("setDepartments", response);
    } catch (error) {}
  },
  async fetchProvinces({ rootState, commit }) {
    try {
      const { status, data } = await axios.get("/ubigeo", {
        params: {
          // accion: "consultarUbigeo",
          idioma: rootState.lang,
          opcion: 2 // 2 => provinces
        }
      });
      if (status != 200) return;
      const response = data.data;
      commit("setProvinces", response);
    } catch (error) {}
  },
  async fetchDistricts({ rootState, commit }) {
    try {
      const { status, data } = await axios.get("/ubigeo", {
        params: {
          // accion: "consultarUbigeo",
          idioma: rootState.lang,
          opcion: 3 // 3 => districts
        }
      });
      if (status != 200) return;
      const response = data.data;
      commit("setDistricts", response);
    } catch (error) {}
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
