<template>
  <v-form ref="formLogin" class="py-4" @submit.prevent="login">
    <v-container>
      <v-row>
        <!-- <v-col cols="12" lg="12" sm="12" class="pb-0">
          <div
            class="d-flex flex-column justify-content-center align-items-center"
          >
            <i class="fas fa-lock c-tertiary login-icon"></i>
            <div class="title-1 mt-3">
              <h5 class="login-title readable">{{ loginTitle[lang] }}</h5>
            </div>
          </div>
        </v-col> -->
        <v-col cols="12" lg="12" sm="12" class="pb-0">
          <v-alert
            v-if="error.visible"
            border="left"
            class="readable"
            dense
            type="error"
          >
            <strong v-html="error.message[lang]"></strong>
          </v-alert>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1">
          <v-text-field
            :label="emailText[lang]"
            :rounded="rounded"
            :color="color"
            :dark="dark"
            :background-color="bgColor"
            :rules="ruleEmail"
            :disabled="disabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            v-model="payload.email"
            class="readable"
            prepend-inner-icon="mdi-account"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-0 pt-0">
          <v-text-field
            :label="passwordText[lang]"
            :rounded="rounded"
            :color="color"
            class="readable"
            :dark="dark"
            :background-color="bgColor"
            :rules="required"
            :disabled="disabled"
            :hide-details="details"
            :outlined="outlined"
            :filled="filled"
            :dense="dense"
            :autocomplete="autocomplete"
            type="password"
            v-model="payload.password"
            prepend-inner-icon="mdi-lock"
            :solo="solo"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0">
          <v-btn
            :disabled="loading"
            :loading="loading"
            dark
            depressed
            type="submit"
            class="d-block w-100 readable"
            color="tertiary"
            :class="{ [roundedFieldclass]: roundedFieldclass }"
          >
            {{ signInLabel[lang] }}
          </v-btn>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-3 pt-1">
          <p class="text-center text-medium">{{ access[lang] }}</p>
          <div class="d-flex justify-content-center">
            <button
              @click="loginWithFacebook"
              type="button"
              class="social-option fb mx-1 readable"
              title="Login Facebook"
            >
              <i class="fab fa-facebook-f"></i>
            </button>
            <div id="buttonDiv"></div>
            <!--
            <button
              @click="loginWithGoogle"
              type="button"
              class="social-option google mx-1 readable"
              title="Login Google"
            >
              <i class="fab fa-google"></i>
            </button>
            -->
          </div>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-1">
          <v-alert
            v-if="googleError.visible"
            outlined
            border="left"
            dense
            type="error"
          >
            <strong>{{ googleError[lang] }}</strong>
          </v-alert>
          <v-alert
            v-if="fbError.visible"
            outlined
            border="left"
            dense
            type="error"
          >
            <strong>{{ fbError[lang] }}</strong>
          </v-alert>
        </v-col>
        <v-col cols="12" lg="12" sm="12" class="pb-1 pt-0">
          <div class="d-flex justify-content-center py-2">
            <!-- <button
              type="button"
              class="text-link c-primary text-medium readable"
              
              @click="$emit('goingToRegister')"
            >
              {{ registerLabel[lang] }}
            </button> -->
            <router-link
              to="/recuperar-cuenta"
              class="text-link c-primary text-medium"
              >{{ recover[lang] }}</router-link
            >
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import baseConfig from "./baseConfig";
import { mapGetters, mapActions } from "vuex";
import { emailValidator } from "@/libs/validators.js";
import { initFbsdk } from "@/plugins/fbAuth";
import { initGooglesdk } from "@/plugins/gAuth";
export default {
  data() {
    return {
      ...baseConfig,
      ruleEmail: [this.validateEmail],
      error: {
        visible: false,
        en: "Invalid email or password",
        es: "Correo o contraseña inválidos",
        message: {
          en: "Invalid email or password",
          es: "Correo o contraseña inválidos",
        },
      },
      invalidEmail: {
        en: "Wrong email",
        es: "Correo incorrecto",
      },
      loading: false,
      googleError: {
        visible: false,
        en: "An error occurred logging in with Google. It appears that your account is not registered by this means of authentication.",
        es: "Ocurrió un error iniciando sesión con Google. Al parecer su cuenta no está registrada por este medio de autenticación.",
      },
      fbError: {
        visible: false,
        en: "An error occurred logging in with Facebook",
        es: "Ocurrió un error iniciando sesión con Facebook",
      },
      payload: {
        email: "",
        password: "",
      },
      loginTitle: {
        en: "Sign in",
        es: "Iniciar Sesión",
      },
      emailText: {
        en: "E-mail",
        es: "Correo electrónico",
      },
      passwordText: {
        en: "Password",
        es: "Contraseña",
      },
      signInLabel: {
        en: "Log in",
        es: "Ingresar",
      },
      registerLabel: {
        en: "Sign up here",
        es: "Registrate aquí",
      },
      recover: {
        en: "Did you forget your password?",
        es: "¿Olvidaste tu contraseña?",
      },
      access: {
        en: "Or sign in with",
        es: "O ingresa con",
      },
    };
  },
  watch: {
    "payload.email"(val) {
      this.payload.email = String(val)
        .replace(/ /g, "")
        .trim()
        .toLowerCase();
    },
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
  },
  mounted() {
    initFbsdk();
    initGooglesdk(this);
  },
  methods: {
    ...mapActions({
      signIn: "signInValidate",
      register: "registerUser",
    }),
    validateEmail(v) {
      return emailValidator(v) || this.invalidEmail[this.lang];
    },
    validateForm() {
      return this.$refs.formLogin.validate();
    },
    async login() {
      if (!this.validateForm()) return;
      this.loading = true;
      const res = await this.signIn({
        vUsuario: this.payload.email,
        vClave: this.payload.password,
        iOrigen: 1,
      });
      this.loading = false;
      if(res ===  true) return;

      this.error.message = res;
      this.error.visible = true;

    },
    loginWithGoogle: async function(payload) {
      try {
        const res = await this.register({
          ...payload,
        });
        if (!res) {
          this.googleError.visible = true;
          setTimeout(() => {
            this.googleError.visible = false;
          }, 12000);
          return;
        }
        this.googleError.visible = false;
      } catch (error) {}
    },
    loginWithFacebook() {
      window.FB.login(
        (response) => {
          if (response && response.authResponse) {
            window.FB.api(
              "/me?fields=first_name, last_name, picture, email",
              async (response) => {
                const payload = {
                  idUsuario: 0,
                  vUsuario: response.email,
                  vClave: response.id,
                  vNombres: response.first_name,
                  vApellidos: response.last_name,
                  iOrigen: 2, // Facebook
                  result: response,
                  foto:
                    response.picture && response.picture.data
                      ? response.picture.data.url
                      : "",
                  iTipoDocumento: 1,
                  vNumeroDocumento: "",
                  registerWithSocial: true,
                };
                const res = await this.register(payload);
                if (!res) {
                  this.fbError.visible = true;
                  setTimeout(() => {
                    this.fbError.visible = false;
                  }, 3000);
                  return;
                }
              }
            );
          }
        },
        { scope: "public_profile, email" }
      );
    },
  },
};
</script>

<style lang="scss">
.text-link {
  color: #444 !important;
  text-decoration: none;
}
.text-link:hover {
  color: var(--color-blue) !important;
}
.login-icon {
  font-size: 25px;
}
.title-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 3px;
    background: var(--color-blue);
  }
}
.login-title {
  font-size: 16px;
  background: #fff;
  position: relative;
  padding: 3px 8px;
}
.social-option {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #fff;
}

.social-option.fb {
  background: #007bff;
}
.social-option.google {
  background: #dc3545;
}
</style>
