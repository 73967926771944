<template>
    <div class="link-wrapper">
        <a v-if="paramsRoute.type == 'external'" target="_blank"  class="card-link" :href="paramsRoute.externalURL">
            <slot name="content"></slot>
        </a>
        <router-link v-else  :to="paramsRoute.params" class="card-link" >
             <slot name="content"></slot>
        </router-link>
    </div>
</template>

<script>
    export default {
        props: {
            paramsRoute: {
                type: Object,
                default: {
                    type: "local",
                    externalURL: "#",
                    params: "#"
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.link-wrapper {
    width: 100%;
    height: 100%;
}
.link-wrapper > a{
    text-decoration: none;
   height: 100%;
   width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 1.55em 1em;
}
</style>