import axios from "../api/entradaslima";
import defaultAxios from "axios";
import { encBtoa, decAtob } from "@/libs/base64.js";
import Swal from "sweetalert2";
const state = () => ({
  authMenu: false,
  tabMenu: "tab-login",
  loggedIn: false,
  user: null,
  tokenCaptcha: null,
});

const getters = {
  getAuthMenu(state) {
    return state.authMenu;
  },
  getTabMenu(state) {
    return state.tabMenu;
  },
  isLoggedIn(state) {
    return state.loggedIn;
  },
  getSessionUser(state) {
    return state.user;
  },
  getTokenCaptcha(state) {
    return state.tokenCaptcha;
  },
};

const mutations = {
  setAuthMenu(state, bool) {
    state.authMenu = bool;
  },
  setTabMenu(state, tab) {
    state.tabMenu = tab;
  },
  setLoggedIn(state, bool) {
    state.loggedIn = bool;
  },
  setUser(state, user) {
    state.user = user;
  },
  setTokenCaptchaUser(state, token) {
    state.tokenCaptcha = token;
  },
};

const actions = {
  changeTabMenu({ commit }, tab) {
    commit("setTabMenu", tab);
  },
  changeAuthMenu({ commit }, bool) {
    commit("setAuthMenu", bool);
  },
  async saveUserOnLS({}, user) {
    if (!user) return;
    const b64user = encBtoa(JSON.stringify(user));
    const encriptedU = await this.dispatch("encodeData", b64user);
    localStorage.setItem("session", JSON.stringify(encriptedU));
  },
  async getUserFromLS({ commit }) {
    try {
      let user = JSON.parse(localStorage.getItem("session"));
      if (!user) return null;
      const b64user = await this.dispatch("decodeResponse", user);
      user = decAtob(b64user);
      user = JSON.parse(user);
      if (!user.IDUSUARIO) return null;
      if (!user.CORREO) return null;
      return user;
    } catch (error) {
      return null;
    }
  },
  async loadUserFromLS({ commit, dispatch }) {
    try {
      const user = await this.dispatch("getUserFromLS");
      if (!user) return;
      //const expTime = (user.SESSION_DATE + (60000 * 30)) // 30 minutos
      //if (expTime < new Date().getTime()) return; // si expira el tiempo de sesión al recargar se cierra la sesión
      commit("setLoggedIn", true);
      commit("setUser", user);
      await this.dispatch("fetchMyShoppingsList");
    } catch (error) {
      console.log(error);
    }
  },
  async signInValidate({ state, commit, dispatch, rootState }, payload) {
    try {
      const endpoint = "/usuarios/login";
      const loginPayload = {
        usuario: payload.vUsuario,
        clave: payload.vClave,
        origen: payload.iOrigen || 1,
        idSistema: 2,
      };
      const { status, data } = await axios.post(endpoint, loginPayload);
      if (status != 200) return false;
      const response = data.data; // await dispatch("decodeResponse", data.data);
      if (!response)
        return {
          en: "Invalid email or password",
          es: "Correo o contraseña inválidos",
        };
      if (response.VALIDADO == 0) {
        console.log("invalido");
        return {
          en: `Your account is not verified.<br/>- Enter your email inbox<br/>- Look for the subject "Complete your registration"<br/>Confirm your account registration to access.`,
          es: `Tu cuenta no está verificada.<br/>- Ingresa a tu bandeja de correo<br/>- Busca el asunto "Completa tu registro"<br/>- Confirma el registro de tu cuenta para acceder.`,
        };
      }
  
      let clientSocialId = null;
      let clientSocialPicture = "";
      const isSocialLogin = (payload.metadata && Object.keys(payload.metadata).length) || (payload.result && Object.keys(payload.result).length > 0)

      if (isSocialLogin) {
        const socialData = payload.metadata || payload.result;
        switch (payload.iOrigen) {
          case 3: // Google
            clientSocialId = socialData.sub;
            clientSocialPicture = socialData.picture;
            break;
          default:
            clientSocialId = null;
            clientSocialPicture = "";
        }
      }
  
      let user = {
        ...response /* [0] */,
        SESSION_DATE: Date.now(),
        URLFOTO: clientSocialPicture,
        SOCIAL_ID: clientSocialId, // 
        METADATA: payload.metadata || payload.result
      };
  
      /* if (payload.metadata && Object.keys(payload.metadata).length > 0) {
        const userUpdated = await dispatch("updateUser", {
          idUsuario: user.IDUSUARIO,
          vUsuario: user.CORREO,
          vClave: payload.metadata.sub,
          iOrigen: payload.iOrigen,
          vNombres: user.NOMBRES,
          vApellidos: user.APELLIDOS,
          iTipoDocumento: user.TIPODOCUMENTO,
          vNumeroDocumento: user.NUMERODOCUMENTO,
          foto: user.URLFOTO,
          result: payload.metadata,
          hideMessage: true,
        });
        console.log({ userUpdated });
      } */
  
      commit("setAuthMenu", false);
      commit("setLoggedIn", true);
      commit("setUser", user);
      await dispatch("saveUserOnLS", user);
      this.dispatch("fetchMyShoppingsList");
  
      return true;
      
    } catch (error) {
      console.log(error);
      return payload.metadata && Object.keys(payload.metadata).length ? false /* {
        en: "Your account is not registered with this means of authentication.",
        es: "Su cuenta no se encuentra registrada con este medio de autenticación.",
      } */ : {
        en: "Invalid email or password",
        es: "Correo o contraseña inválidos",
      };
    }
  },
  async recoverAccount({ state, commit, dispatch, rootState }, payload) {
    try {
      const endpoint = "/usuarios/restablecer";
      const body = {
        idioma: rootState.lang,
        correo: payload.vUsuario,
      };
      const { status, data } = await axios.post(endpoint, body);
      if (status != 200) return null;
      const response = data.data; // await this.dispatch("decodeResponse", data.data);
      return response;
    } catch (error) {
      return null;
    }
  },
  async registerUser({ state, commit, rootState, dispatch }, payload) {
    try {
      this.commit("activeLoader", true);
      const endpoint = "/usuarios";
      const newUser = {
        correo: payload.vUsuario,
        clave: payload.vClave,
        origen: payload.iOrigen,
        nombres: payload.vNombres,
        apellidos: payload.vApellidos,
        tipoDocumento: payload.iTipoDocumento,
        numeroDocumento: payload.vNumeroDocumento ? payload.vNumeroDocumento : undefined,
        metadata: payload.result ? payload.result : {},
        idioma: rootState.lang,
      };
      const { status, data } = await axios.post(endpoint, newUser);
      this.commit("activeLoader", false);
      if (status != 200) throw new Error(data.message);

      const info = data.data; //await dispatch("decodeJSON", data.data);

      if (
        info.IDUSUARIO != "0" &&
        info.ESTADO == 0 &&
        !payload.registerWithSocial
      ) {
        const message = {
          title: {
            en: "Thanks for signing up!",
            es: "¡Gracias por registrarte!",
          },
          description: {
            en: `We have sent an account verification email to <strong>"${newUser.mail}"</strong>.<br/>Go to your email inbox and verify your account to log in.`,
            es: `Hemos enviado un correo de verificación de cuenta a <strong>"${newUser.correo}"</strong>.<br/>Ingresa a tu bandeja de correo y verifica tu cuenta para iniciar sesión.`,
          },
          button: {
            en: "Accept",
            es: "Aceptar",
          },
        };
        Swal.fire({
          icon: "success",
          title: message.title[rootState.lang],
          html: message.description[rootState.lang],
          confirmButtonColor: "#074882",
          confirmButtonText: message.button[rootState.lang],
          allowOutsideClick: true,
        });
        dispatch("changeTabMenu", "tab-login");
        return false;
      }

      if (!info) return false;

      if (
        info.IDUSUARIO != "0" &&
        info.ESTADO == "1" &&
        !payload.registerWithSocial
      ) {
        await this.dispatch("generateSnackbarError", {
          en: "Email is already in use",
          es: "El correo ya se encuentra en uso",
        });
        return false;
      }

      this.commit("activeLoader", true);

      dispatch("changeTabMenu", "tab-login");

      const res = await dispatch("signInValidate", payload);

      this.commit("activeLoader", false);

      if (!res) return false;

      if (payload.idUsuario != 0) {
        await dispatch("generateMessageStatus", {
          title: {
            en: "Updated Data",
            es: "Datos actualizados",
          },
          type: "error",
          html: {
            es: `<p class="text-center"><span > Tus datos se actualizaron correctamente </span> </p>`,
            en: `<p class="text-center"><span > Your profile data has been successfully updated </span> </p>`,
          },
          type: "success",
          confirmButtonText: {
            en: "Ok",
            es: "Aceptar",
          },
          allowOutsideClick: false,
        });
      }
      return true;
    } catch (error) {
      console.log(error);
      const errorMessage = error?.response?.data?.message;
      this.commit("activeLoader", false);
      await this.dispatch("generateSnackbarError", {
        en: errorMessage || "An error ocurred while registering your account.",
        es: errorMessage || "Ocurrió un error cuando se registraba tu cuenta",
      });
      return false;
    }
  },
  async updateUser({ state, dispatch, commit }, payload) {
    try {
      const idUsuario = payload.idUsuario;
      const endpoint = `/usuarios/${idUsuario}`;
      const updatePayload = {
        correo: payload.vUsuario,
        clave: payload.vClave,
        origen: payload.iOrigen,
        nombres: payload.vNombres,
        apellidos: payload.vApellidos,
        tipoDocumento: payload.iTipoDocumento,
        numeroDocumento: payload.vNumeroDocumento,
        foto: payload.foto,
        metadata: payload.result ? payload.result : {},
      };
      const { status, data } = await axios.put(endpoint, updatePayload);
      if (status != 200) return false;
      if (!payload.hideMessage) {
        await dispatch("generateMessageStatus", {
          title: {
            en: "Updated Data",
            es: "Datos actualizados",
          },
          type: "error",
          html: {
            es: `<p class="text-center"><span > Tus datos se actualizaron correctamente </span> </p>`,
            en: `<p class="text-center"><span > Your profile data has been successfully updated </span> </p>`,
          },
          type: "success",
          confirmButtonText: {
            en: "Ok",
            es: "Aceptar",
          },
          allowOutsideClick: false,
        });
      }
      const userLS = await dispatch("getUserFromLS");
      const userUpdated = {
        ...userLS,
        NOMBRES: payload.vNombres,
        APELLIDOS: payload.vApellidos,
        NUMERODOCUMENTO: payload.vNumeroDocumento,
        TIPODOCUMENTO: payload.iTipoDocumento,
      }
      await dispatch("saveUserOnLS", userUpdated);
      commit("setUser", userUpdated);
      return data.data;
    } catch (error) {
      return null;
    }
  },
  async activateAccount({}, { correo = "", idUsuario }) {
    try {
      const endpoint = `/usuarios/${idUsuario}/activar`;
      const { status, data } = await axios.put(endpoint, {
        correo,
      });
      if (status != 200) return false;
      return true;
    } catch (error) {
      return false;
    }
  },
  async restorePassword({}, { idUsuario, correo, password }) {
    try {
      const endpoint = `/usuarios/${idUsuario}/cambioclave`;
      const { status, data } = await axios.put(endpoint, {
        correo,
        clave: password,
      });
      if (status != 200) return false;
      return data.data;
    } catch (error) {
      return false;
    }
  },
  async generateCaptchaToken({ commit }, action = "SearchPage") {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(function() {
        grecaptcha
          .execute(process.env.VUE_APP_SITE_KEY, { action: "SearchPage" })
          .then(function(token) {
            resolve(token);
          })
          .catch((err) => reject(null));
      });
    });
    const token = await getToken();
    return token;
  },
  async validateCaptcha({ dispatch }, { token, defaultScore = 0.5 }) {
    try {
      const endpoint = "/servicios/recaptcha";
      const { status, data } = await axios.get(endpoint, {
        params: {
          // accion: "validarRecaptcha",
          // iIdSistema: 2,
          // recaptchaToken: token,
          token: token,
        },
      });
      if (status != 200) return false;
      if (!data.success) return false;
      if (data.score <= defaultScore) return false;
      return true;
    } catch (error) {
      return false;
    }
  },
  logout({ commit }) {
    commit("setAuthMenu", false);
    commit("setLoggedIn", false);
    commit("setUser", null);
    this.commit("setShoppingsList", []);
    localStorage.removeItem("session");
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
