<template>
  <div class="lm--logo-app">
    <img draggable="false" :src="source" :alt="description ? description : `Logo ${appName}`">
  </div>
</template>
<script>
export default {
  props: {
    source: String,
    description: String
  },
  data() {
    return {
      appName: process.env.VUE_APP_BASE_NAME
    }
  }
}
</script>
<style lang="scss">
.lm--logo-app {
  img {
    max-height: 45px; 
    @media screen and (max-width: 1000px){
      max-height: 40px; 
    }
    @media screen and (max-width: 700px){
      max-height: 38px; 
    }
    @media screen and (max-width: 400px){
      max-height: 34px; 
    }
  }
}
.lm--logo-app.big-logo {
  img {
   max-height: 60px; 
   @media screen and (max-width: 1000px){
      max-height: 50px; 
    }
    @media screen and (max-width: 700px){
      max-height: 45px; 
    }
    @media screen and (max-width: 400px){
      max-height: 40px; 
    }
  }
}
</style>
