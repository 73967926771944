import { transformDate } from "../utils/date";

const state = () => ({
  step: 1,
  stepsData: {
    step1: {
      title: {
        en: "Category",
        es: "Categoría",
      },
      subtitle: {
        en: "Category is important to continue",
        es: "La categoría es importante para continuar",
      },
      buttonNext: {
        en: "Continue",
        es: "Continuar",
      },
    },
    step2: {
      title: {
        en: "Tickets",
        es: "Entradas",
      },
      subtitle: {
        en: "These fields are required to continue with the payment",
        es: "Estos campos son requeridos para continuar con el pago",
      },
      buttonNext: {
        en: "Continue",
        es: "Continuar",
      },
      buttonCancel: {
        en: "Go back",
        es: "Regresar",
      },
      tableHeaders: {
        col1: {
          en: "Ticket type",
          es: "Tipo de entrada",
        },
        col2: {
          en: "Price",
          es: "Precio",
        },
        col3: {
          en: "Quantity",
          es: "Cantidad",
        },
      },
    },
    step3: {
      title: {
        en: "Additional process",
        es: "Proceso adicional",
      },
      subtitle: {
        en: "",
        es: "",
      },
      buttonNext: {
        en: "Continue",
        es: "Continuar",
      },
      buttonCancel: {
        en: "Cancel",
        es: "Cancelar",
      },
    },
    step4: {
      title: {
        en: "Payment",
        es: "Pago",
      },
      subtitle: {
        en:
          "Fill in the fields of the form and proceed to pay with your preferred means of payment",
        es:
          "Rellena los campos del formulario y procede a pagar con tu medio de pago preferido",
      },
      titleFree: {
        en: "Register",
        es: "Registro",
      },
      subtitleFree: {
        en:
          "Complete all the fields of the form and proceed to complete your free registration",
        es:
          "Completa todos los campos del formulario y procede a culminar tu registro gratuito",
      },
      buttonNext: {
        en: "Finalize",
        es: "Finalizar",
      },
      buttonCancel: {
        en: "Go back",
        es: "Regresar",
      },
    },
  },
  baseIndex: 1569878,
  snackbar: {
    value: false,
    timeout: 4000,
  },
  btnIncrement: false,
  warningsList: {
    category: {
      en: "Select a category to continue",
      es: "Selecciona una categoría para continuar",
    },
    date: {
      en: "Select your visit date",
      es: "Selecciona tu fecha de visita",
    },
    article: {
      en: "You cannot continue without a chosen item",
      es: "No puedes continuar sin un artículo elegido",
    },
    quantity: {
      en: "Select an item to continue",
      es: "Selecciona un artículo para continuar",
    },
    notFree: {
      en: "The amount cannot be zero",
      es: "El importe no puede ser 0",
    },
    onlyFree: {
      en: "The current configuration is only for free sale",
      es: "La configuración actual es solo para venta gratuita",
    },
    user: {
      en: "",
      es: "",
    },
  },
  errors: [],

  finalPayload: {
    category: null,
    dateSelected: "",
    schedule: null,
    globalAforo: false,
    dataRequestSchedules: {
      categoryId: 0,
    },
    configuration: {
      HORARIO: 0,
      RUTA: 0,
    },
    route: null,
    user: null,
    horarioCategoria: null,
    butacas: [],
  },
});
const mutations = {
  setStep(state, step) {
    state.step = step;
  },
  setCategory(state, category) {
    state.finalPayload.category = category;
  },
  setDate(state, date) {
    state.finalPayload.dateSelected = date;
  },
  setSchedule(state, schedule) {
    state.finalPayload.schedule = schedule;
  },
  setRouteSelected(state, route) {
    state.finalPayload.route = route;
  },
  setGlobalAforo(state, bool) {
    state.finalPayload.globalAforo = bool;
  },
  setConfigurationProcess(state, conf) {
    state.finalPayload.configuration = conf;
  },
  setUserPayment(state, user) {
    state.finalPayload.user = user;
  },
  updateBaseIndex(state) {
    state.baseIndex += 150;
  },
  changeSnackbar(state, bool) {
    state.snackbar.value = bool;
  },
  setHorarioCategoria(state, time) {
    state.finalPayload.horarioCategoria = time;
  },
  setButacasList(state, butacas = []) {
    state.finalPayload.butacas = JSON.parse(JSON.stringify(butacas));
  },
};

const getters = {
  getDateSelected(state) {
    return state.finalPayload.dateSelected;
  },
  getStep(state) {
    return state.step;
  },
  getBtnIncrement(state) {
    return state.btnIncrement;
  },
  getStepsData(state) {
    return state.stepsData;
  },
  getFinalPayload(state) {
    return state.finalPayload;
  },
  getCategorySelected(state) {
    return state.finalPayload.category;
  },
  getBaseIndex(state) {
    return state.baseIndex;
  },
  getErrorsList(state) {
    return state.errors;
  },
  getSnackbar(state) {
    return state.snackbar;
  },
  getScheduleSelected: (state, getters, rootState) => {
    if (!state.finalPayload.schedule) return null;
    const localSchedule = state.finalPayload.schedule;
    const schedule = rootState.schedules.find(
      (item, i) =>
        `${i + 1}-${item.HORAINICIO}-${item.HORAFIN}` == localSchedule
    );
    return schedule;
  },
  getIfGlobalAforo(state) {
    return state.finalPayload.globalAforo;
  },
  getRouteSelected(state) {
    return state.finalPayload.route;
  },
  getConfigurationProccess(state) {
    return state.finalPayload.configuration;
  },
  haveControlStock(state) {
    return state.finalPayload.category &&
      state.finalPayload.category.CONTROLSTOCK != 0
      ? true
      : false;
  },
  validateCombination(state) {
    return state.finalPayload.category &&
      state.finalPayload.category.COMBINAR != 0
      ? true
      : false;
  },
  validateAnticipated(state) {
    return state.finalPayload.category &&
      state.finalPayload.category.HABILITARCOMPRAANTICIPADA == 1
      ? true
      : false;
  },
  validateLotery(state) {
    return state.finalPayload.category &&
      state.finalPayload.category.HABILITARSORTEO == 1
      ? true
      : false;
  },
  validateFree(state) {
    return state.finalPayload.category &&
      state.finalPayload.category.PERMITIRGRATUITAS == 1
      ? true
      : false;
  },
  contieneIdForo(state) {
    // Mapa es escenario
    return (
      state.finalPayload?.category?.IDFORO &&
      state.finalPayload?.category?.IDFORO != 0
    );
  },
  getFechaSeleccionadaOInicial(state, getters, rootState) {
    const date =
      rootState.dateSelected ||
      state.finalPayload?.dateSelected ||
      (rootState.sale.finalPayload?.category
        ? rootState.sale.finalPayload?.category?.FECHADEFAULT
        : "");

    return date;
    // return date?.includes("/") ? transformDate(date) : date;
  },
  getButacasList(state) {
    return state.finalPayload.butacas;
  },
};

const actions = {
  async changeStep({ state, rootState, getters, commit }, step) {
    try {
      const park = this.state.currentDomainPark;
      switch (step) {
        case 1:
          // clear 2, 3 y 4
          commit("setDate", "");
          commit("setSchedule", null);
          commit("setHorarioCategoria", null);
          commit("setUserPayment", null);
          break;
        case 2:
          console.log("step 2");
          const category = state.finalPayload?.category;
          const dateSelected = getters.getFechaSeleccionadaOInicial;
          this.commit("activeLoader", true);
          await this.dispatch("loadDates", {});
          // console.log({ category, dateSelected, payload: state?.finalPayload });
          if (category && getters.contieneIdForo > 0 && dateSelected) {
            // Consulta la lista de horarios por categoria
            const resultHorarios = await this.dispatch(
              "fetchCategoriaHorarios",
              {
                idCategoria: category.IDCATEGORIA,
                fechaVisita: dateSelected,
                setListaInicial: true
              }
            );
            // Setea por defecto el primer horario
            resultHorarios.length == 1 &&
              commit("setHorarioCategoria", resultHorarios[0]);
          }
          await this.dispatch("loadArticlesList");
          // clear 3 y 4
          commit("setSchedule", null);
          commit("setButacasList", []);
          commit("setUserPayment", null);
          this.commit("activeLoader", false);
          break;
        case 3:
          this.commit("activeLoader", true);
          //this.dispatch("getAditionalProccess")
          this.commit("activeLoader", false);
          break;
        case 4:
          break;
      }
      commit("setStep", step);

      setTimeout(() => {
        document.body.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        document.documentElement.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }, 100);
    } catch (error) {
      console.log(error);
    }
  },
  async validateStep2({ state, commit, dispatch, getters }) {
    state.errors = [];

    // Sin categoría seleccionada
    if (!state.finalPayload.category) {
      state.errors.push({ ...state.warningsList.category });
      commit("changeSnackbar", true);
      return false;
    }

    // Sin fecha
    if (
      !state.finalPayload.dateSelected &&
      state.finalPayload.category.HABILITARCOMPRAANTICIPADA == 0
    ) {
      state.errors.push({ ...state.warningsList.date });
      commit("changeSnackbar", true);
      return false;
    }

    // No permite Gratuito
    if (
      state.finalPayload.category.PERMITIRGRATUITAS == 0 &&
      this.getters.getArticlesTotal == 0
    ) {
      state.errors.push({ ...state.warningsList.notFree });
      commit("changeSnackbar", true);
      return false;
    }

    if (
      state.finalPayload.category.PERMITIRGRATUITAS == 1 &&
      this.getters.getArticlesTotal > 0
    ) {
      state.errors.push({ ...state.warningsList.onlyFree });
      commit("changeSnackbar", true);
      return false;
    }

    // Sin artículos
    const articles = this.state.articlesList;
    if (!articles || articles.length == 0) {
      state.errors.push({ ...state.warningsList.article });
      commit("changeSnackbar", true);
      return false;
    }

    // Sin artículo seleccionado
    const haveItem = articles.findIndex((it) => it.CANTIDADSELECCIONADA > 0);
    if (haveItem == -1) {
      state.errors.push({ ...state.warningsList.quantity });
      commit("changeSnackbar", true);
      return false;
    }

    // Validate min
    let hasErrorMin = false;
    articles.map((art) => {
      if (art.CANTIDADSELECCIONADA > 0) {
        const min = getMinQuantityByArticle(
          art,
          parseFloat(this.state.currentDomainPark.CANTIDADMINIMA) ||
            parseFloat(this.state.currentDomainPark.CANTIDADMINIMAESPECIAL)
        );
        const description = getDescriptionArticle(art);
        if (art.CANTIDADSELECCIONADA < min) {
          hasErrorMin = true;
          state.errors.push({
            en: `The minimum number of tickets purchased for the concept ${description} is ${min}`,
            es: `La cantidad mínima de entradas adquiridas para el concepto ${description} es ${min}`,
          });
        }
      }
    });

    if (hasErrorMin) {
      commit("changeSnackbar", true);
      return false;
    }

    return true;
  },
  generateSnackbarError({ state, commit }, error) {
    state.errors = [];
    state.errors.push({ ...error });
    commit("changeSnackbar", true);
  },
  async changeCategory({ commit }, category) {
    commit("setCategory", category);
    commit("updateBaseIndex");
  },
  async changeRouteSelected({ state, commit }, routeId) {
    this.commit("setSchedules", []);
    commit("setSchedule", null);
    commit("setRouteSelected", routeId);
    this.commit("activeLoader", true);
    await this.dispatch("fetchSchedules", {
      categoryId: state.finalPayload.category
        ? state.finalPayload.category.IDCATEGORIA
        : 0,
      dateSelected: state.finalPayload.dateSelected,
      option: 1,
      setList: true,
      routeId,
    });
    this.commit("activeLoader", false);
  },
  changeSchedule({ commit }, schedule) {
    commit("setSchedule", schedule);
  },
  async incrementTicket({ state, getters, dispatch }, article) {
    state.btnIncrement = true;

    const canIncreaseGroup = await dispatch("validateGroup", article);

    state.btnIncrement = false;

    if (!canIncreaseGroup) return false;

    state.btnIncrement = true;
    const canIncreseMax = await dispatch("validateMax", { article });
    state.btnIncrement = false;

    if (!canIncreseMax) return false;

    state.btnIncrement = false;

    const idx = this.state.articlesList.findIndex(
      (it) => it.IDARTICULO == article.IDARTICULO
    ); // Buscar para cambiar la cantidad seleccionada
    if (idx == -1) return false;

    // FINALIZADO: VALIDAR AFORO POR HORARIO PARA LOS QUE TENGAN IDFORO != 0
    if (getters.contieneIdForo) {
      // Validando articulo
      const tieneAforoArticulo = await dispatch(
        "validarAforoArticulo",
        { idArticulo: article.IDARTICULO, tipo: "validar_incremento" }
      );
      console.log(
        "Validando aforo articulo: " + article.IDARTICULO,
        tieneAforoArticulo
      );
      if (!tieneAforoArticulo) return false;
    }

    const cantidad = this.state.articlesList[idx].CANTIDADSELECCIONADA;
    this.state.articlesList[idx].CANTIDADSELECCIONADA = cantidad + 1;

    return true;
  },
  validateGroup({ state }, article) {
    const allowsGroup =
      state.finalPayload.category.COMBINAR != "0" ? true : false;
    const haveArticleSelected = this.state.articlesList.findIndex(
      (it) => it.CANTIDADSELECCIONADA > 0 && article.IDARTICULO != it.IDARTICULO
    );
    if (!allowsGroup && haveArticleSelected > -1) return false;
    return true;
  },
  async validateMax({ state, dispatch }, { article, isValidationToSave }) {
    let max = await dispatch("getMaxQuantity", article); // Cantidad máxima por cada fila de artículo
    let selected = 0;

    this.state.articlesList.forEach((it) => {
      selected += it.CANTIDADSELECCIONADA;
    });

    if (
      state.finalPayload.category &&
      state.finalPayload.category.CONTROLSTOCK != "0"
    ) {
      // const currentArticle = getArticleInObject(article)
      // article.CANTIDADSELECCIONADA * parseFloat(currentArticle.CANTIDADVALOR) <= max;
      // ((article.CANTIDADSELECCIONADA + 1) * parseFloat(currentArticle.CANTIDADVALOR)) <= max;
      if (isValidationToSave) return article.CANTIDADSELECCIONADA <= max;
      return article.CANTIDADSELECCIONADA < max;
    }

    const allowsGroup = await dispatch("validateGroup", article);

    if (allowsGroup && article.CANTIDADSELECCIONADA < max) {
      // Si permite combinar y la cantidad por fila de articulo no excede su cantidad máxima
      // entonces se valida que la combinatoría no exceda la cantidad máxima por categoría o parque
      let anotherMax =
        parseFloat(state.finalPayload.category.CANTIDADMAXIMA) ||
        parseFloat(this.state.currentDomainPark.CANTIDADMAXIMA) ||
        parseFloat(this.state.currentDomainPark.CANTIDADMAXIMAESPECIAL) ||
        max;
      max = anotherMax;
    }

    if (
      allowsGroup &&
      isValidationToSave &&
      article.CANTIDADSELECCIONADA <= max
    ) {
      let anotherMax =
        parseFloat(state.finalPayload.category.CANTIDADMAXIMA) ||
        parseFloat(this.state.currentDomainPark.CANTIDADMAXIMA) ||
        parseFloat(this.state.currentDomainPark.CANTIDADMAXIMAESPECIAL) ||
        max;
      max = anotherMax;
    }

    if (isValidationToSave) {
      return selected <= max;
    }
    return selected < max;
  },
  getTotalPurchased({ state }, article) {
    const level = article.LEVELS;
    let totalPurchased = article.CANTIDADCOMPRADA;

    if (level == 2) {
      if (!article.selectedLvl1) return 0;
      totalPurchased = parseFloat(article.selectedLvl1.CANTIDADCOMPRADA);
    } else if (level == 3) {
      if (!article.selectedLvl2) return 0;
      totalPurchased = parseFloat(article.selectedLvl2.CANTIDADCOMPRADA);
    }

    return totalPurchased;
  },
  async getMaxQuantity({ state }, article) {
    const level = article.LEVELS;
    let max = article.MAXIMO;
    let articleSelected = { ...article };

    if (level == 2) {
      if (!article.selectedLvl1) return 0;
      articleSelected = { ...article.selectedLvl1 };
      max = parseFloat(article.selectedLvl1.MAXIMO);
    } else if (level == 3) {
      if (!article.selectedLvl2) return 0;
      articleSelected = { ...article.selectedLvl2 };
      max = parseFloat(article.selectedLvl2.MAXIMO);
    }

    if (max == 0) {
      max =
        parseFloat(state.finalPayload.category.CANTIDADMAXIMA) ||
        parseFloat(this.state.currentDomainPark.CANTIDADMAXIMA) ||
        parseFloat(this.state.currentDomainPark.CANTIDADMAXIMAESPECIAL);
    }

    if (
      state.finalPayload.category &&
      state.finalPayload.category.CONTROLSTOCK != "0"
    ) {
      const articleRes = await this.dispatch("validateStock", {
        article: articleSelected,
        parentArticle: article,
      });
      if (!articleRes) return 0;
      const avaible =
        articleRes["CANTIDADSTOCK"] - articleRes["CANTIDADCOMPRADA"];
      max = avaible;
    }

    return max;
  },
  getMinQuantity({ state }, article) {
    const level = article.LEVELS;
    let min = article.MINIMO;

    if (level == 2) {
      if (!article.selectedLvl1) return 1;
      min = parseFloat(article.selectedLvl1.MINIMO);
    } else if (level == 3) {
      if (!article.selectedLvl2) return 1;
      min = parseFloat(article.selectedLvl2.MINIMO);
    }

    if (min == 0) {
      min =
        parseFloat(this.state.currentDomainPark.CANTIDADMINIMA) ||
        parseFloat(this.state.currentDomainPark.CANTIDADMINIMAESPECIAL);
    }

    return min;
  },
  decrementTicket({ state }, article) {
    const idx = this.state.articlesList.findIndex(
      (it) => it.IDARTICULO == article.IDARTICULO
    );
    if (idx == -1) return;
    const cantidad = this.state.articlesList[idx].CANTIDADSELECCIONADA;
    if (cantidad == 0) return;
    this.state.articlesList[idx].CANTIDADSELECCIONADA = cantidad - 1;
  },
  async changeDate({ state, commit, getters }, date) {
    this.commit("activeLoader", true);
    commit("setSchedule", null, { root: true });
    this.dispatch("resetAforoButacas");
    commit("setDate", date);
    await this.dispatch("loadArticlesList");
    if (getters.contieneIdForo) {
      const categoria = state.finalPayload.category;
      await this.dispatch("fetchCategoriaHorarios", {
        idCategoria: categoria.IDCATEGORIA,
        fechaVisita: date,
        setListaInicial: true
      });
    }
    commit("updateBaseIndex");
    this.dispatch("resetHorarioCategoria");
    this.commit("activeLoader", false);
  },
  async getSchedulesDependAditionalProccess(
    { state, rootState, dispatch },
    defaultProp
  ) {
    try {
      let schedules = null;
      let routes = null;

      // Si no permite combinar articulos
      let articleId = 0;
      let paramArticle = true;
      if (defaultProp && defaultProp.IDARTICULO) {
        articleId = parseFloat(defaultProp.IDARTICULO);
      }
      const category = state.finalPayload.category;
      const categoryId = defaultProp
        ? defaultProp.IDCATEGORIA
        : category.IDCATEGORIA;
      const dateSelected =
        (defaultProp
          ? defaultProp.FECHAVISITA
          : state.finalPayload.dateSelected) || "";

      // Por artículo
      const config = await this.dispatch("validateAditionalProccess", {
        categoryId: categoryId,
        paramArticle,
        idArticle: articleId,
      });

      if (config.AFORO == 0 && config.HORARIO == 0 && config.RUTA == 0) {
        const configGeneral = await this.dispatch("validateAditionalProccess", {
          categoryId: 0, // aforo general
          paramArticle,
          idArticle: articleId,
        });

        if (
          configGeneral.AFORO == 0 &&
          configGeneral.HORARIO == 0 &&
          configGeneral.RUTA == 0
        ) {
          // No encontró nunguna configuración
          return { schedules, error: true };
        } else {
          // General
          if (
            configGeneral.AFORO == 1 &&
            configGeneral.HORARIO == 0 &&
            configGeneral.RUTA == 0
          ) {
            state.finalPayload.dataRequestSchedules.categoryId = 0;
            schedules = await dispatch("fetchSchedules", {
              // Aforo general
              categoryId: 0,
              dateSelected: dateSelected,
              option: 1,
              articleId,
            });
            if (schedules.length == 0) return { schedules, error: true };
            return {
              schedules: [schedules[0]],
              error: false,
              onlyAforo: true,
              noCategory: true,
            };
          } else if (configGeneral.HORARIO > 0 || configGeneral.RUTA > 0) {
            if (configGeneral.RUTA > 0) {
              routes = await dispatch("fetchRoutes", {
                // Rutas de aforo general
                categoryId: categoryId || 0,
              });
              schedules = await dispatch("fetchSchedules", {
                // Horarios de Aforo general
                categoryId: 0,
                dateSelected: dateSelected,
                option: 1,
                routeId: routes[0] ? routes[0].IDRUTA : 0,
                articleId,
              });
              return { routes, schedules, error: false, noCategory: true };
            } else {
              schedules = await dispatch("fetchSchedules", {
                // Horarios de Aforo general
                categoryId: 0,
                dateSelected: dateSelected,
                option: 1,
                routeId: 0,
                articleId,
              });
              return { schedules, error: false, noCategory: true };
            }
          }
        }
      } else {
        if (config.AFORO == 1 && config.HORARIO == 0 && config.RUTA == 0) {
          schedules = await dispatch("fetchSchedules", {
            // Aforo
            categoryId: categoryId,
            dateSelected: dateSelected,
            option: 1,
            articleId,
          });
          if (schedules.length == 0) return { schedules, error: true };
          return { schedules: [schedules[0]], error: false, onlyAforo: true };
        } else if (config.HORARIO > 0 || config.RUTA > 0) {
          if (config.RUTA > 0) {
            routes = await dispatch("fetchRoutes", {
              // Rutas de aforo
              categoryId: categoryId,
              articleId,
            });
            schedules = await dispatch("fetchSchedules", {
              // Horarios de Aforo
              categoryId: categoryId,
              dateSelected: dateSelected,
              option: 1,
              routeId: routes[0] ? routes[0].IDRUTA : 0,
              articleId,
            });
            return { routes, schedules, error: false };
          } else {
            schedules = await dispatch("fetchSchedules", {
              // Horarios de Aforo
              categoryId: categoryId,
              dateSelected: dateSelected,
              option: 1,
              routeId: 0,
              articleId,
            });

            return { schedules, error: false };
          }
        }
        return { schedules, error: false };
      }
    } catch (error) {
      return { schedules: null, error: true };
    }
  },
  async validateStockAndMaxQuantityBeforeSave({ dispatch }, articles) {
    let promises = [];

    articles.forEach((article) => {
      if (article.CANTIDADSELECCIONADA > 0) {
        promises.push(
          dispatch("validateMax", { article, isValidationToSave: true })
        );
      }
    });
    const response = await Promise.all(promises);
    return !response.includes(false);
  },
  async validateStockOnDateChange({ dispatch }, { articles, visitDate }) {
    let promises = [];
    articles.forEach((art) => {
      if (art.CONTROLSTOCK != 0) {
        promises.push(
          this.dispatch("validateArticleStock", {
            categoryId: art.IDCATEGORIA,
            articleId: art.IDARTICULO,
            quantity: art.CANTIDAD,
            visitDate: visitDate,
          })
        );
      }
    });
    const response = await Promise.all(promises);
    return response.indexOf(false) == -1;
  },
  async validateAforoBeforeSave({ state, getters, dispatch }, prop) {
    const scheduleSelected = prop ? prop.shedule : getters.getScheduleSelected;
    const routeIdSelected = prop ? prop.routeId : getters.getRouteSelected;
    const categoryId = prop
      ? prop.categoryId
      : state.finalPayload.category && !state.finalPayload.globalAforo
      ? state.finalPayload.category.IDCATEGORIA
      : 0;
    const dateSelected = prop
      ? prop.dateSelected
      : state.finalPayload.dateSelected;
    const option = prop ? prop.iOption : 2;

    const schedules = await dispatch("fetchSchedules", {
      categoryId: categoryId,
      dateSelected: dateSelected, // pendiente - pendiente
      option: option, // cambiar a 2 antes de guardar
      routeId: routeIdSelected || 0,
      setList: false,
      startTime: scheduleSelected.HORAINICIO,
      endTime: scheduleSelected.HORAFIN,
    });

    const finalSchedule = schedules.find(
      (art) =>
        art.HORAINICIO == scheduleSelected.HORAINICIO &&
        art.HORAFIN == scheduleSelected.HORAFIN
    );

    if (!finalSchedule) return false;

    if(finalSchedule?.ENABLED != 1) return false;

    if (prop) {
      finalSchedule.TOTAL = prop.total;
    }

    return await this.dispatch("validateAforo", finalSchedule);
  },
  async validateAllBeforeSave({ state, dispatch }, prop) {
    try {
      let validate1 = true;
      let validate2 = true;
      if (!prop) {
        // Stock & max quantity
        validate1 = await dispatch(
          "validateStockAndMaxQuantityBeforeSave",
          this.state.articlesList
        );
        if (this.getters.haveAditionalProccess && state.finalPayload.schedule) {
          // Aditional proccess != 0
          validate2 = await dispatch("validateAforoBeforeSave");
        }
      } else {
        if (prop.controlStock != "0") {
          validate1 = await dispatch("validateStockOnDateChange", {
            articles: prop.articles,
            visitDate: prop.visitDate,
          });
        }
        if (this.getters.haveAditionalProccess) {
          let total = 0;
          prop.articles.forEach((art) => {
            total += parseFloat(art.CANTIDAD) * art.CANTIDADVALOR;
          });
          validate2 = await dispatch("validateAforoBeforeSave", {
            shedule: prop.schedule,
            routeId: prop.routeId,
            categoryId: prop.categoryId,
            dateSelected: prop.visitDate,
            total: total,
            iOption: prop.iOption || 2,
          });
        }
      }

      if (!validate1) {
        // error in stock
        await this.dispatch("generateMessageStatus", {
          title: {
            en: "Your purchase could not be processed",
            es: "Tu compra no se pudo procesar",
          },
          type: "error",
          html: {
            es: `<p class="text-center"><span > El stock o la capacidad máxima de algunos artículos ha variado y es menor a su cantidad elegida <br>Por favor vuelva a seleccionar su fecha de visita y sus artículos de preferencia. </span> </p>`,
            en: `<p class="text-center"><span > The stock of some items has been varied and is less than your chosen quantity <br>Please select your visit date and your preferred items again. </span> </p>`,
          },
          type: "error",
          confirmButtonText: {
            en: "Ok",
            es: "Aceptar",
          },
          allowOutsideClick: false,
        });
        dispatch("removeStep3");
        dispatch("removeStep2");
        return false;
      }

      if (!validate2) {
        // error in aforo
        await this.dispatch("generateMessageStatus", {
          title: {
            en: "Your purchase could not be processed",
            es: "Tu compra no se pudo procesar",
          },
          type: "error",
          html: {
            es: `<p class="text-center"><span > El aforo disponible ha variado y es menor a la cantidad total de personas que se muestran en el resumen <br>Pruebe seleccionando con otra fecha u horario de visita. </span> </p>`,
            en: `<p class="text-center"><span > The available capacity has varied and is less than the total number of people shown in the summary <br>Try selecting another date or time of visit. </span> </p>`,
          },
          type: "error",
          confirmButtonText: {
            en: "Ok",
            es: "Aceptar",
          },
          allowOutsideClick: false,
        });
        dispatch("removeStep3");
        return false;
      }

      return true;
    } catch (error) {
      return false;
    }
  },
  removeStep2({ commit }) {
    commit("setStep", 1);
    commit("setCategory", null);
    commit("setDate", null);
    commit("setDatesList", []);
    commit("setCalendarList", []);
    this.dispatch("resetHorarioCategoria");
    this.dispatch("resetAforoButacas");
    this.commit("setArticlesList", []);
    commit("updateBaseIndex");
    this.dispatch("cancelTimer");
    this.commit("setPausedTimer", false);
  },
  removeStep3({ state, commit }) {
    commit("setStep", 2);
    commit("setSchedule", null);
    commit("setRouteSelected", null);
    this.commit("setRoutes", []);
    this.commit("setSchedules", []);
    this.commit("setGlobalAforo", false);
    commit("updateBaseIndex");
  },
  removeStep4({ commit }) {
    commit("setStep", 2);
  },
  destroyAdvance({ state, commit }) {
    console.log("Destruir avance");
    commit("setStep", 1);
    commit("setCategory", null);
    commit("setDate", null);
    commit("updateBaseIndex");
    commit("setSchedule", null);
    commit("setUserPayment", null);
    commit("updateBaseIndex");
    this.dispatch("resetHorarioCategoria");
    this.dispatch("resetAforoButacas");
    this.commit("setArticlesList", []);
    this.commit("setCapacitySchedule", []);
    this.commit("setCalendarList", []);
    this.commit("setDatesList", []);
    this.commit("setSchedules", []);
    this.dispatch("cancelTimer");
    this.commit("setPausedTimer", false);
    this.commit("setGlobalAforo", false);
  },
  async onChangeSchedule({ commit, getters, state, rootState, dispatch }) {
    this.commit("activeLoader", true);
    const idCategoria = rootState.sale.finalPayload?.category?.IDCATEGORIA;
    const fechaVisita = getters.getFechaSeleccionadaOInicial;
    const horaInicio =
      rootState.sale.finalPayload.horarioCategoria?.HORAINICIO;
    const horaFin = rootState.sale.finalPayload.horarioCategoria?.HORAFIN;
    await this.dispatch("fetchCategoriaHorarios", {
      idCategoria, 
      fechaVisita, 
      horaInicio, 
      horaFin
    })
    await this.dispatch("loadArticlesList");
    // clear 3 y 4
    commit("setSchedule", null);
    commit("setUserPayment", null);
    this.commit("activeLoader", false);
    this.dispatch("resetAforoButacas");
  },
  resetAforoButacas({ commit }) {
    commit("setButacasList", []);
    commit("resetArticleSeleccion", { root: true });
  },
  resetHorarioCategoria({commit}) {
    commit("setHorarioCategoria", null);
  },
  sendForm({ commit }, payload) {
    commit("setStep", 1);
  },
  async validarAforoButacasZonas({
    commit,
    state,
    rootState,
    dispatch,
    getters,
  }) {
    try {
      const idioma = rootState.lang;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria = rootState.sale.finalPayload?.category?.IDCATEGORIA;
      const fechaVisita = getters.getFechaSeleccionadaOInicial;
      const horaInicio =
        rootState.sale.finalPayload.horarioCategoria?.HORAINICIO;
      const horaFin = rootState.sale.finalPayload.horarioCategoria?.HORAFIN;
      const butacas = getters.getButacasList ?? [];

      console.log({
        idParque,
        idCategoria,
        fechaVisita,
        horaInicio,
        horaFin,
        butacas,
      });

      const errors = [];

      // Construyendo array de butacas para consultar
      const butacasInfo = butacas?.map(butaca => ({
        IDBUTACA: butaca?.BUTACA?.IDBUTACA,
        IDARTICULO: butaca?.IDARTICULO,
      }))

      console.log(butacasInfo);

      const butacasEstado = butacas?.length > 0 ? (await this.dispatch("fetchEstadoButacasMultiple", {
        fechaVisita,
        horaInicio,
        horaFin,
        idCategoria,
        articulos: butacasInfo?.map(butaca => butaca.IDARTICULO),
        butacas: butacasInfo?.map(butaca => butaca.IDBUTACA),
      })) : [];

      console.log({ butacasEstado });

      // Validar Disponibilidad Butaca (en caso sea selección de butacas)
      for (const butaca of butacas) {
        const butacaInfo = butacasEstado?.find(butacaEstado => butacaEstado?.IDBUTACA == butaca?.BUTACA?.IDBUTACA);
        /* const butacaInfo = await this.dispatch("fetchHorarioButaca", {
          fechaVisita,
          horaInicio,
          horaFin,
          idCategoria,
          idArticulo: butaca?.IDARTICULO,
          idButaca: butaca?.BUTACA?.IDBUTACA,
        }); */
        if (!butacaInfo) {
          errors.push({
            en: `An error occurred validating seat B81 ${butaca?.BUTACA?.IDENTIFICADOR}`,
            es: `Ocurrió un error validando la butaca ${butaca?.BUTACA?.IDENTIFICADOR}`,
          });
          continue;
        }
        if (butacaInfo?.ENABLED != 1)
          errors.push({
            en: `Seat ${butaca?.BUTACA?.IDENTIFICADOR} has been occupied`,
            es: `La butaca ${butaca?.BUTACA?.IDENTIFICADOR} ha sido ocupada`,
          });
      }

      // Volver a listar horarios para obtener aforo actualizado
      await dispatch("fetchCategoriaHorarios", {
        idCategoria,
        fechaVisita,
        horaInicio,
        horaFin,
      });

      // Validar Aforo Horarios
      for (const articulo of this.state.articlesList) {
        if(articulo?.CANTIDADSELECCIONADA == 0) continue;
        const tienAforo = await dispatch(
          "validarAforoArticulo",
          { idArticulo: articulo.IDARTICULO, tipo: "validar_aforo" }
        );
        if (!tienAforo)
          errors.push({
            en: `The item "${articulo?.DESCRIPCIONCORTA}" has reached maximum capacity`,
            es: `El artículo "${articulo?.DESCRIPCIONCORTA}" ha alcanzado la capacidad máxima`,
          });
      }

      return errors;
    } catch (error) {
      console.log(error);
      return [
        {
          en: "Error processing your information, please try again.",
          es: "Error procesando su información, vuelva a intentarlo.",
        },
      ];
    }
  },
  validarAforoArticulo({ getters, state }, { idArticulo, tipo = "" }) {
    // Buscar el artículo almacenado en el store
    const articuloStore = this.state.articlesList.find(
      (it) => it.IDARTICULO == idArticulo
    );
    const cantidadSeleccionada = articuloStore?.CANTIDADSELECCIONADA; // Capturar la cantidad seleccionada del artículo

    const horariosArticulos = getters.obtenerHorariosCategoriaValidacion; // Obtener los horarios almacenados en store

    const zonasArticulosDisponibles = horariosArticulos?.filter((horario) => horario.IDARTICULO == idArticulo && horario?.ENABLED == 1); // Filtrar las zonas disponibles para el artículo
    
    console.log({zonasArticulosDisponibles});

    if(!zonasArticulosDisponibles || zonasArticulosDisponibles?.length == 0) return false; 
    
    /* const horarioArticulo = horariosArticulos?.find(
      (articulo) => articulo.IDARTICULO == idArticulo
    );

    console.log({ horarioArticulo, idArticulo, horariosArticulos }); */
    let horarioArticulo = null;

    for(const zonaDisponible of zonasArticulosDisponibles) {
      if(horarioArticulo) continue;
      if(tipo == "validar_incremento" && cantidadSeleccionada < zonaDisponible.CANTIDAD) {
        horarioArticulo = zonaDisponible;
        articuloStore.IDZONAARTICULO = horarioArticulo.IDZONA;
        console.log("validar_incremento",{ idArticulo, idZona: articuloStore.IDZONAARTICULO, cantidadSeleccionada, cantidad: horarioArticulo.CANTIDAD, resta: cantidadSeleccionada < horarioArticulo.CANTIDAD });
        return true;
      }
      else if(tipo == "validar_aforo" && cantidadSeleccionada <= zonaDisponible.CANTIDAD && articuloStore.IDZONAARTICULO == zonaDisponible.IDZONA) {
        horarioArticulo = zonaDisponible;
        console.log("validar_aforo",{ idArticulo, idZona: articuloStore.IDZONAARTICULO, cantidadSeleccionada, cantidad: horarioArticulo.CANTIDAD, resta: cantidadSeleccionada < horarioArticulo.CANTIDAD });
        return true;
      }
    }

    /* if(!horarioArticulo) return false;

    articuloStore.IDZONAARTICULO = horarioArticulo.IDZONA; */

    /* if(tipo == "validar_incremento") return cantidadSeleccionada < horarioArticulo.CANTIDAD;
    else if(tipo == "validar_aforo") return cantidadSeleccionada <= horarioArticulo.CANTIDAD; */
    // Si se supera el aforo
    return false;// cantidadSeleccionada < horarioArticulo.CANTIDAD;
  },
};

function getMinQuantityByArticle(article, defaultMin) {
  const level = article.LEVELS;
  let min = article.MINIMO;

  if (level == 2) {
    if (!article.selectedLvl1) return 1;
    min = parseFloat(article.selectedLvl1.MINIMO);
  } else if (level == 3) {
    if (!article.selectedLvl2) return 1;
    min = parseFloat(article.selectedLvl2.MINIMO);
  }

  if (min == 0) {
    min = defaultMin;
  }

  return min;
}

function getDescriptionArticle(article) {
  let desc = article.DESCRIPCIONCORTA;

  if (article.LEVELS == 2) {
    if (!article.selectedLvl1) return "";
    desc = article.selectedLvl1.DESCRIPCIONCORTA;
  } else if (article.LEVELS == 3) {
    if (!article.selectedLvl2) return "";
    desc = article.selectedLvl2.DESCRIPCIONCORTA;
  }

  return desc;
}

function getArticleInObject(article) {
  // Busca articulo en agrupación

  let articleObj = article;

  if (article.LEVELS == 2) {
    if (!article.selectedLvl1) return null;
    articleObj = article.selectedLvl1;
  } else if (article.LEVELS == 3) {
    if (!article.selectedLvl2) return null;
    articleObj = article.selectedLvl2;
  }

  return articleObj;
}

export default {
  state,
  mutations,
  getters,
  actions,
};
