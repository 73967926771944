<template>
  <ul class="lateral-menu">
    <li v-for="(item, idx) in menu" :key="item.name[$store.state.lang] + idx">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :to="item.path"
            depressed
            link
            v-bind="attrs"
            v-on="on"
            :aria-label="item.name[$store.state.lang]"
            :class="
              `lateral-menu-link readable  ${
                !item.visited ? item.extraClass : ''
              }`
            "
            :disabled="!isSubDomain && item.permitDisabled"
          >
            <div class="link-content">
              <img :src="item.icon" class="nav-link-lateral" alt="" />
            </div>
          </v-btn>
        </template>
        <span>{{ item.name[$store.state.lang] }} {{ item.path }}</span>
      </v-tooltip>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    isSubDomain: {
      type: Boolean
    }
  },
  computed: {
    ...mapGetters({
      menu: "getNavigationMenu",
    }),
  },
  methods: {
    redirectToRoute(path = "") {
      this.$router.push(path).catch((error) => {
        console.log(error);
        /* if (error.name != "NavigationDuplicated") {
          throw error;
        } */
      });
    },
  },
};
</script>

<style lang="scss">
.lateral-menu {
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  position: fixed;
  padding-left: 0 !important;
  bottom: 190px;
  left: 25px;
  z-index: 10;

  @media screen and (max-height: 550px) and (min-width: 601px) {
    bottom: 0px;
  }
  @media screen and (max-height: 380px) and (min-width: 601px) {
    bottom: 0px;
    width: 100%;
    left: 0;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  @media screen and (max-width: 600px) {
    bottom: 0px;
    width: 100%;
    left: 0;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  li {
    margin-bottom: 3px;

    @media screen and (max-width: 600px) {
      flex: 1;
      margin-bottom: 0;
    }
    @media screen and (max-height: 380px) and (min-width: 601px) {
      flex: 1 !important;
      margin-bottom: 0;
    }
    .lateral-menu-link {
      width: 50px !important;
      height: 50px !important;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--color-blue) !important;
      border-radius: 2px;
      transition: all ease-in-out 0.25s;
      position: relative;
      //overflow: hidden;
      animation: moveToTop ease-in-out 1.2s;
      min-width: 50px !important;
      @media screen and (max-height: 380px) and (min-width: 601px) {
        min-width: 100% !important;
        width: 100% !important;
        border-radius: 0 !important;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
      }
      .link-content {
        position: relative;
      }

      &.important-link:not(.v-btn--active) {
        background: rgb(196, 134, 10) !important;
        .link-content::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          /* top: 50%;
          left: 50%; */
          width: 20px;
          height: 20px;
          border-radius: 50%;
          animation: geoEffect 0.5s linear infinite;
          animation-play-state: running;
          transition: 0.5s;
          transform: translate(-50%, -50%) scale(1);
          background: rgb(252, 167, 0);
          box-shadow: 0 0 rgba(252, 167, 0, 0.7),
            0 0 0 10px rgba(252, 167, 0, 0.7), 0 0 0 20px rgba(252, 167, 0, 0.7),
            0 0 0 30px rgba(252, 167, 0, 0.7);
          z-index: 1;
        }
      }

      &.important-link:hover {
      }

      @media screen and (max-width: 600px) {
        width: auto !important;
        border-radius: 0;
      }

      &.router-link-exact-active,
      &.v-btn--active {
        background: var(--color-pink) !important;
      }

      img {
        width: 20px;
        height: 20px;
        transition: all ease-in-out 0.25s;
        z-index: 2;
        position: relative;
      }

      &:hover {
        img {
          transform: scale(1.15);
        }
      }
    }
  }
}

@keyframes moveToTop {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes geoEffect {
  to {
    box-shadow: 0 0 0 10px rgba(252, 167, 0, 0.7),
      0 0 0 20px rgba(252, 167, 0, 0.7), 0 0 0 30px rgba(252, 167, 0, 0.7),
      0 0 0 40px rgba(255, 54, 101, 0);
  }
}
</style>
