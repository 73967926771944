import axios from "../api/entradaslima.js";

const state = () => ({
  groups: [],
  groupsDetail: [],
  socialNetworks: []
});

const getters = {
  getGroupsParkInformation(state) {
    return state.groups;
  },
  getDetailGroupsParkInformation(state) {
    return state.groupsDetail;
  },
  getSocialsNetworkPark(state) {
    return state.socialNetworks;
  }
};

const mutations = {
  setInformationGroups(state, groups) {
    state.groups = groups;
  },
  setDetailGroupsInformation(state, arrInfo) {
    state.groupsDetail = arrInfo;
  },
  setSocialsNetworkPark(state, socialList) {
    state.socialNetworks = socialList;
  }
};

const actions = {
  async loadParkHomeInfo({ dispatch }, isChange) {
    await dispatch("fetchInformationGroups");
    await dispatch("fetchDetailGroupInformation");
    if (!isChange) {
      await dispatch("fetchSocialNetworksPark");
    }
  },
  async fetchInformationGroups({ state, commit, rootState }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/grupoinformativo`;
      const { data, status } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 2
        }
      });
      if (status != 200) return;
      const response = data.data; // await this.dispatch("decodeResponse", data.data);
      commit("setInformationGroups", response);
    } catch (error) {}
  },
  async fetchDetailGroupInformation({ commit, rootState }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/grupoinformativo/detalle`;
      const { data, status } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 2
        }
      });
      if (status != 200) return;
      const response = data.data; // await this.dispatch("decodeResponse", data.data);
      commit("setDetailGroupsInformation", response);
    } catch (error) {}
  },
  async fetchSocialNetworksPark({ commit, rootState }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/redessociales`;
      const { data, status } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 2,
        }
      });
      if (status != 200) return;
      const response = data.data; // await this.dispatch("decodeResponse", data.data);
      commit("setSocialsNetworkPark", response);
    } catch (error) {}
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
