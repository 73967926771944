import moment from "moment";

export const getCurrentDate = (format = "DD/MM/YYYY") => {
  return moment().format(format);
};

export const formatDate = (date, format = "YYYY-MM-DD") => {
  return moment(date).format(format);
};

export const transformDate = ({
  date = "",
  to = "YYYY-MM-DD",
  from = "DD/MM/YYYY",
}) => {
  if (date.includes("-")) 
    from = to;
  return moment(date, from).format(to);
};
