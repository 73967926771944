import axios from "@/api/pide";

const documents = {
  "1": "DNI",
  "2": "CE",
  "RUC": "RUC",
}

const state = () => ({});

const getters = {};

const mutations = {};

const actions = {
  /* async getDocumentIdentityData({ state, rootState }, { DOCUMENTO, TIPO }) { // Nuevo temporalmente disabled
    try {
      if (!DOCUMENTO) return null;
      
      const tipoDocumento = documents[TIPO] || "";
      const documento = String(DOCUMENTO).trim();
      const endpoint = `/servicios/pide/${tipoDocumento}/${documento}`

      const { data, status } = await axios.get(endpoint);
      if (status != 200) return null;
      if (!data.data || data.data.length == 0) {
        await this.dispatch("generateSnackbarError", {
          en: "The document number is invalid.",
          es: "El número de documento no es válido."
        });
        return null;
      }

      const response = data.data;

      if (!response) return null;
      return response;
    } catch (error) {
      return null;
    }
  }, */
  async getDocumentIdentityData({ state, rootState }, { DOCUMENTO, TIPO }) {
    try {
      if (!DOCUMENTO) return null;
      
      const tipoDocumento = documents[TIPO] || "";
      const documento = String(DOCUMENTO).trim();


      const { data, status } = await axios.get("/", {
        params: {
          accion: `consultarPIDE_${tipoDocumento}`,
          NUMERODOCUMENTO: documento
        }
      });

      if (status != 200) return null;
      if (!data.data || data.data.length == 0) {
        await this.dispatch("generateSnackbarError", {
          en: "The document number is invalid.",
          es: "El número de documento no es válido."
        });
        return null;
      }

      const response = await this.dispatch("decodeResponse", data.data);

      if (!response || response.length == 0) return null;
      return response[0];
    } catch (error) {
      return null;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
