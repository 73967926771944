import store from "../store";
import { changeVuetifyThemeColors } from "./vuetify";

export const setColorsApplication = async (context) => {
  const isSubDomain = await store.getters.currentDomainPark;
  const colors = isSubDomain
    ? {
        ...(context.$store.state.currentDomainPark.USARCOLORESPERSONALIZADOS !=
        "1"
          ? { ...context.$store.state.global }
          : { ...context.$store.state.currentDomainPark }),
      }
    : {
        ...context.$store.state.global,
      };

  changeVuetifyThemeColors.bind(context)(colors);
};
