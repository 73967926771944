/*
import Vue from "vue";
import GAuth from 'vue-google-oauth2';

const gauthOption = {
    //GOOGLE
    clientId: process.env.VUE_APP_GOOGLE_ID,
    clientSecret: process.env.VUE_APP_GOOGLE_SECRET,
    applicationName: process.env.VUE_APP_GOOGLE_APP_NAME,
    scope: 'https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.email',
    prompt: 'select_account',
    response_type: 'id_token permission',
    fetch_basic_profile: true,
}

Vue.use(GAuth, gauthOption)
*/
export const initGooglesdk = (view, selector = "buttonDiv") => {
    async function  handleCredentialResponse (googleResponse){
          const responsePayload = decodeJwtResponse(googleResponse.credential);
          const email = responsePayload.email;
          const imagen = responsePayload.picture;
          const name = responsePayload.given_name;
          const lastName = responsePayload.family_name;
          const googleId = responsePayload.sub;
          const response = responsePayload;

          const payload = {
              idUsuario: 0,
              vUsuario: email,
              vClave: googleId,
              vNombres: name,
              vApellidos: lastName,
              iOrigen: 3, // Google
              result: response,
              foto: imagen,
              iTipoDocumento: 1,
              vNumeroDocumento: "",
              registerWithSocial: true,
            };
            console.log(payload);
            await view.loginWithGoogle(payload);
    }
    function decodeJwtResponse (token){
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
    }
    google.accounts.id.initialize({
        client_id: process.env.VUE_APP_GOOGLE_ID,
        callback: handleCredentialResponse
    });
    google.accounts.id.renderButton(
        document.getElementById(selector),
        {
            type:"icon",
            shape: "circle",
            theme: "outline",
            size: "large"
        }
    );
    google.accounts.id.prompt();
}