function componentToHex(c) {
  const hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(r, g, b) {
  return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function convertRgbToHex(str = "") {
  let [r, g, b] = str
    .replace("(", "")
    .replace(")", "")
    .replace("rgb", "")
    .trim()
    .split(",");

  r = r.trim();
  g = g.trim();
  b = b.trim();
  return rgbToHex(Number(r), Number(g), Number(b));
}
