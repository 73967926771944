<template>
  <div class="lm--lang-list">
    <button
      type="button"
      @click="$store.dispatch('changeLanguage', 'es')"
      :title="btnSpanish[lang]"
      :class="`button-lang readable  ${lang == 'es' ? 'active-lang' : ''}`"
    >
      <span>ES</span>
    </button>
    <span>/</span>
    <button
      type="button"
      @click="$store.dispatch('changeLanguage', 'en')"
      :title="btnEnglish[lang]"
      :class="`button-lang readable ${lang == 'en' ? 'active-lang' : ''}`"
    >
      <span>EN</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      btnEnglish: {
        en: "English language",
        es: "Idioma Inglés",
      },
      btnSpanish: {
        en: "Spanish language",
        es: "Idioma Español",
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      accTools: "getAccesibilityTool",
    }),
  },
  watch: {
    lang(val) {
      this.accTools.restart(val);
      console.log("resetenado lang", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.lm--lang-list {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-left: 15px;
  & > span {
    margin: 0 0.25em;
  }
  .button-lang {
    background: transparent;
    outline: none;
    color: #fff;
  }
  .button-lang.active-lang {
    span {
      color: $color-secondary !important;
    }
  }
}
</style>