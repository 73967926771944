<template>
  <v-app dark class="lm--app">
    <Header />

    <v-main
      class="lm--main subdomain-wrapper no-padd-top"
      :class="{ pb__subdomain: isSubDomain }"
    >
      <router-view></router-view>
    </v-main>

    <LoaderPage :active="loader" :zIndex="150" />

    <LateralMenu :isSubDomain="isSubDomain"></LateralMenu>

    <SnackbarError v-if="isSubDomain"></SnackbarError>
  </v-app>
</template>

<script>
import Header from "@/components/Header";
import Home from "@/views/Home";
import LoaderPage from "@/components/Loader";
import SnackbarError from "@/views/subdomain/components/SnackbarError.vue";
import LateralMenu from "@/views/subdomain/components/LateralMenu.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import { setColorsApplication } from "@/plugins/colors";

export default {
  name: "App",
  components: {
    Header,
    LoaderPage,
    Home,
    SnackbarError,
    LateralMenu,
  },
  data: () => ({}),
  watch: {
    $route() {
      console.log("cambió route");
      setColorsApplication(this);
    },
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      accTools: "getAccesibilityTool",
      isSubDomain: "getStatusSubdomain",
    }),
    ...mapState({
      loader: (state) => state.loader,
    }),
  },
  mounted() {
    // Cargando Script de Pago
    const paymentScript = document.createElement("script");
    paymentScript.setAttribute("id", "visanet-library");
    paymentScript.setAttribute("src", `${process.env.VUE_APP_VISANET_LIBRARY}`);
    document.head.appendChild(paymentScript);
    const mapScriptEs = document.createElement("script");
    mapScriptEs.setAttribute("id", `map-library-es`);
    mapScriptEs.setAttribute("src", `${process.env.VUE_APP_MAP_URL}es`);
    document.head.appendChild(mapScriptEs);
  },
};
</script>
<style lang="scss">
body {
  font-family: $primary-font;
  overflow: hidden;
}
/* SCROLL */
body::-webkit-scrollbar {
  /* display: none; */
  width: 10px;
  height: 8px;
  border-radius: 8px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb {
  background: $scroll-color;
  border: 2px solid #eee;
  border-radius: 8px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb:hover {
  background: $scroll-color;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

body::-webkit-scrollbar-thumb:active {
  background-color: $scroll-color;
  border-radius: 8px;
}

.ox-hidden {
  overflow-x: hidden;
}

.p-0 {
  padding: 0 !important;
}

.br-15 {
  border-radius: 15px;
  @media screen and (max-width: 599px) {
    border-radius: 15px 15px 0px 0px;
  }
}

.c-white {
  color: white;
}
.bg-eee {
  background: #eee;
}
/*  */
.lm--app {
  box-sizing: border-box;
  width: 100%;
}

.lm--cover-page {
  margin-top: 0px !important;
  /* margin-top: 80px !important;
  @media screen and (max-width: 1000px) {
    margin-top: 70px !important;
  }
  @media screen and (max-width: 500px) {
    margin-top: 60px !important;
  } */
}

.lm--main {
  margin-top: 85px;
  &.principal-main {
    margin-top: -30px;
  }
  &.subdomain-wrapper {
    .v-main__wrap {
      // height: calc(100vh - 85px);
      overflow-y: auto;
      @media screen and (max-width: 600px) {
        // height: calc(100vh - calc(85px + 42px));
      }
      @media screen and (max-width: 500px) {
        // height: calc(100vh - calc(61px + 42px));
      }
    }
    @media screen and (max-width: 1000px) {
      margin-top: 85px;
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 42px;
    }
    @media screen and (max-width: 500px) {
      margin-top: 61px;
    }
    &.pb__subdomain {
      @media screen and (max-width: 600px) {
        padding-bottom: 80px !important;
      }
    }
  }

  padding-top: $searcherMidHeight;

  &.no-padd-top {
    padding-top: 0 !important;
  }
  @media screen and (max-width: 599px) {
    padding-top: 10px !important;
  }
}

.lm--card-modal-site {
  font-family: $primary-font;
  height: 100%;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  padding: 12px 0;
  @media screen and (min-width: 1200px) {
    padding: 15px 0;
  }
  @media screen and (min-width: 1500px) {
    padding: 18px 0;
  }
  @media screen and (min-width: 1800px) {
    padding: 20px 0;
  }
  .container-site {
    height: 100%;
    padding: 50px 0;
    padding-top: 80px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    box-sizing: border-box;
    /* align-items: center; */
  }
  .top-items {
    position: fixed;
    top: 20px;
    left: 0;
    width: 100%;
    background: #000;
    z-index: 160;
    @media screen and (max-width: 1000px) {
      top: 15px;
    }
    @media screen and (max-width: 1000px) {
      top: 15px;
    }
    @media screen and (max-width: 920px) {
      top: 0px;
    }
  }
  .top-info {
    display: flex;
    align-items: center;
    margin-top: 50px;
    @media screen and (max-width: 1350px) {
      margin-top: 30px;
    }
    @media screen and (max-width: 1000px) {
      margin-top: 15px;
    }
    @media screen and (max-width: 640px) {
      margin-top: 0px;
    }

    .title-site {
      margin-left: 15px;
      color: #fff;
      font-size: 20px;
      @media screen and (max-width: 599px) {
        font-size: 18px;
      }
      @media screen and (max-width: 400px) {
        font-size: 16px;
      }
    }
  }
  .body-info {
    padding: 20px 0;
    box-sizing: border-box;
    color: #fff;
    .img-site {
      min-height: 350px;
      height: 420px;
      max-height: 500px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.25),
          rgba(0, 0, 0, 0)
        );
      }
    }
    .link {
      margin-top: 18px;
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.75);
      color: rgba(255, 255, 255, 0.75);
      font-size: 0.75rem;
      font-weight: 500;
      text-decoration: none;
      padding: 0.45em 0.6em;
      border-radius: 10px;
      text-transform: uppercase;
      transition: all ease-in-out 0.25s;
      &.proximent {
        background: #4caf50 !important;
        border: 1px solid #4caf50 !important;
        color: #fff !important;
      }
      &.maintance {
        background: $color-secondary;
        border: 1px solid $color-secondary;
        color: #fff;
      }
      &:hover {
        background: $color-secondary;
        border: 1px solid $color-secondary;
        color: #fff;
      }
    }
    .content-right {
      .content-title {
        color: $color-secondary;
        margin-bottom: 10px;
      }
      .content-letters {
        margin-bottom: 20px;
      }
      .left-item {
        font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
        margin-bottom: 15px;
        i {
          margin-right: 10px;
        }
      }
      .link-section {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        .logo-site {
          padding: 1em 0.6em;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          background: #fff;
          display: flex;
          img {
            margin: auto;
          }
        }
      }
    }
  }
}

.title-sticky {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 5;
  padding: 10px 0;
  box-sizing: border-box;
  @media screen and (max-width: 600px) {
    max-height: 65px;
    min-height: 58px;
    padding: 7px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    .lm--subtitle {
      display: none !important;
    }
    .col-12 {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width: 450px) {
    padding: 5px 0;
  }
}

.text-center {
  text-align: center;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.img-fluid {
  max-width: 100%;
  max-height: 100%;
}

.v-dialog {
  box-shadow: none !important;
}
.mouse-pointer {
  cursor: pointer;
}

.swal2-title {
  font-size: 1.25em !important;
}

.swal2-html-container {
  font-size: 1em !important;
}

.title-card {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 600;
}
.subtitle-card {
  font-size: 11px;
  font-weight: 400;
}

#visaNetJS {
  background: rgba(0, 0, 0, 0.95) !important;
}
.grecaptcha-badge {
  display: none !important;
}
</style>
