import Vue from "vue";
import VueRouter from "vue-router";
//import multiguard from 'vue-router-multiguard';
import { verifyDomain } from "@/libs/verifyDomain";
import { checkHistory } from "../middlewares/history";
import { clearPark, loadPark } from "../middlewares/park";
import store from "../store";

Vue.use(VueRouter);

const homeRouter = [
  // Restablecer cuenta
  {
    path: "/restablecer-cuenta",
    name: "RestoreAccount",
    component: () => import("@/views/RestorePassword.vue"),
  },
  // Activar cuenta
  {
    path: "/activar-cuenta",
    name: "ActivateAccount",
    component: () => import("@/views/ActivateAccount.vue"),
  },
  // Recuperar cuenta
  {
    path: "/recuperar-cuenta",
    name: "AccountRecovery",
    component: () => import("@/views/subdomain/RecoveryAccount.vue"),
  },
  // Perfil
  {
    path: "/perfil",
    name: "Profile",
    component: () => import("@/views/subdomain/Account.vue"),
    beforeEnter: checkHistory,
  },
  // Mis Entradas
  {
    path: "/mis-entradas",
    name: "MyTickets",
    component: () => import("@/views/subdomain/MyTickets.vue"),
    beforeEnter: checkHistory,
  },
  {
    path: "/mis-entradas/buscar",
    name: "SearchPage",
    component: () => import("@/views/subdomain/SearchPage.vue"),
  },
  // Detalle de Entrada / Resumen de compra
  {
    path: "/mis-entradas/:parkId/:purchaseId",
    name: "TicketDetail",
    component: () => import("@/views/subdomain/TicketDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!from.name && to.query && to.query.visitDate) {
        next({ name: "SearchPage" });
        return;
      }
      next();
    },
  },
  // Calendario
  {
    path: "/mi-calendario",
    name: "MyCalendar",
    component: () => import("@/views/subdomain/MyCalendar.vue"),
    beforeEnter: checkHistory,
  },
  // Inicio
  {
    path: "/",
    name: "InitPage",
    component: () => import("@/views/InitPage.vue"),
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        beforeEnter: clearPark,
      },
      // Parques Inicio
      {
        path: "parques/:parent_name",
        name: "Parks",
        component: () => import("@/views/Parks.vue"),
        beforeEnter: clearPark,
      },
    ],
  },
  {
    path: "/:park_slug",
    name: "Park",
    //redirect: { name: "HomeInfo", },
    component: () => import("@/views/subdomain/Home.vue"),
    beforeEnter: loadPark,
    children: [
      // Información del parque
      {
        path: "inicio",
        name: "HomeInfo",
        component: () => import("@/views/subdomain/ParkInfo.vue"),
        beforeEnter: checkHistory,
      },
      // Venta
      {
        path: "",
        name: "HomeSale",
        component: () => import("@/views/subdomain/Sale.vue"),
        beforeEnter: checkHistory,
        redirect: { name: "HomeSaleV2" }
      },
      
      // About us
      {
        path: "conocenos",
        name: "AboutUs",
        component: () => import("@/views/subdomain/AboutUs.vue"),
        beforeEnter: checkHistory,
      },
      {
        path: "conocenos/:section",
        name: "SectionPage",
        component: () => import("../views/subdomain/Section.vue"),
      },
      {
        path: "conocenos/:section/:pin",
        name: "DataPinPage",
        component: () => import("../views/subdomain/Pin.vue"),
      },
      {
        path: "conocenos/:section/:pin/:id360",
        name: "Visor360Page",
        component: () => import("../views/subdomain/Visor360.vue"),
      },
      
      // Mis Entradas
      {
        path: "mis-entradas",
        name: "MyTickets",
        component: () => import("@/views/subdomain/MyTickets.vue"),
        beforeEnter: checkHistory,
      },
      {
        path: "mis-entradas/buscar",
        name: "SearchPage",
        component: () => import("@/views/subdomain/SearchPage.vue"),
      },
      // Detalle de Entrada / Resumen de compra
      {
        path: "mis-entradas/:parkId/:purchaseId",
        name: "TicketDetail",
        component: () => import("@/views/subdomain/TicketDetail.vue"),
        beforeEnter: (to, from, next) => {
          if (!from.name && to.query && to.query.visitDate) {
            next({ name: "SearchPage" });
            return;
          }
          next();
        },
      },
      // Calendario
      {
        path: "mi-calendario",
        name: "MyCalendar",
        component: () => import("@/views/subdomain/MyCalendar.vue"),
        beforeEnter: checkHistory,
      },
      // Venta x2
      {
        path: "ventas",
        name: "HomeSaleV2",
        component: () => import("@/views/subdomain/Sale.vue"),
        beforeEnter: checkHistory,
      },
      // Venta y Categoría
      {
        path: "ventas/:categoryId",
        name: "HomeCategorySale",
        component: () => import("@/views/subdomain/Sale.vue"),
        beforeEnter: checkHistory,
      },
    ],
  },
];

const subDomainRouter = [
  /* {
    path: "/inicio",
    name: "HomeInfo",
    component: () => import("@/views/subdomain/ParkInfo.vue"),
    beforeEnter: checkHistory,
  },
  {
    path: "/mis-entradas",
    name: "MyTickets",
    component: () => import("@/views/subdomain/MyTickets.vue"),
    beforeEnter: checkHistory,
  },
  {
    path: "/mis-entradas/buscar",
    name: "SearchPage",
    component: () => import("@/views/subdomain/SearchPage.vue"),
  },
  {
    path: "/mis-entradas/:purchaseId",
    name: "TicketDetail",
    component: () => import("@/views/subdomain/TicketDetail.vue"),
    beforeEnter: (to, from, next) => {
      if (!from.name && to.query && to.query.visitDate) {
        next({ name: "SearchPage" });
        return;
      }
      next();
    },
  },
  {
    path: "/mi-calendario",
    name: "MyCalendar",
    component: () => import("@/views/subdomain/MyCalendar.vue"),
    beforeEnter: checkHistory,
  }, */
  /* {
    path: "/conocenos",
    name: "AboutUs",
    component: () => import("@/views/subdomain/AboutUs.vue"),
    beforeEnter: checkHistory,
  },
  {
    path: "/conocenos/:section",
    name: "SectionPage",
    component: () => import("../views/subdomain/Section.vue"),
  },
  {
    path: "/conocenos/:section/:pin",
    name: "DataPinPage",
    component: () => import("../views/subdomain/Pin.vue"),
  },
  {
    path: "/conocenos/:section/:pin/:id360",
    name: "Visor360Page",
    component: () => import("../views/subdomain/Visor360.vue"),
  }, */
  /* {
    path: "/perfil",
    name: "Profile",
    component: () => import("@/views/subdomain/Account.vue"),
    beforeEnter: checkHistory,
  }, */
  /* {
    path: "/recuperar-cuenta",
    name: "AccountRecovery",
    component: () => import("@/views/subdomain/RecoveryAccount.vue"),
  }, */
  /* {
    path: "/:categoryId",
    name: "HomeCategorySale",
    component: () => import("@/views/subdomain/Sale.vue"),
    beforeEnter: checkHistory,
  },
  {
    path: "/",
    name: "HomeSale",
    component: () => import("@/views/subdomain/Sale.vue"),
    beforeEnter: checkHistory,
  }, */
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL || "/",
  routes: homeRouter, //verifyDomain() ? homeRouter : subDomainRouter,
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    if (store.state.isSubDomain) {
      const park = store.getters.getCurrentParkByDomain;
      const titlePage = `.: VENTAS EN LINEA - ${String(
        park.DESCRIPCION
      ).toUpperCase()} :.`;
      document.title = titlePage;
      gtag("config", window.GA_TRACKING_ID, {
        page_path: to.fullPath,
        app_name: titlePage,
        send_page_view: true,
      });
    } else {
      const titlePage = `.:${process.env.VUE_APP_BASE_NAME}:.`;
      document.title = titlePage;
    }
  });
});

export default router;
