export const decodificar = (r) => cesar(r, -3);

export function codificar(r) { return cesar(r, 3) }

function cesar(r, n) {
    var e = "",
        t = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
        a = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
    if (t.length === a.length)
        var c = t.length,
            u = t.length - 1;
    for (var f = 0; f < r.length; f++) {
        var i = -1,
            g = 0;
        (i = buscar(r[f], t)) < 0 && (g = 1, i = buscar(r[f], a)), i >= 0 ? 0 === g ? i + n > u ? e += t[(i = i + n - c - n) + n] : i + n < 0 ? n < 0 ? e += t[t.length + (i += n)] : e = t[(i = c - -1 * (i + n)) + n] : e += t[(i = i) + n] : e += i + n > u ? a[(i = i + n - c - n) + n] : i + n < 0 ? n < 0 ? a[a.length + (i += n)] : a[(i = c - -1 * (i + n)) + n] : a[(i = i) + n] : e += r[f]
    }

    return e
}

function buscar(r, n) {
    for (var e = -1, t = 0; t < n.length; t++)
        if (n[t] === r) { e = t; break }
    return e
}