export default {
    rounded: true,
    color: "primary",
    bgColor: "#fafafa",
    required: [(v) => !!v || "Requerido"],
    disabled: false,
    details: false,
    filled: true,
    outlined: false,
    dense: false,
    autocomplete: "off",
    solo: false,
    readonly: true,
    dark: false,
    roundedFieldclass: "em__rounded_field"
}