import Axios from "axios";
import { codificar } from "../libs/decrypt";

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL_PIDE, // from .env file
  headers: {
    "Content-Type":
      "application/json; charset=UTF-8; application/x-www-form-urlencoded",
    accept: "text/html; charset=iso-8859-1"
  }
});

axios.interceptors.request.use(
  function(config) {
    try {
      config.params = JSON.parse(codificar(JSON.stringify(config.params)));
      return config;
    } catch (error) {
      return config;
    }
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    return error.response || error;
  }
);

export default axios;
