import Vue from "vue";
import Vuex from "vuex";

import { decodificar, codificar } from "../libs/decrypt";
import { verifyDomain, getDomain } from "../libs/verifyDomain";
import moment, { lang } from "moment";

// api
import axios from "../api/entradaslima";
import apiPide from "../api/pide";
import AxiosDefault from "axios";

// assets
import Swal from "sweetalert2";
import { imgService } from "@/libs/media";
import { encBtoa, decAtob } from "@/libs/base64.js";
import logoMuni from "../assets/img/logo-muni.png";
import logoApp from "../assets/img/logo-app.png";
import bgImage from "../assets/img/background.jpg";
import visitalimaIMG from "../assets/img/visitalima.jpg";

// modules
import sale from "./sale";
import auth from "./auth";
import shopping from "./shopping";
import pide from "./pide";
import ubigee from "./ubigee";
import timer from "./timer";
import about from "./about";
import info from "./info";
import navigation from "./navigation";
import accesibility from "./accesibility";
import parkModule from "./park";
import paymentModule from "./payment";
import { formatDate, getCurrentDate, transformDate } from "../utils/date";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseURL: process.env.VUE_APP_HOST_URL,
    apiURL: process.env.VUE_APP_API_URL,
    logoMuni: logoMuni,
    logoApp: logoApp,
    bgImage: bgImage,
    langs: ["es", "en"],
    // visita lima
    visitalimaIMG: visitalimaIMG,
    visitaLima: {
      img: visitalimaIMG,
      title: {
        es: "Visita Lima",
        en: "Visit Lima",
      },
      description: {
        es:
          "Lima tiene magia y sus calles son un museo abierto que permitirán reconectarte contigo mismo desde lo más profundo del corazón de la ciudad. Cada ciudadano es el protagonista de su historia, anfitrión de su barrio y orgulloso embajador de un destino de incalculables experiencias.",
        en:
          "Lima has magic and its streets are an open museum that will allow you to reconnect with yourself from the depths of the heart of the city. Each citizen is the protagonist of his story, host of his neighborhood and proud ambassador of a destination of incalculable experiences.",
      },
      url: "http://visitalima.pe/clase/revistas/",
      urlPhrase: {
        es: "Explora Lima",
        en: "Explore Lima",
      },
    },
    uriEmilima: "https://www.emilima.com.pe",
    //
    lang: "es",
    muniLink: "https://www.munlima.gob.pe/",
    loader: false,
    socialItems: [
      //FontAwesome
      {
        icon: "fab fa-facebook-f",
        redirectTo: "https://www.facebook.com/LimaDesarrolloEconomico",
        title: `Facebook ${process.env.VUE_APP_BASE_NAME}`,
      },
      {
        icon: "fab fa-instagram",
        redirectTo: "https://www.instagram.com/munlima/",
        title: `Instagram ${process.env.VUE_APP_BASE_NAME}`,
      },
      {
        icon: "fab fa-twitter",
        redirectTo: "https://twitter.com/MuniLima",
        title: `Twitter ${process.env.VUE_APP_BASE_NAME}`,
      },
      {
        icon: "fab fa-youtube",
        redirectTo: "https://www.youtube.com/user/webmunlima",
        title: `Youtube ${process.env.VUE_APP_BASE_NAME}`,
      },
    ],
    coverTitle: {
      part1: { es: "Compra entradas de tus", en: "Buy your tickets of your" },
      part2: { es: "centros de esparcimiento", en: "favorite places" },
      part3: { es: "y Eventos favoritos", en: "and favorite Events" },
    },
    searchHolder: {
      es: "Busca tu evento",
      en: "Search your event",
    },
    titleSection: {
      title: {
        es: "Obten tus entradas",
        en: "Get your tickets",
      },
      title2: {
        es: "Obten tus entradas para visitar",
        en: "Get your tickets to visit",
      },
      subtitle: {
        es:
          "Encuentra el lugar que desees visitar y compra tus entradas en línea",
        en: "Find the place you want to visit and buy your tickets online",
      },
    },
    footer: {
      copy: {
        es: "Todos los derechos reservados",
        en: "All rights reserved",
      },
      made: {
        es: "Elaborado por",
        en: "Powered by",
      },
    },
    linkPhase: {
      es: "Compra en linea aquí",
      en: "Buy online here",
    },
    linkPhaseParentPark: {
      es: "Elige una opción",
      en: "Choose an option",
    },
    statusSite: {
      maintanceText: {
        es: "En mantenimiento",
        en: "In maintenance",
      },
      commingText: {
        es: "Próximamente",
        en: "Coming soon",
      },
    },
    global: null,
    parentsList: [],
    parkList: [],
    allDomainsPark: [],
    // modal
    modal: false,
    currentPark: null,
    //
    isSubDomain: false,
    domain: "",
    currentDomainPark: null,
    categoriesPark: [],
    datesList: [],
    calendarList: [],
    articlesList: [],
    capacitySchedule: [],
    schedules: [],
    routes: [],
    paymentList: [],
    faqs: [],
    terms: "",
    localIP: "",
    // Selecteds
    categorySelected: null,
    dateSelected: "",
    articleSelected: null,
    //
    anounces: [],
    modalAnounces: false,
    finalPayload: {},
    //fake
    fakeList: [
      { id: 1, name: "Parque de las leyendas", slug: "parque-de-las-leyendas" },
      {
        id: 2,
        name: "Circuito mágico del agua",
        slug: "circuito-magico-del-agua",
      },
    ],
  },
  getters: {
    getValidateAdquiriente(state) {
      return state.currentDomainPark?.VALIDARADQUIRIENTE == 1 ? true : false;
    },
    getLanguage(state) {
      return state.lang;
    },
    getParents(state) {
      return state.parentsList;
    },
    getParks(state) {
      return state.parkList;
    },
    getUser(state) {
      return null;
    },
    getCurrentParkByDomain(state) {
      return state.currentDomainPark;
    },
    getLogoByCurrentPark(state) {
      if (!state.currentDomainPark)
        return require("@/assets/img/logo-app.png");
      if (
        !state.currentDomainPark.URLFOTOLOGO.toLowerCase().includes(".jpg") &&
        !state.currentDomainPark.URLFOTOLOGO.toLowerCase().includes(".png") &&
        !state.currentDomainPark.URLFOTOLOGO.toLowerCase().includes(".jpeg")
      )
        return require("@/assets/img/logo-app.png");

      return imgService(state.currentDomainPark.URLFOTOLOGO);
    },
    getVisibilitySaleView(state) {
      if (!state.currentDomainPark) return false;
      if (
        state.currentDomainPark?.IDESTADODESARROLLO == "3" ||
        state.currentDomainPark?.IDESTADODESARROLLO == "4"
      )
        return false;
      return true;
      //return state.currentDomainPark.IDESTADODESARROLLO == "2";
    },
    getStatusDevelopment(state) {
      if (state.currentDomainPark?.IDESTADODESARROLLO == "1")
        return { en: "In maintance", es: "En Mantenimiento" };
      if (state.currentDomainPark?.IDESTADODESARROLLO == "3")
        return { en: "In maintance", es: "En Mantenimiento" };
      if (state.currentDomainPark?.IDESTADODESARROLLO == "4")
        return { en: "Coming soon", es: "Próximamente" };
      return {};
    },
    getCategoriesPark(state) {
      return state.categoriesPark;
    },
    getDatesList(state) {
      return state.datesList;
    },
    getCalendarList(state) {
      return state.calendarList;
    },
    getArticlesList(state) {
      return state.articlesList;
    },
    getCapacitySchedule(state) {
      return state.capacitySchedule;
    },
    getArticlesSelected(state) {
      return state.articlesList.filter((it) => it.CANTIDADSELECCIONADA > 0);
    },
    getArticlesTotal(state) {
      // gratis validar
      let total = 0;
      state.articlesList.forEach((it) => {
        if (it.CANTIDADSELECCIONADA > 0) {
          switch (it.LEVELS) {
            case 1:
              total += parseFloat(it.PRECIO) * it.CANTIDADSELECCIONADA;
              break;
            case 2:
              total +=
                parseFloat(it.selectedLvl1.PRECIO) * it.CANTIDADSELECCIONADA;
              break;
            case 3:
              total +=
                parseFloat(it.selectedLvl2.PRECIO) * it.CANTIDADSELECCIONADA;
              break;
          }
        }
      });
      return total;
    },
    getTotalTickets(state) {
      let total = 0;
      state.articlesList.forEach((it) => {
        if (it.CANTIDADSELECCIONADA > 0) {
          total += it.CANTIDADSELECCIONADA;
        }
      });
      return total;
    },
    getCurrentTotal(state, rootState) {
      if (rootState.haveAditionalProccess && rootState.validateFree)
        return rootState.getTotalPeople;
      if (
        rootState.getCategorySelected &&
        rootState.getCategorySelected.CONTROLSTOCK != "0"
      )
        return rootState.getTotalPeople;
      return rootState.getTotalTickets;
    },
    getTotalPeople(state) {
      let totalPeople = 0;
      state.articlesList.forEach((art) => {
        if (art.CANTIDADSELECCIONADA > 0) {
          let article = getArticleInObject(art);
          totalPeople +=
            art.CANTIDADSELECCIONADA * (parseFloat(article.CANTIDADVALOR) || 1);
        }
      });
      return totalPeople;
    },
    getStatusSubdomain(state) {
      return state.isSubDomain;
    },
    getFAQs(state) {
      return state.faqs;
    },
    getTermsAndConditions(state) {
      return state.terms;
    },
    getSchedules(state) {
      return state.schedules;
    },
    getAllRoutes(state) {
      return state.routes;
    },
    getPaymentList(state) {
      return state.paymentList;
    },
    haveAditionalProccess(state) {
      return (
        state.currentDomainPark &&
        state.currentDomainPark.IDPROCESOADICIONAL != "0"
      );
    },
  },
  mutations: {
    setLanguage(state, language) {
      localStorage.setItem("lang", language);
      state.lang = language;
    },
    activeLoader(state, loader) {
      state.loader = loader;
    },
    setGlobalData(state, data) {
      state.global = data;
    },
    setParentsList(state, parentsList) {
      state.parentsList = parentsList;
    },
    setParksList(state, parkList) {
      state.parkList = parkList;
    },
    setAllDomainsParks(state, parkList) {
      state.allDomainsPark = parkList;
    },
    setStatusModal(state, status) {
      state.modal = status;
    },
    setCurrentPark(state, park) {
      state.currentPark = park;
    },
    setDomain(state, domain) {
      state.domain = domain;
    },
    setCurrentDomainPark(state, park) {
      state.currentDomainPark = park;
    },
    setCategoriesPark(state, categories) {
      state.categoriesPark = categories;
    },
    setDatesList(state, dates) {
      state.datesList = dates;
    },
    setCalendarList(state, list) {
      state.calendarList = list;
    },
    setArticlesList(state, articles) {
      state.articlesList = articles;
    },
    resetArticleSeleccion(state){
      state.articlesList = state?.articlesList?.map(article => {
        return {...article, CANTIDADSELECCIONADA: 0 }
      })
    },
    setCapacitySchedule(state, list) {
      state.capacitySchedule = list;
    },
    setDateSelected(state, date) {
      state.dateSelected = date;
    },
    setStatusSubDomain(state, status) {
      state.isSubDomain = status;
    },
    setFAQs(state, faqs) {
      state.faqs = faqs;
    },
    setTermsAndConditions(state, terms) {
      state.terms = terms;
    },
    setSchedules(state, list) {
      state.schedules = list;
    },
    setRoutes(state, routes) {
      state.routes = routes;
    },
    setAnounces(state, anounces) {
      state.anounces = anounces;
    },
    setStatusModalAnounces(state, bool) {
      state.modalAnounces = bool;
    },
    setLocalIP(state, ip) {
      state.localIP = ip;
    },
    setPaymentList(state, list) {
      state.paymentList = list;
    },
  },
  actions: {
    // Modal
    openModal({ state, commit }) {
      commit("setStatusModal", true);
    },
    closeModal({ state, commit }) {
      commit("setStatusModal", false);
    },
    // Modal anounces
    openModalAnounces({ state, commit }) {
      commit("setStatusModalAnounces", true);
    },
    closeModalAnounces({ state, commit }) {
      commit("setStatusModalAnounces", false);
    },
    // Logica de negocio
    validateSubdomain({ commit }) {
      // Valida si es Subdominio o dominio principal
      console.log(this.$route);
      const domain = getDomain();
      commit("setDomain", domain);
      const bool = verifyDomain(domain);
      commit("setStatusSubDomain", !bool);
      return !bool;
    },
    changeCurrentPark({ state, commit }, park) {
      // Cambia el parque actual de forma global
      commit("setCurrentPark", {
        ...park,
        childrens: [...state.parkList].filter(
          (p) => p.IDPARQUEPADRE == park.IDPARQUE
        ),
      });
    },
    findBySlug({ state, commit }, slug) {
      // Busca parque padre por slug
      const place = state.fakeList.find((it) => it.slug === slug);
      if (!place) return null;
      return { ...place };
    },
    loadLanguageFromLS({ state, commit }) {
      const lang = localStorage.getItem("lang");
      if (!lang) {
        commit("setLanguage", "es");
      } else {
        if (state.langs.indexOf(lang) == -1) {
          commit("setLanguage", "es");
          return;
        }
        commit("setLanguage", lang);
      }
    },
    async changeLanguage({ state, rootState, getters, commit, dispatch }, lang) {
      // Cambia el lenguaje
      commit("activeLoader", true);
      commit("setLanguage", lang);
      commit("setButacasList", [])
      await dispatch("getGlobalData");
      await dispatch("getParksList");
      if (state.isSubDomain) {
        const parkInfo = state.currentDomainPark;
        await dispatch("getParkByDomain", parkInfo?.SLUG);
        this.dispatch("loadParkHomeInfo", true);
        if (this.state.sale.step > 1) {
          await this.dispatch("changeStep", 2);
          await this.dispatch("removeStep3");
          await this.dispatch("removeStep4");
        }
      }
      if(getters.contieneIdForo) {
        // FINALIZADO: validar si es necesario volver a llamar la lista de articulos
        await this.dispatch(
          "fetchCategoriaHorarios",
          {
            idCategoria: rootState.sale.finalPayload?.category?.IDCATEGORIA,
            fechaVisita: getters.getFechaSeleccionadaOInicial,
          }
        );
      }
      this.commit("updateBaseIndex");
      commit("activeLoader", false);
    },

    /* ================ DATOS GENERALES ====================== */
    async getGlobalData({ state, commit, dispatch }) {
      // Actualizado
      const { status, data } = await axios.get(`/parametros`, {
        params: {
          idioma: state.lang,
        },
      });
      if (status != 200) return null;
      const response = data.data;
      commit("setGlobalData", response);
      return state.global;
    },
    async getParksList({ state, commit, dispatch }) {
      // Actualizado
      const { status, data } = await axios.get(`/parques`, {
        params: {
          idioma: state.lang,
          idSistema: 2,
        },
      });
      if (status != 200) return null;
      const response = data.data;
      const parents = [...response].filter(
        (park) => park.IDPARQUEPADRE == "0" || Number(park.PADRE) > 0
      ); // PADRES

      // Filtro para parques Hijos o Padres sin hijos (para buscador)
      const childs = [...response].filter((park) => {
        // PADRE = cantidad de hijos
        if (park.IDPARQUEPADRE != "0" || park.PADRE == "0") {
          if (park.DEPEDENCIAS == "0") {
            // Si no tiene dependencias no hay problema
            return true;
          } else {
            // En caso tenga dependencia Buscar si el padre se encuentra en la lista
            // Estar en la lista = estar habilitado
            const idx = parents.findIndex(
              (parent) => parent.IDPARQUE == park.DEPEDENCIAS
            ); // Buscar index del Padre
            if (idx > -1) {
              // > -1 Si encuentra el padre en la lista
              return true;
            }
            return false;
          }
        } else {
          return false;
        }
      });

      commit("setParentsList", parents);
      commit("setParksList", childs);
      commit("setAllDomainsParks", response);

      return state.parkList;
    },
    async getAnounces({ state, commit, dispatch }) {
      // Actualizado
      const { status, data } = await axios.get(`/anuncios`, {
        params: {
          idioma: state.lang,
        },
      });
      if (status != 200) return null;
      const response = data.data;
      commit("setAnounces", response);
      return state.anounces;
    },
    getParksByParent({ state }, parent) {
      return [...state.parkList].filter(
        (p) => p.IDPARQUEPADRE == parent.IDPARQUE
      );
    },
    async getParkByDomain({ state, commit, dispatch }, slugOrId) {
      // Actualizado
      // domain = domain || state.domain;
      console.log({ slugOrId });
      const isParkId = Number(slugOrId) ? true : false;
      const park = isParkId ? (await this.dispatch("getParkById", slugOrId)) : (await this.dispatch("getParkBySlug", slugOrId)); // state.allDomainsPark.find((p) => p.SUBDOMINIO == domain);
      console.log({ park });
      if (!park) return;
      commit("setCurrentDomainPark", park);
      await dispatch("getCategoriesByPark");
      // Load More info
      this.dispatch("loadParkHomeInfo");
      await dispatch("fetchFAQs");
      await dispatch("fetchTermsAndConditions");
      
      dispatch("fetchPaymentList");
      
      this.dispatch("fetchDepartments");
      this.dispatch("fetchProvinces");
      this.dispatch("fetchDistricts");

    },

    /* ================ DATOS DE PARQUE ====================== */
    /* async getCategoriesByPark({ state, commit, dispatch }) {
      const idParque = state.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/categorias`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: state.lang,
          idSistema: 2,
          // accion: "consultarListadoCategorias",
          // iIdParque: state.currentDomainPark.IDPARQUE,
        },
      });
      if (status != 200) return null;
      const response = data.data; // await dispatch("decodeResponse", data.data);
      commit("setCategoriesPark", response);
      if (this.state.sale.finalPayload.category) {
        this.state.sale.finalPayload.category = response.find(
          (cat) =>
            cat.IDCATEGORIA == this.state.sale.finalPayload.category.IDCATEGORIA
        );
      }
    }, */
    /* async loadDates({ state, dispatch }) {
      const park = state.currentDomainPark;
      const category = this.state.sale.finalPayload.category;

      if (category.IDEVENTO != 0) {
        await dispatch("getEventsDate");
      } else {
        await dispatch("getDatesList");
      }

      if (park.DIASHABILITADOSMAXIMO > park.DIASHABILITADOS) {
        await dispatch("getCalendarList");
      }
    }, */
    /* async getDatesList({ state, commit, dispatch }) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const diasHabilitados = state.currentDomainPark.DIASHABILITADOS;
        const diasHabilitadosMaximo =
          state.currentDomainPark.DIASHABILITADOSMAXIMO;
        console.log("Categoria", this.state.sale.finalPayload.category);
        const fechaVisita = this.state.sale.finalPayload.category
          ? (this.state.sale.finalPayload.category.FECHADEFAULT || getCurrentDate())
          : getCurrentDate();
        const endpoint = `/parques/${idParque}/fechasvisita`;
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            diasHabilitados,
            diasHabilitadosMaximo,
            fechaVisita: transformDate({ date: fechaVisita }),
            // idSistema: 2,
            // accion: "consultarListadoFechas",
            //iIdParque: state.currentDomainPark.IDPARQUE,
          },
        });
        if (status != 200) return null;

        const str = JSON.stringify(data.data);
        const replaced = str; //.replace(/\\r/g, " ").replace(/\\n/g, " ");
        const obj = JSON.parse(replaced);
        const response = data.data; // await dispatch("decodeResponse", obj);
        commit("setDatesList", response);
        // set default date
      } catch (error) {}
    }, */
    /* async getEventsDate({ state, commit, dispatch }, idEvento = "") {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        idEvento = this.state.sale.finalPayload.category
          ? this.state.sale.finalPayload.category.IDEVENTO
          : idEvento;
        const fechaVisita = this.state.sale.finalPayload.category
          ? this.state.sale.finalPayload.category.FECHADEFAULT
          : getCurrentDate();
        const endpoint = `/parques/${idParque}/eventos/${idEvento}/fechasvisita`;
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            fechaVisita: transformDate({ date: fechaVisita }),
            // idSistema: 2,
            //accion: "consultarFechasEvento",
          },
        });
        if (status != 200) return null;

        const str = JSON.stringify(data.data);
        const replaced = str; //.replace(/\\r/g, " ").replace(/\\n/g, " ");
        const obj = JSON.parse(replaced);
        const response = obj; // await dispatch("decodeResponse", obj);
        commit("setDatesList", response);
        return response;
      } catch (err) {}
    }, */
    /*  async getCalendarList({ state, commit, dispatch }) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const fechaVisita = getCurrentDate();
        const diasHabilitadosMaximo =
          state.currentDomainPark.DIASHABILITADOSMAXIMO;
        const endpoint = `/parques/${idParque}/fechasvisita/calendario`;
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            diasHabilitadosMaximo,
            fechaVisita: transformDate({ date: fechaVisita }),
            // vFechaVisita: moment().format("DD/MM/YYYY"),
            // accion: "consultarListadoFechasCalendario",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;

        const str = JSON.stringify(data.data);
        const replaced = str; //.replace(/\\r/g, " ").replace(/\\n/g, " ");
        const obj = JSON.parse(replaced);
        const response = obj; // await dispatch("decodeResponse", obj);
        commit("setCalendarList", response);
        return response;
      } catch (error) {}
    }, */
    /* async loadArticlesList({ dispatch }) {
      let defaultDate = "";
      defaultDate = this.state.sale.finalPayload.category
        ? this.state.sale.finalPayload.category.FECHADEFAULT
        : "";
      if (this.getters.haveControlStock) {
        await dispatch("getArticlesStock", { defaultDate });
      } else {
        await dispatch("getArticlesList", { defaultDate });
      }
    }, */
    /* async getArticlesList(
      { state, commit, dispatch },
      { categoryId, defaultDate }
    ) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria =
          this.state.sale.finalPayload.category.IDCATEGORIA || categoryId;
        const fechaVisita =
          this.state.sale.finalPayload.dateSelected ||
          defaultDate || getCurrentDate();
        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos`;
        console.log(this.state.sale.finalPayload.dateSelected, defaultDate);
        console.log({ fechaVisita });
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            fechaVisita: transformDate({ date: fechaVisita }),
            // vFechaVisita:
            //   this.state.sale.finalPayload.dateSelected ||
            //   defaultDate ||
            //   moment().format("DD/MM/YYYY"),
            //accion: "consultarListadoArticulos",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // IDCATEGORIA:
            //   this.state.sale.finalPayload.category.IDCATEGORIA || categoryId,
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;
        const response = data.data; // await dispatch("decodeResponse", data.data);
        const articles = await dispatch("formatArticles", response);
        state.articlesList = [];
        commit("setArticlesList", articles);
      } catch (error) {}
    }, */
    /* async getArticlesStock({ state, commit, dispatch }, { defaultDate }) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria =
          this.state.sale.finalPayload.category.IDCATEGORIA || categoryId;
        const idArticulo = 0;
        const fechaVisita =
          this.state.sale.finalPayload.dateSelected ||
          defaultDate || getCurrentDate();
        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`;

        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            fechaVisita: transformDate({ date: fechaVisita }),
            // accion: "consultarListadoArticulosStock",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // IDCATEGORIA:
            //   this.state.sale.finalPayload.category.IDCATEGORIA || categoryId,
            // iIdArticulo: 0,
            // vFechaVisita:
            //   this.state.sale.finalPayload.dateSelected ||
            //   defaultDate ||
            //   moment().format("DD/MM/YYYY"),
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;
        const response = data.data; // await dispatch("decodeResponse", data.data);
        const articles = await dispatch("formatArticles", response);
        state.articlesList = [];
        commit("setArticlesList", articles);
      } catch (error) {}
    }, */
    /* async fetchArticlesOnDateChange(
      { state, dispatch },
      { categoryId, stock }
    ) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria = categoryId;
        const idArticulo = stock ? 0 : undefined;
        const fechaVisita = "";

        const endpoint = stock 
          ? `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`
          : `/parques/${idParque}/categorias/${idCategoria}/articulos`;

        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            fechaVisita: transformDate({ date: fechaVisita }),
            idArticulo,
            // accion: stock
            //   ? "consultarListadoArticulosStock"
            //   : "consultarListadoArticulos",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // IDCATEGORIA: categoryId,
            // iIdArticulo: 0,
            // vFechaVisita: "",
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;
        const response = data.data; // await dispatch("decodeResponse", data.data);
        return response;
      } catch (error) {
        return null;
      }
    }, */
    /* async validateArticleStock(
      { state, dispatch },
      { categoryId, articleId, quantity, visitDate }
    ) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria = categoryId;
        const idArticulo = articleId;
        const fechaVisita = visitDate;

        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`;

        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            fechaVisita: transformDate({ date: fechaVisita })
            // accion: "consultarListadoArticulosStock",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // IDCATEGORIA: categoryId,
            // iIdArticulo: articleId,
            // vFechaVisita: visitDate,
            // iIdSistema: 2,
          },
        });
        if (status != 200) return false;
        const response = await dispatch("decodeResponse", data.data);
        if (!response[0]) return false;

        const avaible =
          parseFloat(response[0].CANTIDADSTOCK) -
          parseFloat(response[0].CANTIDADCOMPRADA);

        if (quantity <= avaible) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    }, */
    /* async validateStock(
      { state, commit, dispatch },
      { article, parentArticle }
    ) {
      try {
        if (!article) return null;
        if (!this.state.sale.finalPayload.category) return null;
        if (this.state.sale.finalPayload.category.CONTROLSTOCK == "0")
          return null;
        // Parámetros
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria = this.state.sale.finalPayload.category.IDCATEGORIA || categoryId;
        const idArticulo = article.IDARTICULO;
        const fechaVisita = this.state.sale.finalPayload.dateSelected || getCurrentDate()
        // Endpoint
        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`;
        // Consulta Servicio
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            fechaVisita: transformDate({ date: fechaVisita }),
            // accion: "consultarListadoArticulosStock",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // IDCATEGORIA:
            //   this.state.sale.finalPayload.category.IDCATEGORIA || categoryId,
            // iIdArticulo: article.IDARTICULO,
            // vFechaVisita:
            //   this.state.sale.finalPayload.dateSelected ||
            //   moment().format("DD/MM/YYYY"),
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;
        const response = await data.data; // dispatch("decodeResponse", data.data);
        if (!response[0]) return null;
        const idx = state.articlesList.findIndex(
          (ar) => ar.IDARTICULO == parentArticle.IDARTICULO
        );
        if (idx > -1) {
          state.articlesList[idx]["CANTIDADSTOCKDISPONIBLE"] =
            response[0]["CANTIDADSTOCK"] - response[0]["CANTIDADCOMPRADA"];
        }
        this.commit("updateBaseIndex");
        return response[0];
      } catch (error) {
        return null;
      }
    }, */
    /* async formatArticles({ commit, dispatch }, list) {
      // Si no agrupa
      if (
        this.getters.getCategorySelected &&
        this.getters.getCategorySelected.AGRUPAR == "0"
      ) {
        return list.map((item) => {
          return {
            ...item,
            LEVELS: 1,
            SUBOBJETOS: null,
            CANTIDADSELECCIONADA: 0,
          };
        });
      }

      // Si es que agrupa
      let finalArr = [];
      list.forEach((item, idx) => {
        const arr = item.DESCRIPCIONCORTA.split("/");

        let obj;
        let exist;
        switch (arr.length) {
          case 1:
            // Un nivel
            obj = { ...item };
            obj.CANTIDADSELECCIONADA = 0;
            obj.DESCRIPCIONCORTA = arr[0];
            obj.SUBOBJETOS = null;
            obj.LEVELS = 1;
            finalArr.push(obj);
            break;
          case 2:
            // Dos niveles
            exist = finalArr.find((it) => it.DESCRIPCIONCORTA == arr[0]);
            obj = exist || { ...item };
            obj.CANTIDADSELECCIONADA = 0;
            obj.DESCRIPCIONCORTA = arr[0];
            obj.SUBOBJETOS = obj.SUBOBJETOS || [];
            obj.LEVELS = 2;
            if (!exist) {
              // Si no existe en arreglo
              finalArr.push(obj);
            }
            if (
              obj.SUBOBJETOS &&
              !obj.SUBOBJETOS.find((it) => it.NAME == arr[1])
            ) {
              obj.SUBOBJETOS.push({
                ...item,
                IDARTICULO: item.IDARTICULO,
                NAME: arr[1],
                VALUE: obj.SUBOBJETOS.length + 1 || 1,
              });
            }
            break;
          case 3:
            // Dos niveles
            exist = finalArr.find((it) => it.DESCRIPCIONCORTA == arr[0]);
            obj = exist || { ...item };
            obj.CANTIDADSELECCIONADA = 0;
            obj.DESCRIPCIONCORTA = arr[0];
            obj.LEVELS = 3;
            obj.SUBOBJETOS = obj.SUBOBJETOS || [];
            if (!exist) {
              // Si no existe en arreglo
              finalArr.push(obj);
            }

            const idx = obj.SUBOBJETOS.findIndex((it) => it.NAME == arr[1]);

            if (idx == -1) {
              obj.SUBOBJETOS.push({
                ...item,
                IDARTICULO: item.IDARTICULO,
                NAME: arr[1],
                VALUE: obj.SUBOBJETOS.length + 1 || 1,
                SUBOBJETOS: [
                  {
                    ...item,
                    IDARTICULO: item.IDARTICULO,
                    NAME: arr[2],
                    VALUE: 1,
                  },
                ],
              });
            } else {
              obj.SUBOBJETOS[idx].SUBOBJETOS.push({
                ...item,
                IDARTICULO: item.IDARTICULO,
                NAME: arr[2],
                VALUE: obj.SUBOBJETOS[idx].SUBOBJETOS.length + 1,
              });
            }
            break;
        }
      });

      await dispatch("setMaxQuantity");
      return finalArr;
    }, */
    /* async getCapacitySchedule({ state, commit, dispatch }) { //ELIMINADO
      try {
        if (!this.state.sale.finalPayload.category) return;
        const articleId = await dispatch("getUniqueArticleIdSelected");
        if (!articleId) return;
        // Parametros
        const idParque = state.currentDomainPark.IDPARQUE;
        const idArticulo = articleId;
        const idCategoria = this.state.sale.finalPayload.category.IDCATEGORIA || categoryId;
        // Enpoint
        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/configuracion`
        // Consulta Servicio
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            // accion: "consultarConfiguracionProcesoAdicionalUno",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdCategoria:
            //   this.state.sale.finalPayload.category.IDCATEGORIA || categoryId,
            // iIdArticulo: articleId,
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;
        const response = await dispatch("decodeResponse", data.data);
        commit("setCapacitySchedule", response);
      } catch (err) {}
    }, */
    /* async getUniqueArticleIdSelected({ state, dispatch }) {
      // Obtiene el único id de articulo elegido
      const article = state.articlesList.find(
        (art) => art.CANTIDADSELECCIONADA > 0
      );
      if (!article) return 0;
      const articleId = await dispatch("getArticleId", article);
      return articleId;
    }, */
    /* getArticleId({}, article) {
      // Busca articulo en un articulo anidado
      if (article.LEVELS == 1) {
        return article.IDARTICULO;
      } else if (article.LEVELS == 2) {
        return article.selectedLvl1 ? article.selectedLvl1.IDARTICULO : "";
      } else {
        return article.selectedLvl2 ? article.selectedLvl2.IDARTICULO : "";
      }
    }, */
    /* async setMaxQuantity({ state }) {
      const promises = state.articlesList.map((it) => {
        // seteando cantidad final
        return new Promise((resolve, reject) => {
          this.dispatch("getMaxQuantity", it).then((max) => {
            it.CANTIDADMAXIMAFINAL = max;
            resolve();
          });
        });
      });
      await Promise.all(promises);
      this.commit("updateBaseIndex");
    }, */
    /* async getAditionalProccess(
      { state, commit, dispatch, getters },
      { categoryId = "", paramArticle = false, idArticle }
    ) {
      try {
        let articleId = "";
        if (!this.getters.validateCombination && !paramArticle) {
          articleId = await dispatch("getUniqueArticleIdSelected");
          categoryId = this.state.sale.finalPayload.category.IDCATEGORIA;
        }
        // Parámetros
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria = categoryId;
        const idArticulo = idArticle || articleId || 0;

        // Endpoint
        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/configuracion`;

        // Consulta Servicio
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            // accion: "consultarConfiguracionProcesoAdicionalUno",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdCategoria: categoryId,
            // iIdArticulo: idArticle || articleId || 0,
            // iIdSistema: 2,
          },
        });

        if (status != 200)
          return {
            haveError: true,
            haveSchedules: false,
            haveRoutes: false,
            isOnlyAforo: false,
          };

        // const response = data.data; // await dispatch("decodeResponse", data.data);

        const config = data.data;

        if (config.AFORO == 0 && config.HORARIO == 0 && config.RUTA == 0)
          return {
            haveSchedules: false,
            haveRoutes: false,
            isOnlyAforo: false,
          };

        if (config.AFORO == 1 && config.HORARIO == 0 && config.RUTA == 0)
          return { haveSchedules: false, haveRoutes: false, isOnlyAforo: true };

        if (config.AFORO > 0 && config.RUTA > 0)
          return { haveSchedules: true, haveRoutes: true, isOnlyAforo: false };

        if (config.AFORO > 0 && config.HORARIO > 0)
          return { haveSchedules: true, haveRoutes: false, isOnlyAforo: false };

        return {
          haveError: false,
          haveSchedules: false,
          haveRoutes: false,
          isOnlyAforo: false,
        };
      } catch (error) {
        return {
          haveError: true,
          haveSchedules: false,
          haveRoutes: false,
          isOnlyAforo: false,
        };
      }
    }, */
    /* async validateAditionalProccess(
      { state, commit, dispatch, getters },
      { categoryId = "", paramArticle = false, idArticle }
    ) {
      try {
        let articleId = "";
        if (!this.getters.validateCombination && !paramArticle) {
          articleId = await dispatch("getUniqueArticleIdSelected");
          categoryId = this.state.sale.finalPayload.category.IDCATEGORIA;
        }
        // Parámetros
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria = categoryId;
        const idArticulo = idArticle || articleId || 0;

        // Endpoint
        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/configuracion`;
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            // accion: "consultarConfiguracionProcesoAdicionalUno",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdCategoria: categoryId,
            // iIdArticulo: idArticle || articleId || 0,
            // iIdSistema: 2,
          },
        });

        if (status != 200) {
          this.commit("setConfigurationProcess", {
            HORARIO: 0,
            RUTA: 0,
          });
          return { AFORO: 0, RUTA: 0, HORARIO: 0 };
        }

        const response = data.data; // await dispatch("decodeResponse", data.data);

        this.commit("setConfigurationProcess", {
          HORARIO: response.HORARIO,
          RUTA: response.RUTA,
        });

        return response;
      } catch (error) {
        this.commit("setConfigurationProcess", {
          HORARIO: 0,
          RUTA: 0,
        });
        return { AFORO: 0, RUTA: 0, HORARIO: 0 };
      }
    }, */
    /* async validateAforo({ state, getters, dispatch }, config) {
      if (!config) return false;

      const { CANTIDAD, AFORO, ENABLED } = config;

      if (!config.TOTAL) {
        if (getters.getTotalPeople > parseFloat(CANTIDAD)) return false;
      } else {
        if (config.TOTAL > parseFloat(CANTIDAD)) return false;
      }

      return true;
    }, */
    async fetchRoutes({ state, commit, dispatch }, config) {
      try {
        const articleId = await dispatch("getUniqueArticleIdSelected");

        // Parámetros
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria = config ? config.categoryId : 0;
        const idArticulo =
          config && config.articleId
            ? config.articleId
            : this.getters.validateCombination
            ? 0
            : articleId;

        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/rutas`;
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            // accion: "consultarRutas",
            // iIdSistema: 2,
            // //
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdCategoria: config ? config.categoryId : 0,
            // iIdArticulo:
            //   config && config.articleId
            //     ? config.articleId
            //     : this.getters.validateCombination
            //     ? 0
            //     : articleId,
          },
        });
        if (status != 200) return null;
        const response = data.data; // await dispatch("decodeResponse", data.data);

        if (config && config.setList == true) {
          commit("setRoutes", response);
        }
        return response;
      } catch (error) {
        return null;
      }
    },
    async fetchCordinateRoutes({ state, dispatch }, routeId) {
      try {
        if (!routeId) return [];
        const { status, data } = await apiPide.get("/", {
          params: {
            accion: "consultarListadoRutasCoordinadas",
            idioma: state.lang,
            iIdSistema: 2,
            //
            iIdParque: state.currentDomainPark.IDPARQUE,
            iIdRuta: routeId,
          },
        });
        if (status != 200) return [];
        const response = dispatch("decodeResponse", data.data);
        return response;
      } catch (error) {
        return [];
      }
    },
    async fetchPinesRoute({ state, dispatch }, routeId) {
      try {
        if (!routeId) return [];
        const { status, data } = await apiPide.get("/", {
          params: {
            accion: "consultarListadoPines",
            idioma: state.lang,
            iIdSistema: 2,
            //
            iIdParque: state.currentDomainPark.IDPARQUE,
            iIdRuta: routeId,
            iIdTipoPin: 0,
            iIdPin: 0,
            vDescripcion: "",
          },
        });
        if (status != 200) return [];
        const response = await dispatch("decodeResponse", data.data);
        return response;
      } catch (error) {
        return [];
      }
    },
    /* async fetchSchedules({ state, commit, dispatch }, config) {
      try {
        //if (!this.state.sale.finalPayload.dateSelected) return;
        const articleId = await dispatch("getUniqueArticleIdSelected");
        // Parámetros
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCategoria = config ? config.categoryId : 0;
        const idArticulo = config && config.articleId
          ? config.articleId
          : this.getters.validateCombination
          ? 0
          : articleId;
        const idRuta = config && config.routeId ? config.routeId : 0;
        const fechaVisita = config
          ? config.dateSelected
          : this.state.sale.finalPayload.dateSelected;
        const horaInicio = config.startTime ? config.startTime : "";
        const horaFin = config.endTime ? config.endTime : "";
        const opcion = config ? config.option : 1;
        // Endpoint 
        const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/horarios`;
        // Consulta Servicio
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            idRuta,
            fechaVisita: transformDate({ date: fechaVisita }),
            horaInicio,
            horaFin,
            opcion,
            // accion: "cargarListadoHorarios",
            // iIdSistema: 2,
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdCategoria: config ? config.categoryId : 0,
            // iIdArticulo:
            //   config && config.articleId
            //     ? config.articleId
            //     : this.getters.validateCombination
            //     ? 0
            //     : articleId,
            // iIdRuta: config && config.routeId ? config.routeId : 0,
            // vFecha: config
            //   ? config.dateSelected
            //   : this.state.sale.finalPayload.dateSelected,
            // vHoraInicio: config.startTime ? config.startTime : "",
            // vHoraFin: config.endTime ? config.endTime : "",
            // iOpcion: config ? config.option : 1,
          },
        });
        if (status != 200) return null;

        const response = data.data; // await dispatch("decodeResponse", data.data);

        if (config && config.setList == true) {
          commit("setSchedules", response);
        }
        return response;
      } catch (error) {
        return null;
      }
    }, */
    /* async fetchFAQs({ state, commit, dispatch }) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const endpoint = `/parques/${idParque}/preguntasyrespuestas`;
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            // accion: "consultarPreguntasRespuestas",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;
        const response = data.data; // await dispatch("decodeResponse", data.data);
        commit("setFAQs", response);
      } catch (error) {}
    }, */
    /* async fetchTermsAndConditions({ state, commit }) {
      try {
        const idParque = state.currentDomainPark.IDPARQUE;
        const endpoint = `/parques/${idParque}/terminosycondiciones`
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            // accion: "consultarTerminosCondiciones",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdSistema: 2,
          },
        });
        if (status != 200) return null;
        commit("setTermsAndConditions", data.data);
      } catch (error) {}
    }, */
    async changeDateToVisit({ state, dispatch }, payload) {
      try {
        if (!payload) return false;
        const idParque = state.currentDomainPark.IDPARQUE;
        const idCompra = payload.IDCOMPRA;
        const configuration = this.getters.getConfigurationProccess;
        const body = {
          idSistema: 2,
          fechaVisita: transformDate({ date: payload.NUEVAFECHAVISITA }),
          idRuta: 0,
          horaInicio: payload.HORARIOINICIO,
          horaFin: payload.HORARIOFIN,
          horario: configuration.HORARIO,
          ruta: configuration.RUTA,
        };
        const endpoint = `/parques/${idParque}/compras/${idCompra}/cambiofechavisita`;
        const { data, status } = await axios.put(endpoint, body);

        // const { status, data } = await axios.get("/", {
        //   params: {
        //     accion: "actualizarFechaVisitacompra",
        //     idioma: state.lang,
        //     iIdSistema: 2,
        //     iIdParque: state.currentDomainPark.IDPARQUE,
        //     iIdCompra: payload.IDCOMPRA,
        //     vFecha: payload.NUEVAFECHAVISITA,
        //     iIdRuta: 0,
        //     vHoraInicio: payload.HORARIOINICIO,
        //     vHoraFin: payload.HORARIOFIN,
        //     iHorario: configuration.HORARIO,
        //     iRuta: configuration.RUTA,
        //   },
        // });

        if (status != 200) {
          this.dispatch("generateSnackbarError", {
            en:
              "An error has occurred and the date could not be updated, please try again later.",
            es:
              "Ha ocurrido un error y la fecha no ha podido actualizarse, por favor, intentelo más tarde.",
          });
          return null;
        }

        await dispatch("generateMessageStatus", {
          title: {
            en: "Date updated",
            es: "Fecha actualizada",
          },
          html: {
            es: `<p class="text-center"><span > Tu fecha de visita ha sido actualizada correctamente </span> </p>`,
            en: `<p class="text-center"><span > Your visit date has been successfully updated </span> </p>`,
          },
          type: "success",
          confirmButtonText: {
            en: "Ok",
            es: "Aceptar",
          },
          footer: {
            es: `<p class="text-center"><span > <strong>Fecha de visita: </strong> ${payload.NUEVAFECHAVISITA}</span> </p>`,
            en: `<p class="text-center"><span > <strong>Visit date: </strong> ${payload.NUEVAFECHAVISITA}</span> </p>`,
          },
          allowOutsideClick: false,
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    /* async fetchPaymentList({ state, commit, dispatch }) {
      try {
        // Parametros
        const idParque = state.currentDomainPark.IDPARQUE;
        // Endpoint
        const endpoint = `/parques/${idParque}/mediosdepago`;
        // Consulta Servicio
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            idSistema: 2,
            // accion: "consultarListadoMedioPago",
            // iIdParque: state.currentDomainPark.IDPARQUE,
          },
        });
        if (status != 200) return null;
        const response = data.data; // await dispatch("decodeResponse", data.data);

        commit("setPaymentList", response);
        return response;
      } catch (error) {}
    }, */
    /* async fetchPaymentKeys({ state, dispatch }, paymentId = 1) {
      try {
        // Parametros
        const idParque = state.currentDomainPark.IDPARQUE;
        const idFormaPago = paymentId;
        const idEstado = state.currentDomainPark.IDESTADODESARROLLO;

        // Endpoint
        const endpoint = `/parques/${idParque}/mediosdepago/${idFormaPago}/llaves`;
        // Consulta Servicio
        const { status, data } = await axios.get(endpoint, {
          params: {
            idioma: state.lang,
            idSistema: 2,
            idEstado,
            // accion: "consultarLlavesMedioPago",
            // iIdParque: state.currentDomainPark.IDPARQUE,
            // iIdEstado: state.currentDomainPark.IDESTADODESARROLLO,
            // iIdFormaPago: paymentId,
          },
        });
        if (status != 200) return null;
        const response = data.data; // await dispatch("decodeResponse", data.data);
        return response;
      } catch (error) {
        return null;
      }
    }, */
    /* openPDFOnNewWindow({ state }, pdfId) { // En desuso
      const URL = `${state.apiURL}/index.php?accion=view&file=${pdfId}&idioma=${state.lang}`;
      window.open(URL);
    }, */
    /* async downloadPDF({ state }, { pdfId, purchaseId }) { // En desuso
      try {
        const { data, status } = await axios.get("/", {
          params: {
            accion: "view",
            file: pdfId,
            idioma: state.lang,
          },
          responseType: "blob",
        });
        if (status != 200) return null;
        const url = window.URL.createObjectURL(data);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${purchaseId}.pdf`;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        return true;
      } catch (error) {
        return null;
      }
    }, */
    /* async getUserIP({ commit }) {
      const { data, status } = await axios.get(
        "https://api.ipify.org?format=json"
      );
      if (status != 200) return;
      commit("setLocalIP", data.ip);
    }, */
    /* async generateVisaNetToken({}, { VISANET_USERNAME, VISANET_PASSWORD }) {
      try {
        if (!VISANET_USERNAME || !VISANET_PASSWORD) return null;
        const base64 = encBtoa(VISANET_USERNAME + ":" + VISANET_PASSWORD);
        const { data, status } = await AxiosDefault.post(
          process.env.VUE_APP_VISANET_TOKEN_URL,
          {},
          {
            headers: {
              Authorization: `Basic ${base64}`,
            },
            timeout: 1000 * 20, // 20 segundos
          }
        );
        if (status != 200 && status != 201) return null;
        return data;
      } catch (error) {
        return null;
      }
    }, */
    /* async generateVisaNetSession(
      { state, dispatch },
      { merchantId, token, email }
    ) {
      try {
        if (!merchantId || !token) return null;
        const sessionURL = `${process.env.VUE_APP_VISANET_SESSION_URL}/${merchantId}`;
        const mdd = await dispatch("getMerchantDefinedData", email);
        const { data, status } = await AxiosDefault.post(
          sessionURL,
          {
            amount: this.getters.getArticlesTotal,
            recurrenceMaxAmount: null,
            channel: "web",
            antifraud: {
              clientIp: state.localIP,
              merchantDefineData: mdd,
            },
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            timeout: 1000 * 20, // 20 segundos
          }
        );
        if (status != 200 && status != 201) return null;
        return data;
      } catch (error) {
        return null;
      }
    }, */
    /* async getMerchantDefinedData({ state }, email) {
      let mdd = {
        MDD4: "",
        MDD32: "",
        MDD75: "",
        MDD21: "",
      };
      const user = this.state.auth.user;

      if (state.global.MDD == 1) {
        if (!user) {
          // INVITADO
          mdd["MDD4"] = email;
          mdd["MDD32"] = email;
          mdd["MDD75"] = "Invitado";
          mdd["MDD21"] = 0;
        } else {
          mdd["MDD4"] = user.CORREO;
          mdd["MDD32"] = user.CORREO;
          mdd["MDD75"] = "Registrado";
          mdd["MDD77"] = user.DIASREGISTRO || 0;
          mdd["MDD21"] = user.RECURRENTE || 0;
        }
      } else {
        const dateToReplace = `_${moment().format("HHmmss")}@`;
        mdd["MDD4"] = email.replace("@", dateToReplace);
        mdd["MDD32"] = email.replace("@", dateToReplace);
        mdd["MDD75"] = "Registrado";
        mdd["MDD77"] = 0;
        mdd["MDD21"] = 0;
      }

      return mdd;
    }, */
    /* async insertHeaderPurchase({ state, dispatch, getters }, payment) {
      try {
        const user = this.state.auth.user;
        const sale = this.state.sale.finalPayload;
        const schedule = this.getters.getScheduleSelected;
        const configuration = this.getters.getConfigurationProccess;
        const agent = navigator.userAgent || "";
        if (getters.haveAditionalProccess) {
          const validated = await this.dispatch("validateAllBeforeSave");

          if (!validated) return null;
        }

        const params = {
          // accion: "insertarCabeceraCompra",
          //
          iIdParque: state.currentDomainPark.IDPARQUE,
          iIdSistema: 2,
          iIdUsuario: user ? user.IDUSUARIO : 0,
          vFechaVisita: sale.dateSelected,
          vCorreo: payment.email,
          vNombres: payment.names,
          vApellidos: payment.lastnames,
          dTotal: this.getters.getArticlesTotal,
          idioma: state.lang,
          resultMail: "",
          version: agent,
          iTipoDocumento: payment.documentType,
          vNumeroDocumento: payment.document,
          vTipoComprobante: payment.voucherType, // 01 => Factura, 03 => Boleta normal
          vNumeroRUC: payment.facturation.ruc || "",
          vRazonSocial: payment.facturation.rSocial || "",
          vDireccion: payment.facturation.direction
            ? payment.facturation.direction.substring(0, 100)
            : "",
          cIdUbigeo: payment.facturation.ubigee || "000000",
          iIdRuta: 0,
          vHoraInicio: schedule ? schedule.HORAINICIO : "",
          vHoraFin: schedule ? schedule.HORAFIN : "",
          iHorario: configuration.HORARIO || 0,
          iRuta: configuration.RUTA || 0,
          cIdUbigeoDir: payment.district,
        };

        const { status, data } = await axios.get("/", {
          params,
        });

        if (status != 200) return null;
        const response = await dispatch("decodeResponse", data.data);
        return response[0];
      } catch (error) {
        return null;
      }
    }, */
    /* async insertPurchaseDetail({ state, dispatch }, article) {
      try {
        const { status, data } = await axios.get("/", {
          params: {
            // accion: "insertarDetalleCompra",
            iIdCompra: article.IDCOMPRA,
            iIdParque: state.currentDomainPark.IDPARQUE,
            iIdSistema: 2,
            iIdArticulo: article.IDARTICULO,
            dPrecio: article.PRECIO,
            nCantidad: article.CANTIDADSELECCIONADA,
          },
        });
        if (status != 200) return false;

        const response = await dispatch("decodeResponse", data.data);

        if (response[0].IDCESTA != 1) {
          return false;
        }

        return true;
      } catch (error) {
        return false;
      }
    }, */
    /* async insertAllPurchaseDetail({ state, dispatch }, purchaseId) {
      try {
        let promises = [];
        state.articlesList.forEach((article) => {
          if (article.CANTIDADSELECCIONADA > 0) {
            switch (article.LEVELS) {
              case 1: // Articulo nivel 1 => solo
                promises.push(
                  dispatch("insertPurchaseDetail", {
                    IDCOMPRA: purchaseId,
                    IDARTICULO: article.IDARTICULO,
                    PRECIO: article.PRECIO,
                    CANTIDADSELECCIONADA: article.CANTIDADSELECCIONADA,
                  })
                );
                break;
              case 2:
                promises.push(
                  dispatch("insertPurchaseDetail", {
                    IDCOMPRA: purchaseId,
                    IDARTICULO: article.selectedLvl1.IDARTICULO,
                    PRECIO: article.selectedLvl1.PRECIO,
                    CANTIDADSELECCIONADA: article.CANTIDADSELECCIONADA,
                  })
                );
                break;
              case 3:
                promises.push(
                  dispatch("insertPurchaseDetail", {
                    IDCOMPRA: purchaseId,
                    IDARTICULO: article.selectedLvl2.IDARTICULO,
                    PRECIO: article.selectedLvl2.PRECIO,
                    CANTIDADSELECCIONADA: article.CANTIDADSELECCIONADA,
                  })
                );
                break;
            }
          }
        });
        const response = await Promise.all(promises);

        const errors = response.filter((res) => res == false);

        return errors.length == 0 ? true : false;
      } catch (error) {
        return false;
      }
    }, */
    /* async registerCompanionList(
      { state },
      { companionList = [], purchaseId = "" }
    ) {
      try {
        let strDocumentsList = "";
        let strTypesDocList = "";
        companionList.forEach((doc) => {
          strDocumentsList += `|${doc.DOCUMENTO}`;
          strTypesDocList += `|${doc.TIPO_DOCUMENTO}`;
        });
        const { status, data } = await axios.get("/", {
          params: {
            // accion: "registrarDocumentosAcompaniantes",
            iIdParque: state.currentDomainPark.IDPARQUE,
            iIdSistema: 2,
            iIdCompra: purchaseId,
            vDocumentosAcompaniantes: strDocumentsList,
            iTipoDocumentoAcompaniantes: strTypesDocList,
          },
        });

        if (status != 200) {
          // error register
          return false;
        }

        return true;
      } catch (error) {
        return false;
      }
    }, */
    /*  async updatePurchaseStatusFree({ state, dispatch }, payload) {
      try {
        if (!payload) return false;

        const { status, data } = await axios.get("/", {
          params: {
            // accion: "actualizarEstadoCompra",
            iIdParque: state.currentDomainPark.IDPARQUE,
            iIdSistema: 2,
            iIdEstado: 2,
            iIdCompra: payload.purchaseId,
            iIdFormaPago: payload.paymentType,
            result: "Venta Gratuita",
          },
        });

        if (status != 200) {
          // error update
          return false;
        }

        if (!this.getters.validateLotery) {
          // await this.dispatch("fetchPurchaseDetail", {
            purchaseId: payload.purchaseId,
            sendMail: 1,
          });
        }
        return true;
      } catch (error) {
        return false;
      }
    }, */
    /* async cancelPurchase({ state, dispatch }, payload) {
      try {
        if (!payload) return false;

        const { status, data } = await axios.get("/", {
          params: {
            // accion: "actualizarEstadoCompra",
            iIdParque: state.currentDomainPark.IDPARQUE,
            iIdSistema: 2,
            iIdEstado: 3,
            iIdCompra: payload.purchaseId,
            iIdFormaPago: payload.paymentType,
            result: "Tu compra fue cancelada",
          },
        });

        if (status != 200) return false;

        if (payload.noMessage) return true;

        const paymentError = {
          es: `<br>El medio de pago configurado está presentando fallas técnicas.<br>Por favor, vuelva a intentarlo más tarde.`,
          en: `<br>The configured payment method is experiencing technical failures.<br>Please try again later.`,
        };

        // Mensaje tu compra fue cancelada
        await dispatch("generateMessageStatus", {
          title: {
            en: "Your purchase could not be processed",
            es: "Tu compra no se pudo procesar",
          },
          type: "error",
          html: {
            es: `<p class="text-center"><span > Tu compra fue cancelada. </span> </p>`,
            en: `<p class="text-center"><span > Your purchase was canceled. </span> </p>`,
          },
          type: "error",
          confirmButtonText: {
            en: "Ok",
            es: "Aceptar",
          },
          footer: {
            es: `<p class="text-center"><span > <strong>Número de compra: </strong> ${payload.purchaseId}</span> </p>`,
            en: `<p class="text-center"><span > <strong>Purchase number: </strong> ${payload.purchaseId}</span> </p>`,
          },
          allowOutsideClick: false,
        });
        return true;
      } catch (error) {
        return false;
      }
    }, */
    /* async openVisaNetForm({ state, dispatch }, config = {}) {
      if (!config) return false;
      const newToken = await dispatch("generateVisaNetToken", {
        VISANET_USERNAME: config.vUsername,
        VISANET_PASSWORD: config.vPassword,
      });
      const user = this.getters.getSessionUser;
      const email = user ? user.CORREO : config.email;
      const visa = VisanetCheckout.configure({
        sessiontoken: config.sessionToken,
        channel: "web",
        attachEvent: true,
        merchantid: config.merchantId,
        formbuttoncolor: "#009688",
        formbackgroundcolor: "#FFFFFF",
        purchasenumber: config.purchaseId,
        showamount: "true",
        amount: this.getters.getArticlesTotal,
        //merchantlogo: state.currentDomainPark.URLFOTOLOGO ? imgService(state.currentDomainPark.URLFOTOLOGO) : '',
        merchantname: state.currentDomainPark.DESCRIPCION,
        cardholdername: config.names,
        cardholderlastname: config.lastNames,
        cardholderemail: config.email,
        //usertoken: '<?php echo $_SESSION["USUARIOCOMPRA"]["CORREO"]; ?>',
        expirationminutes: 5,
        timeouturl: `${window.location.protocol}//${window.location.hostname}`,
        action: `${window.location.protocol}//${window.location.hostname}/finalizarcompra.php?purchaseId=${config.purchaseId}&parkId=${state.currentDomainPark.IDPARQUE}&paymentType=${config.paymentType}&amount=${this.getters.getArticlesTotal}&merchantId=${config.merchantId}&token=${newToken}&sessionV=${config.sessionToken}&lang=${state.lang}&email=${email}`,
        complete: function(params) {},
      });

      VisanetCheckout.open();
    }, */
    /* async generateSecurityToken({ dispatch }) {
      try {
        const { data, status, headers } = await axios.post("/", {
          // accion: "generarTokenSeguridad",
          iIdSistema: 2,
        });
        console.log("headers", headers, headers["set-cookie"]);
        if (status != 200) return null;
        if (!data.data || data.data.length == 0) return null;
        const tokenEncripted = data.data[0]["token"];
        console.log("TOKEN ENCRIPTADO", tokenEncripted);
        return tokenEncripted;
        if (!tokenEncripted) return null;
        const token = decodificar(tokenEncripted);
        console.log("Token desencriptado", token);
        return token;
      } catch (error) {
        return null;
      }
    }, */
    /* async updatePurchaseDetail({}) {
      try {
        const { status, data } = await axios.get("/", {
          params: {
            // accion: "actualizarEstadoCompra",
            iIdParque: state.currentDomainPark.IDPARQUE,
            iIdSistema: 2,
            iIdEstado: 3,
            iIdCompra: article.IDCOMPRA,
            iIdFormaPago: article.PRECIO,
            result: article.CANTIDADSELECCIONADA,
          },
        });
      } catch (error) {}
    }, */
    async generateMessageStatus(
      { state },
      {
        title,
        message,
        html,
        type = "success",
        footer,
        confirmButtonText,
        allowOutsideClick,
        callback = () => {},
      }
    ) {
      // Genera un mensaje con Swal
      Swal.fire({
        icon: type,
        title: title ? title[state.lang] : "",
        text: message ? message[state.lang] : "",
        html: html ? html[state.lang] : "",
        confirmButtonColor: "#074882",
        confirmButtonText: confirmButtonText
          ? confirmButtonText[state.lang]
          : "",
        footer: footer ? footer[state.lang] : "",
        allowOutsideClick: allowOutsideClick,
      })
        .then(callback)
        .catch((err) => {
          return false;
        });
    },
    async decodeResponse({ state, commit, dispatch }, data) {
      // Decodifica texto y objetos simples
      if (Array.isArray(data)) {
        return data.map((obj) => {
          let newObj = {};
          Object.keys(obj).forEach((key) => {
            newObj[decodificar(key)] = decodificar(obj[key]);
          });
          return newObj;
        });
      } else if (typeof data == "object") {
        const obj = data;
        let newObj = {};
        Object.keys(obj).forEach((key) => {
          newObj[decodificar(key)] = decodificar(obj[key]);
        });
        return newObj;
      } else {
        return decodificar(data);
      }
    },
    async decodeJSON({}, data) {
      // Decodifica un JSON response
      const str = JSON.stringify(data);
      const decoded = decodificar(str);
      return JSON.parse(decoded);
    },
    async encodeData({}, data) {
      // Codifica la Data
      const str = JSON.stringify(data);
      return JSON.parse(codificar(str));
    },
  },
  modules: {
    sale,
    auth,
    shopping,
    pide,
    ubigee,
    timer,
    about,
    info,
    navigation,
    accesibility,
    parkModule,
    paymentModule,
  },
});

function getArticleInObject(article) {
  // Busca articulo en agrupación

  let articleObj = article;

  if (article.LEVELS == 2) {
    if (!article.selectedLvl1) return null;
    articleObj = article.selectedLvl1;
  } else if (article.LEVELS == 3) {
    if (!article.selectedLvl2) return null;
    articleObj = article.selectedLvl2;
  }

  return articleObj;
}
