//FACEBOOK
// app_id: '2327575520852119',
// app_secret: '4ba10daa85f3d3dd76dc68fc51199b5e',

export const initFbsdk = () => {
    return new Promise(resolve => {
        window.fbAsyncInit = function() {
            FB.init({
                appId: process.env.VUE_APP_FB_ID,
                cookie: true, // enable cookies to allow the server to access the session
                xfbml: true, // parse social plugins on this page
                version: 'v12.0' // use graph api version 2.8
            })
        }(function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0]
            if (d.getElementById(id)) return
            js = d.createElement(s);
            js.id = id
            js.src = '//connect.facebook.net/en_US/all.js'
            fjs.parentNode.insertBefore(js, fjs)
        }(document, 'script', 'facebook-jssdk'))
    })
}