<template>
  <div class="lm--title-section" :class="{'pl-0': btnBack, 'no-before': btnBack}">
    <div class="d-flex align-items-center justify-content-between">
      <div>
        <div class="d-flex align-items-center">
          <v-btn
            v-if="btnBack"
            @click="goBack"
            title="Regresar"
            color="tertiary"
            fab
            depressed
            x-small
            dark
            class="mr-2"
          >
            <span class="material-icons"> chevron_left </span>
          </v-btn>

          <h3
            :class="
              `lm--title ${dark ? 'text-white' : ''} ${
                smallText ? 'text-small' : ''
              }`
            "
          >
            {{ title }}
          </h3>
        </div>
        <p
          v-if="subtitle"
          :class="
            `lm--subtitle  ${btnBack ? 'ml-28' : ''} ${
              dark ? 'text-white' : ''
            } ${smallText ? 'text-small' : ''}`
          "
        >
          {{ subtitle }}
        </p>
      </div>
      <div v-if="!noLogo" class="logo-site-container">
        <!-- park ? loadImg(park.URLFOTOLOGO) :  -->
        <img
          :src="require('@/assets/img/logo-app.png')"
          draggable="false"
          height="45"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import { imgService } from "@/libs/media";
import { mapGetters } from "vuex";
export default {
  props: {
    title: String,
    subtitle: String,
    btnBack: Boolean,
    noLogo: Boolean,
    smallText: Boolean,
    dark: Boolean,
    backHome: Boolean
  },
  computed: {
    ...mapGetters({
      park: "getCurrentParkByDomain"
    })
  },
  methods: {
    goBack() {
      if(this.backHome) {
        this.$router.push("/")
        return;
      }
      window.history.back(-1);
    },
    loadImg(src) {
      return imgService(src);
    }
  }
};
</script>

<style lang="scss" scoped>
.lm--title-section {
  width: 100%;
  position: relative;
  padding-left: 15px;
  font-family: $primary-font;
  &.no-before {
    @media screen and (max-width: 640px) {
      padding-left: 0;
    }
  }
  &.no-before::before {
    display: none !important;
  }
  .logo-site-container {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 4px 6px;
    box-sizing: border-box;
    border-radius: 3px;
  }
  .lm--title {
    color: $color-tertiary;
    margin-bottom: 0;
    padding-bottom: 0;
    font-size: 1.35rem;
    /* &::first-letter:not(.text-white) {
      color: var(--color-pink);
    } */
    &.text-white {
      color: #fff !important;
    }
    &.text-small {
      font-size: 1.2rem;
      font-weight: 500 !important;
      @media screen and (max-width: 700px) {
        font-size: 1.12rem;
      }
      @media screen and (max-width: 600px) {
        font-size: 1.1rem;
      }
      @media screen and (max-width: 500px) {
        font-size: 1.15rem;
      }
      @media screen and (max-width: 400px) {
        font-size: 1rem;
      }
    }
    @media screen and (max-width: 700px) {
      font-size: 1.3rem;
    }
    @media screen and (max-width: 600px) {
      font-size: 1.28rem;
    }
    @media screen and (max-width: 500px) {
      font-size: 1.2rem;
    }
    @media screen and (max-width: 400px) {
      font-size: 1rem;
    }
  }
  .lm--subtitle {
    margin-bottom: 0;
    color: rgba(0, 0, 0, 0.68);
    font-size: 14px;
    &.text-white {
      color: #fff !important;
    }
    @media screen and (max-width: 700px) {
      font-size: 13px;
    }
    @media screen and (max-width: 400px) {
      font-size: 12px;
    }
  }
}
.ml-28 {
  margin-left: 42px;
}
.lm--title-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 4px;
  background: var(--color-pink);
}
</style>
