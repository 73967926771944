export function initAnimation() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
    })
    TweenMax.from(".init", 1, {
        delay: .15,
        opacity: 0,
        y: -10,
        ease: Expo.easeInOut
    });

    let t1 = new TimelineMax();

    t1.from(".ringOne", 3, {
        delay: 0.7,
        opacity: 0,
        //y: 170,
        top: "45%",
        ease: Expo.easeInOut
    });

    TweenMax.from(".h3-init", 1, {
        delay: 2.2,
        opacity: 0,
        y: -15,
        ease: Expo.easeInOut
    });

    setTimeout(() => {
        const ring = document.getElementById('ring');
        ring.style.display = 'flex'
    }, 1500)


}

export function finalAnimation(callback) {

    TweenMax.to(".init", 3, {
        delay: 1,
        top: "-200vh",
        ease: Expo.easeInOut,
        onComplete: function() {
            document.body.style.overflowY = 'visible';
            document.documentElement.style.overflowY = 'visible';
            callback()
        }
    });

    TweenMax.to(".ring", 3, {
        delay: 1.15,
        top: "-500%",
        ease: Expo.easeInOut,
    });

    TweenMax.from(".lm--cover-page", 3, {
        delay: 1.3,
        opacity: 0,
        y: 100,
        ease: Expo.easeInOut,
    });


    TweenMax.from(".v-main__wrap", 3, {
        delay: 1.3,
        opacity: 0,
        ease: Expo.easeInOut,
    });

    TweenMax.from(".lm--container-searcher", 3, {
        delay: 1.5,
        opacity: 0,
        ease: Expo.easeInOut,
    });


    TweenMax.from(".nav-link-lateral", 3, {
        delay: 1.1,
        opacity: 0,
        y: 12,
        ease: Expo.easeInOut
    });


    TweenMax.from(".navbar-brand", 3, {
        delay: 2,
        opacity: 0,
        y: 20,
        ease: Expo.easeInOut
    });

    TweenMax.from(".nav-link", 3, {
        delay: 2,
        opacity: 0,
        y: 20,
        ease: Expo.easeInOut
    });
}