import store from "@/store/index";

export const imgService = (filename) => {
    if (!filename) return "";
    return `${store.state.baseURL}/repositorio/${filename}`;
}

export const imgServicePark = (filename) => {
    if(!filename) return "";
    const parkSlug = store.state.currentDomainPark?.ABREVIATURA || store.state.currentDomainPark?.SLUG;
    if(filename.includes(parkSlug))
        return `${store.state.baseURL}/repositorio/${filename}`;

    return `${store.state.baseURL}/repositorio/parques/${parkSlug}/${filename}`;
}