import axios from "@/api/entradaslima";
import AxiosDefault from "axios";
import { transformDate } from "../utils/date";
import { encBtoa } from "@/libs/base64";
import { jwtDecode } from "jwt-decode";

const state = () => ({});
const getters = {};
const mutations = {};
const actions = {
  async getUserIP({ commit }) {
    const { data, status } = await AxiosDefault.get(
      "https://api.ipify.org?format=json"
    );
    if (status != 200) return;
    commit("setLocalIP", data.ip, { root: true });
  },
  async fetchPaymentList({ rootState, commit, dispatch }) {
    try {
      // Parametros
      const idParque = rootState.currentDomainPark.IDPARQUE;
      // Endpoint
      const endpoint = `/parques/${idParque}/mediosdepago`;
      // Consulta Servicio
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 2,
        },
      });
      if (status != 200) return null;
      const response = data.data;
      commit("setPaymentList", response, { root: true });
      return response;
    } catch (error) {}
  },
  async fetchPaymentKeys({ rootState, dispatch }, paymentId = 1) {
    try {
      // Parametros
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idFormaPago = paymentId;
      const idEstado = rootState.currentDomainPark.IDESTADODESARROLLO;
      // Endpoint
      const endpoint = `/parques/${idParque}/mediosdepago/${idFormaPago}/llaves`;
      // Consulta Servicio
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idSistema: 2,
          idEstado,
        },
      });
      if (status != 200) return null;
      const response = data.data;
      const jwtDecoded = jwtDecode(response.token);
      const paymentKeys = jwtDecoded.data;
      return paymentKeys;
    } catch (error) {
      return null;
    }
  },
  async generateVisaNetToken({}, { VISANET_USERNAME, VISANET_PASSWORD }) {
    try {
      if (!VISANET_USERNAME || !VISANET_PASSWORD) return null;
      const base64 = encBtoa(VISANET_USERNAME + ":" + VISANET_PASSWORD);
      const { data, status } = await AxiosDefault.post(
        process.env.VUE_APP_VISANET_TOKEN_URL,
        {},
        {
          headers: {
            Authorization: `Basic ${base64}`,
          },
          timeout: 1000 * 20, // 20 segundos
        }
      );
      if (status != 200 && status != 201) return null;
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  },
  async generateVisaNetSession(
    { rootState, dispatch, rootGetters },
    { merchantId, token, email }
  ) {
    try {
      if (!merchantId || !token) return null;
      const sessionURL = `${process.env.VUE_APP_VISANET_SESSION_URL}/${merchantId}`;
      const mdd = await dispatch("getMerchantDefinedData", email);
      const { data, status } = await AxiosDefault.post(
        sessionURL,
        {
          amount: rootGetters.getArticlesTotal,
          recurrenceMaxAmount: null,
          channel: "web",
          antifraud: {
            clientIp: rootState.localIP,
            merchantDefineData: mdd,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          timeout: 1000 * 20, // 20 segundos
        }
      );
      if (status != 200 && status != 201) return null;
      return data;
    } catch (error) {
      return null;
    }
  },
  async openVisaNetForm({ rootState, rootGetters, dispatch }, config = {}) {
    if (!config) return false;
    const newToken = await dispatch("generateVisaNetToken", {
      VISANET_USERNAME: config.vUsername,
      VISANET_PASSWORD: config.vPassword,
    });
    const user = rootGetters.getSessionUser;
    const email = user ? user.CORREO : config.email;
    const parkSlug = rootState.currentDomainPark.SLUG ?? "";
    const paramsVisanet = {
      sessiontoken: config.sessionToken,
      channel: "web",
      attachEvent: true,
      merchantid: config.merchantId,
      formbuttoncolor: "#009688",
      formbackgroundcolor: "#FFFFFF",
      purchasenumber: config.purchaseId,
      showamount: "true",
      amount: rootGetters.getArticlesTotal,
      //merchantlogo: rootState.currentDomainPark.URLFOTOLOGO ? imgService(rootState.currentDomainPark.URLFOTOLOGO) : '',
      merchantname: rootState.currentDomainPark.DESCRIPCION,
      cardholdername: config.names,
      cardholderlastname: config.lastNames,
      cardholderemail: config.email,
      //usertoken: '<?php echo $_SESSION["USUARIOCOMPRA"]["CORREO"]; ?>',*/
      expirationminutes: 5,
      timeouturl: `${window.location.protocol}//${window.location.hostname}`,
      action: `${window.location.protocol}//${window.location.hostname}/finalizarcompra.php?purchaseId=${config.purchaseId}&parkId=${rootState.currentDomainPark.IDPARQUE}&paymentType=${config.paymentType}&amount=${rootGetters.getArticlesTotal}&merchantId=${config.merchantId}&token=${newToken}&sessionV=${config.sessionToken}&lang=${rootState.lang}&email=${email}&parkSlug=${parkSlug}`,
      complete: function(params) {},
    };

    const visa = VisanetCheckout.configure(paramsVisanet);

    VisanetCheckout.open();
  },
  async getMerchantDefinedData({ rootState }, email) {
    let mdd = {
      MDD4: "",
      MDD32: "",
      MDD75: "",
      MDD21: "",
    };
    const user = rootState.auth.user;

    if (rootState.global.MDD == 1) {
      if (!user) {
        // INVITADO
        mdd["MDD4"] = email;
        mdd["MDD32"] = email;
        mdd["MDD75"] = "Invitado";
        mdd["MDD21"] = 0;
      } else {
        mdd["MDD4"] = user.CORREO;
        mdd["MDD32"] = user.CORREO;
        mdd["MDD75"] = "Registrado";
        mdd["MDD77"] = user.DIASREGISTRO || 0;
        mdd["MDD21"] = user.RECURRENTE || 0;
      }
    } else {
      const dateToReplace = `_${moment().format("HHmmss")}@`;
      mdd["MDD4"] = email.replace("@", dateToReplace);
      mdd["MDD32"] = email.replace("@", dateToReplace);
      mdd["MDD75"] = "Registrado";
      mdd["MDD77"] = 0;
      mdd["MDD21"] = 0;
    }

    return mdd;
  },
  async getArticlesSelected({ rootState, rootGetters }) {
    let articles = [];
    rootState.articlesList.forEach((article) => {
      if (article.CANTIDADSELECCIONADA > 0) {
        switch (article.LEVELS) {
          case 1: // Articulo nivel 1 => solo
            articles.push({
              idArticulo: article.IDARTICULO,
              precio: article.PRECIO,
              cantidad: article.CANTIDADSELECCIONADA,
              ...(rootGetters.contieneIdForo ? { idZona: article?.IDZONAARTICULO ?? 0 } : {})
            });
            break;
          case 2:
            articles.push({
              idArticulo: article.selectedLvl1.IDARTICULO,
              precio: article.selectedLvl1.PRECIO,
              cantidad: article.CANTIDADSELECCIONADA,
            });
            break;
          case 3:
            articles.push({
              idArticulo: article.selectedLvl2.IDARTICULO,
              precio: article.selectedLvl2.PRECIO,
              cantidad: article.CANTIDADSELECCIONADA,
            });
            break;
        }
      }
    });

    // Colocar idCategoria a los artículos
    for (const articulo of articles) {
      const idCategoria = rootGetters.getCategorySelected?.IDCATEGORIA;
      articulo["idCategoria"] = idCategoria;
    }

    // Añadir butacas en caso exista
    if (rootGetters.contieneIdForo) {
      for (const articulo of articles) {
        
        const horarioArticulo = rootGetters.obtenerHorariosCategoriaValidacion?.find(
          (horario) => horario.IDARTICULO == articulo?.idArticulo
        );
        const tipoZona = horarioArticulo.TIPOZONA;

        articulo["tipoZona"] = tipoZona;

        if (tipoZona == 2) {
          const butacasArticulo = rootGetters.getButacasList
            ?.filter((butaca) => butaca.IDARTICULO == articulo?.idArticulo)
            ?.map((butaca) => ({
              idButaca: butaca?.BUTACA?.IDBUTACA,
              idZona: butaca?.BUTACA?.IDZONA,
            }))
            ?.filter((butaca) => !!butaca);
          butacasArticulo?.length > 0 &&
            (articulo["butacas"] = butacasArticulo);
        }
      }
    }
    return articles;
  },
  async validateRegisterPurchase({ rootGetters, commit, dispatch }) {
    if (rootGetters.haveAditionalProccess && !rootGetters.contieneIdForo) {
      const validated = await dispatch("validateAllBeforeSave");
      if (!validated) return false;
    }

    if (rootGetters.contieneIdForo) {
      // Stock & max quantity
      const stockValidation = await dispatch(
        "validateStockAndMaxQuantityBeforeSave",
        this.state.articlesList
      );
      console.log({ stockValidation });
      if (!stockValidation) {
        commit(
          "setErrorDialog",
          {
            errors: [],
            title: { en: "Capacity not available", es: "Aforo no disponible" },
            subtitle: {
              en: "Dear customer, the maximum capacity of some items has been exceeded.",
              es:
                "Estimado cliente, el aforo máximo de algunos artículos ha sido superado.",
            },
            footer: {
              en: "Please reselect your items.",
              es: "Por favor, vuelva a seleccionar sus artículos.",
            },
          },
          { root: true }
        );
        commit("setDialogError", true, { root: true });
        return false;
      }
      const errorsValidation = await dispatch("validarAforoButacasZonas");
      console.log({ errorsValidation });
      if (errorsValidation?.length > 0) {
        commit(
          "setErrorDialog",
          {
            errors: errorsValidation,
            title: { en: "Capacity not available", es: "Aforo no disponible" },
            subtitle: {
              en: "Dear customer, the capacity of some items has changed.",
              es:
                "Estimado cliente, la capacidad de algunos artículos ha variado.",
            },
            footer: {
              en: "Please reselect your items.",
              es: "Por favor, vuelva a seleccionar sus artículos.",
            },
          },
          { root: true }
        );
        commit("setDialogError", true, { root: true });
        return false;
      }
    }
    return true;
  },
  async registerPurchase({ rootState, rootGetters, dispatch }, payment) {
    try {
      const user = rootState.auth.user;
      const sale = rootState.sale.finalPayload;
      const schedule = rootGetters.getScheduleSelected;
      const configuration = rootGetters.getConfigurationProccess;
      const agent = navigator.userAgent || "";

      const validPurchase = await dispatch("validateRegisterPurchase");
      if (!validPurchase) return null;

      const idParque = rootState.currentDomainPark.IDPARQUE;
      const articulos = await dispatch("getArticlesSelected");
      const payload = {
        idioma: rootState.lang,
        idSistema: 2,
        // version: agent,
        datosAdquiriente: {
          idUsuario: user ? user.IDUSUARIO : 0,
          correo: payment.email,
          tipoDocumento: payment.documentType,
          numeroDocumento: payment.document,
          nombres: payment.names,
          apellidos: payment.lastnames,
          ubigeo: payment.facturation.ubigee || "000000",
          acompaniantes:
            payment.escorts && payment.escorts.length > 0
              ? payment.escorts.map((escort) => ({
                  tipoDocumento: escort.TIPO_DOCUMENTO,
                  numeroDocumento: escort.DOCUMENTO,
                }))
              : undefined,
        },
        datosVisita: {
          fechaVisita: transformDate({ date: sale.dateSelected }),
          idRuta: 0,
          ...(rootGetters.contieneIdForo
            ? {
                // Horario con IDFORO
                horaInicio: sale?.horarioCategoria?.HORAINICIO || "",
                horaFin: sale?.horarioCategoria?.HORAFIN || "",
              }
            : {
                horaInicio: schedule?.HORAINICIO || "",
                horaFin: schedule?.HORAFIN || "",
              }),
          // horaInicio: schedule ? schedule.HORAINICIO : "",
          // horaFin: schedule ? schedule.HORAFIN : "",
          horario: rootGetters.contieneIdForo ? 1 : configuration.HORARIO || 0,
          ruta: configuration.RUTA || 0,
          // Nueva configuración con IDFORO
          tipoValidacion: sale?.category?.VALIDACIONINDIVIDUAL || 0,
          ...(rootGetters.contieneIdForo
            ? { idForo: sale?.category?.IDFORO || 0 }
            : {}),
        },
        datosFacturacion: {
          total: rootGetters.getArticlesTotal,
          tipoComprobante: payment.voucherType,
          ruc: payment.facturation.ruc || undefined,
          razonsocial: payment.facturation.rSocial || undefined,
          direccion: payment.facturation.direction
            ? payment.facturation.direction.substring(0, 100)
            : undefined,
          ubigeo: payment.facturation.ubigee || undefined,
          items: articulos,
        },
      };

      const endpoint = `/parques/${idParque}/compras`;

      console.log({
        endpoint,
        payload,
      });

      const { status, data } = await axios.post(endpoint, payload);

      if (status != 200)
        throw new Error(data.message || "Error procesando la compra.");
      // const response = data.data; //await dispatch("decodeResponse", data.data);
      return {
        success: true,
        message: { en: data.data.message, es: data.data.message },
        IDCOMPRA: data.data.IDCOMPRA,
      };
    } catch (error) {
      console.log("error", error?.message);
      const errorBackendData = error?.response?.data;
      console.log({errorBackendData});

      if(errorBackendData) {
        commit(
          "setErrorDialog",
          {
            errors: [{ en: errorBackendData?.message, es: errorBackendData?.message }],
            title: { en: "Registration error", es: "Error de registro" },
            /* subtitle: {
              en: "Dear customer, the capacity of some items has changed.",
              es:
                "Estimado cliente, la capacidad de algunos artículos ha variado.",
            }, */
            footer: {
              en: "Please reselect your items.",
              es: "Por favor, vuelva a seleccionar sus artículos.",
            },
          },
          { root: true }
        );
        commit("setDialogError", true, { root: true });
      }

      return {
        success: false,
        message: {
          en: errorBackendData?.message || error?.message,
          es: errorBackendData?.message || error?.message,
        },
        IDCOMPRA: null,
      };
    }
  },
  async updatePurchaseStatusFree(
    { rootState, rootGetters, dispatch },
    payload
  ) {
    try {
      if (!payload) return false;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCompra = payload.purchaseId;
      const idFormaPago = payload.paymentType;
      const endpoint = `/parques/${idParque}/compras/${idCompra}/cambioestado`;
      const { status, data } = await axios.put(endpoint, {
        idioma: rootState.lang,
        idSistema: 2,
        idEstado: 2,
        idFormaPago: idFormaPago,
        metadata: { result: "Venta Gratuita" },
      });

      if (status != 200) return false;

      if (!rootGetters.validateLotery) {
        await dispatch("fetchPurchaseDetail", {
          purchaseId: payload.purchaseId,
          parkId: idParque,
          sendMail: 1,
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  },
  async cancelPurchase({ dispatch }, payload) {
    try {
      if (!payload) return false;

      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCompra = payload.purchaseId;
      const idFormaPago = payload.paymentType;
      const endpoint = `/parques/${idParque}/compras/${idCompra}/cambioestado`;

      const { status, data } = await axios.put(endpoint, {
        idioma: rootState.lang,
        idSistema: 2,
        idEstado: 3,
        idFormaPago: idFormaPago,
        metadata: { result: "Compra cancelada" },
      });

      if (status != 200) return false;

      if (payload.noMessage) return true;

      const paymentError = {
        es: `<br>El medio de pago configurado está presentando fallas técnicas.<br>Por favor, vuelva a intentarlo más tarde.`,
        en: `<br>The configured payment method is experiencing technical failures.<br>Please try again later.`,
      };

      // Mensaje tu compra fue cancelada
      await dispatch("generateMessageStatus", {
        title: {
          en: "Your purchase could not be processed",
          es: "Tu compra no se pudo procesar",
        },
        type: "error",
        html: {
          es: `<p class="text-center"><span > Tu compra fue cancelada. </span> </p>`,
          en: `<p class="text-center"><span > Your purchase was canceled. </span> </p>`,
        },
        type: "error",
        confirmButtonText: {
          en: "Ok",
          es: "Aceptar",
        },
        footer: {
          es: `<p class="text-center"><span > <strong>Número de compra: </strong> ${payload.purchaseId}</span> </p>`,
          en: `<p class="text-center"><span > <strong>Purchase number: </strong> ${payload.purchaseId}</span> </p>`,
        },
        allowOutsideClick: false,
      });
      return true;
    } catch (error) {
      return false;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
