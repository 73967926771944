import "./assets/css/accesibility.css";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
// store.dispatch("validateSubdomain");
import router from "./router";
import vuetify, { changeVuetifyThemeColors } from "./plugins/vuetify";
import "./plugins/vMask";
import "./plugins/gAuth";
import "./plugins/v-viewer";
import { initAnimation, finalAnimation } from "./plugins/loaderAnimation";
import { setColorsApplication } from "./plugins/colors";

Vue.config.productionTip = false;

async function main() {
  initAnimation();

  await store.dispatch("destroyAdvance");
  await store.dispatch("loadLanguageFromLS");
  await store.dispatch("getGlobalData");
  await store.dispatch("getParksList");
  // await store.dispatch("validateSubdomain");
  // await store.dispatch("getParkByDomain");
  await store.dispatch("loadVisitedRoutes");
  await store.dispatch("loadUserFromLS");

  new Vue({
    store,
    vuetify,
    router,
    mounted() {
      setTimeout(() => {
        finalAnimation(() => {});
        store.state.accesibility.accTools.init();
        setColorsApplication(this);
      }, 1000);
    },
    render: (h) => h(App),
  }).$mount("#app");
}

main();
