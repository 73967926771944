<template>
    <a class="lm--social-link" target="_blank" :href="href || '#'" :title="title" >
        <i :class="icon"></i>
    </a>
</template>

<script>
    export default {
        props: {
            href: String,
            icon: String,
            title: String
        }
    }
</script>

<style lang="scss" scoped>
.lm--social-link {
    font-size: 20px;
    margin: 0 5px;
    font-weight: 400;
    transition: all ease-in-out .25s;
    color: #fff;
    &:hover {
        color: $color-secondary;
        margin-top: -3px;
    }

}
</style>