import axios from "@/api/entradaslima";
import { getCurrentDate, transformDate } from "../utils/date";

const state = () => ({
  horariosCategoria: [],
  horariosCategoriaAforo: [],
  horariosCategoriaDistinct: [],
  errorDialog: {
    active: false,
    errors: [],
    title: null,
    subtitle: null,
    footer: null
  }
});

const getters = {
  obtenerModoListadoCategorias(state, getters, rootState) {
    return rootState.currentDomainPark?.MODOLISTADOCATEGORIAS || 0;
  },
  obtenerHorariosArticulos(state) {
    return state.horariosCategoria;
  },
  obtenerHorariosCategoria(state) {
    return state.horariosCategoriaDistinct;
  },
  obtenerHorariosCategoriaValidacion(state) {
    return state.horariosCategoriaAforo;
  },
  obtenerEstadoDialogoError(state) {
    return state.errorDialog.active;
  },
  obtenerErrorDialogo(state) {
    return state.errorDialog;
  },
  permiteSolicitarFactura(state, getters, rootState) {
    return rootState.currentDomainPark?.PUEDESOLICITARFACTURA || 0;
  },
  permiteComprarModoInvitado(state, getters, rootState) {
    return Boolean(rootState.currentDomainPark?.MODOINVITADO || 0)
  }
};

const mutations = {
  setHorariosCategoria(state, horarios = []) {
    state.horariosCategoria = horarios;
    const mapperHorarios = new Map();
    horarios.forEach((horarioItem) => {
      mapperHorarios.set(
        `${horarioItem.HORAINICIO}_${horarioItem.HORAFIN}`,
        horarioItem
      );
    });
    state.horariosCategoriaDistinct = Array.from(
      mapperHorarios
    ).map(([key, value]) => ({ ...value }))
    ?.sort((a,b) => {
      // Convierte las horas de inicio a objetos Date para comparar
      const horaInicioA = new Date(`2000-01-01T${a.HORAINICIO}`);
      const horaInicioB = new Date(`2000-01-01T${b.HORAINICIO}`);
      // Compara las horas de inicio
      return horaInicioA - horaInicioB;
    });

    console.log({
      horarios: state.horariosCategoria,
      distinct: state.horariosCategoriaDistinct,
    });
  },
  setHorariosCategoriaValidacion(state, horarios = [])  {
    state.horariosCategoriaAforo = horarios;
  },
  setDialogError(state, status) {
    state.errorDialog.active = status;
    if(!status) {
      // reset data card error
      state.errorDialog.errors = [];
      state.errorDialog.title = null;
      state.errorDialog.subtitle = null;
      state.errorDialog.footer = null
    }
  },
  setErrorDialog(state, errorDialog) {
    state.errorDialog.errors = errorDialog?.errors ?? [];
    state.errorDialog.title = errorDialog?.title ?? null;
    state.errorDialog.subtitle = errorDialog?.subtitle ?? null;
    state.errorDialog.footer = errorDialog?.footer ?? null;
  }
};

const actions = {
  async getParkBySlug({ rootState }, slug = "") {
    const endpoint = `/parques/slug/${slug}`;
    const { status, data } = await axios.get(endpoint, {
      params: {
        idioma: rootState.lang,
        idSistema: 2,
      },
    });
    if (status != 200) throw new Error("No se encontró el parque");
    if (!data.data?.IDPARQUE) throw new Error("No se encontró el parque");
    return data.data;
  },
  async getParkById({ rootState }, idParque = "") {
    const endpoint = `/parques/${idParque}`;
    const { status, data } = await axios.get(endpoint, {
      params: {
        idioma: rootState.lang,
        idSistema: 2,
      },
    });
    if (status != 200) throw new Error("No se encontró el parque");
    if (!data.data?.IDPARQUE) throw new Error("No se encontró el parque");
    return data.data;
  },
  async getCategoriesByPark({ rootState, commit, getters, dispatch }) {
    const idParque = rootState.currentDomainPark.IDPARQUE;
    const endpoint = `/parques/${idParque}/categorias`;
    const { status, data } = await axios.get(endpoint, {
      params: {
        idioma: rootState.lang,
        idSistema: 2,
      },
    });
    if (status != 200) return null;
    const response = data.data;
    commit("setCategoriesPark", response, { root: true });
    console.log("categories", getters.getCategoriesPark);
    if (rootState.sale.finalPayload.category) {
      rootState.sale.finalPayload.category = response.find(
        (cat) =>
          cat.IDCATEGORIA == rootState.sale.finalPayload.category.IDCATEGORIA
      );
    }
  },
  async loadDates({ rootState, dispatch }) {
    const park = rootState.currentDomainPark;
    const category = rootState.sale.finalPayload.category;

    if (category.IDEVENTO != 0) {
      await dispatch("getEventsDate");
    } else {
      await dispatch("getDatesList");
    }

    if (park.DIASHABILITADOSMAXIMO > park.DIASHABILITADOS) {
      await dispatch("getCalendarList");
    }
  },
  async getDatesList({ rootState, commit, dispatch }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const diasHabilitados = rootState.currentDomainPark.DIASHABILITADOS;
      const diasHabilitadosMaximo =
        rootState.currentDomainPark.DIASHABILITADOSMAXIMO;

      const fechaVisita = rootState.sale.finalPayload.category
        ? rootState.sale.finalPayload.category.FECHADEFAULT || getCurrentDate()
        : getCurrentDate();

      const endpoint = `/parques/${idParque}/fechasvisita`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          diasHabilitados,
          diasHabilitadosMaximo,
          fechaVisita: transformDate({ date: fechaVisita }),
        },
      });
      if (status != 200) return null;
      const response = data.data;
      commit("setDatesList", response, { root: true });
    } catch (error) {}
  },
  async getEventsDate({ rootState, commit, dispatch }, idEvento = "") {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      idEvento = rootState.sale.finalPayload.category
        ? rootState.sale.finalPayload.category.IDEVENTO
        : idEvento;
      const fechaVisita = rootState.sale.finalPayload.category
        ? rootState.sale.finalPayload.category.FECHADEFAULT || getCurrentDate()
        : getCurrentDate();
      const endpoint = `/parques/${idParque}/eventos/${idEvento}/fechasvisita`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          fechaVisita: transformDate({ date: fechaVisita }),
        },
      });
      if (status != 200) return null;
      const response = data.data;
      commit("setDatesList", response, { root: true });
      return response;
    } catch (err) {}
  },
  async getCalendarList({ rootState, commit, dispatch }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const fechaVisita = getCurrentDate();
      const diasHabilitadosMaximo =
        rootState.currentDomainPark.DIASHABILITADOSMAXIMO;
      const endpoint = `/parques/${idParque}/fechasvisita/calendario`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          diasHabilitadosMaximo,
          fechaVisita: transformDate({ date: fechaVisita }),
        },
      });
      if (status != 200) return null;
      const response = data.data;
      commit("setCalendarList", response, { root: true });
      return response;
    } catch (error) {}
  },
  async loadArticlesList({ rootState, dispatch, rootGetters }) {
    let defaultDate = "";
    defaultDate = rootState.sale.finalPayload.category
      ? rootState.sale.finalPayload.category.FECHADEFAULT
      : "";
    if (rootGetters.haveControlStock) {
      await dispatch("getArticlesStock", { defaultDate });
    } else {
      await dispatch("getArticlesList", { defaultDate });
    }
  },
  async getArticlesList(
    { rootState, commit, dispatch },
    { categoryId, defaultDate }
  ) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria =
        rootState.sale.finalPayload.category.IDCATEGORIA || categoryId;
      const fechaVisita =
        rootState.sale.finalPayload.dateSelected ||
        defaultDate ||
        getCurrentDate();
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos`;

      console.log({ fechaVisita });

      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          fechaVisita: transformDate({ date: fechaVisita }),
        },
      });
      if (status != 200) return null;
      const response = data.data;
      const articles = await dispatch("formatArticles", response);
      rootState.articlesList = [];
      commit("setArticlesList", articles, { root: true });
    } catch (error) {}
  },
  async getArticlesStock({ rootState, commit, dispatch }, { defaultDate }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria =
        rootState.sale.finalPayload.category.IDCATEGORIA || categoryId;
      const idArticulo = 0;
      const fechaVisita =
        rootState.sale.finalPayload.dateSelected ||
        defaultDate ||
        getCurrentDate();
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`;

      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          fechaVisita: transformDate({ date: fechaVisita }),
        },
      });
      if (status != 200) return null;
      const response = data.data;
      const articles = await dispatch("formatArticles", response);
      rootState.articlesList = [];
      commit("setArticlesList", articles, { root: true });
    } catch (error) {}
  },
  async fetchArticlesOnDateChange(
    { rootState, dispatch },
    { categoryId, stock, idPark = "", visitDate }
  ) {
    try {
      const idParque = idPark || rootState.currentDomainPark.IDPARQUE;
      const idCategoria = categoryId;
      const idArticulo = stock ? 0 : undefined;
      const fechaVisita = visitDate || getCurrentDate(); //""; // modificado

      const endpoint = stock
        ? `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`
        : `/parques/${idParque}/categorias/${idCategoria}/articulos`;

      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          fechaVisita: transformDate({ date: fechaVisita }),
          idArticulo,
        },
      });
      if (status != 200) return null;
      const response = data.data;
      return response;
    } catch (error) {
      return null;
    }
  },
  async validateArticleStock(
    { rootState, dispatch },
    { categoryId, articleId, quantity, visitDate }
  ) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria = categoryId;
      const idArticulo = articleId;
      const fechaVisita = visitDate;

      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`;

      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          fechaVisita: transformDate({ date: fechaVisita }),
        },
      });
      if (status != 200) return false;
      const response = data.data; // await dispatch("decodeResponse", data.data);
      if (!response[0]) return false;

      const avaible =
        parseFloat(response[0].CANTIDADSTOCK) -
        parseFloat(response[0].CANTIDADCOMPRADA);

      if (quantity <= avaible) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  },
  async validateStock(
    { rootState, commit, dispatch },
    { article, parentArticle }
  ) {
    try {
      if (!article) return null;
      if (!rootState.sale.finalPayload.category) return null;
      if (rootState.sale.finalPayload.category.CONTROLSTOCK == "0") return null;
      // Parámetros
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria =
        rootState.sale.finalPayload.category.IDCATEGORIA || categoryId;
      const idArticulo = article.IDARTICULO;
      const fechaVisita =
        rootState.sale.finalPayload.dateSelected || getCurrentDate();
      // Endpoint
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/stock`;
      // Consulta Servicio
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          fechaVisita: transformDate({ date: fechaVisita }),
        },
      });
      if (status != 200) return null;
      const response = await data.data;
      if (!response[0]) return null;
      const idx = rootState.articlesList.findIndex(
        (ar) => ar.IDARTICULO == parentArticle.IDARTICULO
      );
      if (idx > -1) {
        rootState.articlesList[idx]["CANTIDADSTOCKDISPONIBLE"] =
          response[0]["CANTIDADSTOCK"] - response[0]["CANTIDADCOMPRADA"];
      }
      commit("updateBaseIndex", { root: true });
      return response[0];
    } catch (error) {
      return null;
    }
  },
  async formatArticles({ commit, dispatch, rootGetters }, list) {
    // Si no agrupa
    if (
      rootGetters.getCategorySelected &&
      rootGetters.getCategorySelected.AGRUPAR == "0"
    ) {
      return list.map((item) => {
        return {
          ...item,
          LEVELS: 1,
          SUBOBJETOS: null,
          CANTIDADSELECCIONADA: 0,
        };
      });
    }

    // Si es que agrupa
    let finalArr = [];
    list.forEach((item, idx) => {
      const arr = item.DESCRIPCIONCORTA.split("/");

      let obj;
      let exist;
      switch (arr.length) {
        case 1:
          // Un nivel
          obj = { ...item };
          obj.CANTIDADSELECCIONADA = 0;
          obj.DESCRIPCIONCORTA = arr[0];
          obj.SUBOBJETOS = null;
          obj.LEVELS = 1;
          finalArr.push(obj);
          break;
        case 2:
          // Dos niveles
          exist = finalArr.find((it) => it.DESCRIPCIONCORTA == arr[0]);
          obj = exist || { ...item };
          obj.CANTIDADSELECCIONADA = 0;
          obj.DESCRIPCIONCORTA = arr[0];
          obj.SUBOBJETOS = obj.SUBOBJETOS || [];
          obj.LEVELS = 2;
          if (!exist) {
            // Si no existe en arreglo
            finalArr.push(obj);
          }
          if (
            obj.SUBOBJETOS &&
            !obj.SUBOBJETOS.find((it) => it.NAME == arr[1])
          ) {
            obj.SUBOBJETOS.push({
              ...item,
              IDARTICULO: item.IDARTICULO,
              NAME: arr[1],
              VALUE: obj.SUBOBJETOS.length + 1 || 1,
            });
          }
          break;
        case 3:
          // Dos niveles
          exist = finalArr.find((it) => it.DESCRIPCIONCORTA == arr[0]);
          obj = exist || { ...item };
          obj.CANTIDADSELECCIONADA = 0;
          obj.DESCRIPCIONCORTA = arr[0];
          obj.LEVELS = 3;
          obj.SUBOBJETOS = obj.SUBOBJETOS || [];
          if (!exist) {
            // Si no existe en arreglo
            finalArr.push(obj);
          }

          const idx = obj.SUBOBJETOS.findIndex((it) => it.NAME == arr[1]);

          if (idx == -1) {
            obj.SUBOBJETOS.push({
              ...item,
              IDARTICULO: item.IDARTICULO,
              NAME: arr[1],
              VALUE: obj.SUBOBJETOS.length + 1 || 1,
              SUBOBJETOS: [
                {
                  ...item,
                  IDARTICULO: item.IDARTICULO,
                  NAME: arr[2],
                  VALUE: 1,
                },
              ],
            });
          } else {
            obj.SUBOBJETOS[idx].SUBOBJETOS.push({
              ...item,
              IDARTICULO: item.IDARTICULO,
              NAME: arr[2],
              VALUE: obj.SUBOBJETOS[idx].SUBOBJETOS.length + 1,
            });
          }
          break;
      }
    });

    await dispatch("setMaxQuantity");
    return finalArr;
  },
  async getUniqueArticleIdSelected({ rootState, dispatch }) {
    // Obtiene el único id de articulo elegido
    const article = rootState.articlesList.find(
      (art) => art.CANTIDADSELECCIONADA > 0
    );
    if (!article) return 0;
    const articleId = await dispatch("getArticleId", article);
    return articleId;
  },
  getArticleId({ commit }, article) {
    // Busca articulo en un articulo anidado
    if (article.LEVELS == 1) {
      return article.IDARTICULO;
    } else if (article.LEVELS == 2) {
      return article.selectedLvl1 ? article.selectedLvl1.IDARTICULO : "";
    } else {
      return article.selectedLvl2 ? article.selectedLvl2.IDARTICULO : "";
    }
  },
  async setMaxQuantity({ rootState, commit, dispatch }) {
    const promises = rootState.articlesList.map((it) => {
      // seteando cantidad final
      return new Promise((resolve, reject) => {
        dispatch("getMaxQuantity", it).then((max) => {
          it.CANTIDADMAXIMAFINAL = max;
          resolve();
        });
      });
    });
    await Promise.all(promises);
    commit("updateBaseIndex", { root: true });
  },
  async getAditionalProccess(
    { rootState, commit, dispatch, rootGetters },
    { categoryId = "", paramArticle = false, idArticle }
  ) {
    try {
      let articleId = "";
      if (!rootGetters.validateCombination && !paramArticle) {
        articleId = await dispatch("getUniqueArticleIdSelected");
        categoryId = rootState.sale.finalPayload.category.IDCATEGORIA;
      }
      // Parámetros
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria = categoryId;
      const idArticulo = idArticle || articleId || 0;

      // Endpoint
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/configuracion`;

      // Consulta Servicio
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
        },
      });

      if (status != 200)
        return {
          haveError: true,
          haveSchedules: false,
          haveRoutes: false,
          isOnlyAforo: false,
        };

      const config = data.data;

      if (config.AFORO == 0 && config.HORARIO == 0 && config.RUTA == 0)
        return {
          haveSchedules: false,
          haveRoutes: false,
          isOnlyAforo: false,
        };

      if (config.AFORO == 1 && config.HORARIO == 0 && config.RUTA == 0)
        return { haveSchedules: false, haveRoutes: false, isOnlyAforo: true };

      if (config.AFORO > 0 && config.RUTA > 0)
        return { haveSchedules: true, haveRoutes: true, isOnlyAforo: false };

      if (config.AFORO > 0 && config.HORARIO > 0)
        return { haveSchedules: true, haveRoutes: false, isOnlyAforo: false };

      return {
        haveError: false,
        haveSchedules: false,
        haveRoutes: false,
        isOnlyAforo: false,
      };
    } catch (error) {
      return {
        haveError: true,
        haveSchedules: false,
        haveRoutes: false,
        isOnlyAforo: false,
      };
    }
  },
  async validateAditionalProccess(
    { rootState, commit, dispatch, rootGetters },
    { categoryId = "", paramArticle = false, idArticle }
  ) {
    try {
      let articleId = "";
      if (!rootGetters.validateCombination && !paramArticle) {
        articleId = await dispatch("getUniqueArticleIdSelected");
        categoryId = rootState.sale.finalPayload.category.IDCATEGORIA;
      }
      // Parámetros
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria = categoryId;
      const idArticulo = idArticle || articleId || 0;

      // Endpoint
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/configuracion`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
        },
      });

      if (status != 200) {
        commit(
          "setConfigurationProcess",
          {
            HORARIO: 0,
            RUTA: 0,
          },
          { root: true }
        );
        return { AFORO: 0, RUTA: 0, HORARIO: 0 };
      }

      const response = data.data;

      commit(
        "setConfigurationProcess",
        {
          HORARIO: response.HORARIO,
          RUTA: response.RUTA,
        },
        { root: true }
      );

      return response;
    } catch (error) {
      commit(
        "setConfigurationProcess",
        {
          HORARIO: 0,
          RUTA: 0,
        },
        { root: true }
      );
      return { AFORO: 0, RUTA: 0, HORARIO: 0 };
    }
  },
  async validateAforo({ rootState, rootGetters, dispatch }, config) {
    if (!config) return false;

    const { CANTIDAD, AFORO, ENABLED } = config;

    if(ENABLED != 1) return false;

    if (!config.TOTAL) {
      if (rootGetters.getTotalPeople > parseFloat(CANTIDAD)) return false;
    } else {
      if (config.TOTAL > parseFloat(CANTIDAD)) return false;
    }

    return true;
  },
  async fetchSchedules({ rootState, rootGetters, commit, dispatch }, config) {
    try {
      //if (!rootState.sale.finalPayload.dateSelected) return;
      const articleId = await dispatch("getUniqueArticleIdSelected");
      // Parámetros
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idCategoria = config ? config.categoryId : 0;
      const idArticulo =
        config && config.articleId
          ? config.articleId
          : rootGetters.validateCombination
          ? 0
          : articleId;
      const idRuta = config && config.routeId ? config.routeId : 0;
      const fechaVisita = config
        ? config.dateSelected
        : rootState.sale.finalPayload.dateSelected;
      const horaInicio = config.startTime ? config.startTime : "";
      const horaFin = config.endTime ? config.endTime : "";
      const opcion = config ? config.option : 1;
      // Endpoint
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/horarios`;
      // Consulta Servicio
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          idRuta,
          fechaVisita: transformDate({ date: fechaVisita }),
          horaInicio,
          horaFin,
          opcion,
        },
      });
      if (status != 200) return null;

      const response = data.data;

      if (config && config.setList == true) {
        commit("setSchedules", response, { root: true });
      }
      return response;
    } catch (error) {
      return null;
    }
  },
  async fetchFAQs({ rootState, commit, dispatch }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/preguntasyrespuestas`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
        },
      });
      if (status != 200) return null;
      const response = data.data;
      commit("setFAQs", response, { root: true });
    } catch (error) {}
  },
  async fetchTermsAndConditions({ rootState, commit }) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/terminosycondiciones`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
        },
      });
      if (status != 200) return null;
      commit("setTermsAndConditions", data.data?.TERMINOSYCONDICIONES, {
        root: true,
      });
    } catch (error) {}
  },
  async fetchCategoriaHorarios(
    { state, rootState, commit },
    { idCategoria, opcion = 1, fechaVisita, horaInicio = undefined, horaFin = undefined, setListaInicial = false }
  ) {
    try {
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/horarios`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma: rootState.lang,
          opcion: opcion,
          fechaVisita: transformDate({ date: fechaVisita }),
          horaInicio,
          horaFin
        },
      });
      if (status != 200) return null;
      setListaInicial && commit("setHorariosCategoria", data.data);
      commit("setHorariosCategoriaValidacion", data.data);
      return state.horariosCategoriaDistinct;
    } catch (error) {
      commit("setHorariosCategoriaValidacion", []);
      return [];
    }
  },
  async fetchHorarioButacas(
    { rootState },
    { fechaVisita, horaInicio, horaFin, idCategoria, idArticulo }
  ) {
    try {
      const idioma = rootState.lang;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/horarios/butacas`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma,
          fechaVisita: transformDate({ date: fechaVisita }),
          horaInicio,
          horaFin,
        },
      });
      if (status != 200) return [];
      return data.data;
    } catch (error) {
      return [];
    }
  },
  async fetchHorarioButaca(
    { rootState },
    { fechaVisita, horaInicio, horaFin, idCategoria, idArticulo, idButaca }
  ) {
    try {
      const idioma = rootState.lang;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulo}/horarios/butacas/${idButaca}`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma,
          fechaVisita: transformDate({ date: fechaVisita }),
          horaInicio,
          horaFin,
        },
      });
      if (status != 200) return null;
      return data.data;
    } catch (error) {
      return null;
    }
  },
  async fetchEstadoButacasMultiple(
    { rootState },
    { fechaVisita, horaInicio, horaFin, idCategoria, articulos = [], butacas = [] }
  ) {
    try {
      const idioma = rootState.lang;
      const idParque = rootState.currentDomainPark.IDPARQUE;
      const idArticulos = articulos?.join(",");
      const idButacas = butacas?.join(",");
      const endpoint = `/parques/${idParque}/categorias/${idCategoria}/articulos/${idArticulos}/horarios/butacas/${idButacas}/masivo`;
      const { status, data } = await axios.get(endpoint, {
        params: {
          idioma,
          fechaVisita: transformDate({ date: fechaVisita }),
          horaInicio,
          horaFin,
        },
      });
      if (status != 200) return null;
      return data.data;
    } catch (error) {
      return null;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
