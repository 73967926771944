import AccesibilityTool from "../assets/js/accesibility";

const state = () => ({
  accTools: new AccesibilityTool()
});

const getters = {
  getAccesibilityTool: state => state.accTools
};

const mutations = {};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
