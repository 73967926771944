import { preetyTimeString } from "@/libs/timer";

const state = () => ({
  expiresTime: null,
  currentTimeString: "00:00:00",
  inProgress: false,
  isPaused: false,
  maxTime: 10, //minutes
  timeOutTimer: null
});

const getters = {
  getCurrentTimeString(state) {
    return state.currentTimeString;
  },
  getInProgress(state) {
    return state.inProgress;
  }
};

const mutations = {
  setExpiresTime(state, exp) {
    state.expiresTime = expiresTime;
  },
  setInProgressTime(state, bool) {
    state.inProgress = bool;
  },
  setCurrentTimeString(state, time) {
    state.currentTimeString = time;
  },
  setPausedTimer(state, bool) {
    state.isPaused = bool;
  }
};

const actions = {
  saveTimeOnLS({ state, commit }) {
    const currentDate = new Date();
    const minutes = currentDate.getMinutes();
    currentDate.setMinutes(minutes + state.maxTime);
    const milliseconds = currentDate.getTime();
    localStorage.setItem("shop_m_session", milliseconds);
  },
  async getTimeOnLS({ state, commit, dispatch }) {
    let time = state.expiresTime || localStorage.getItem("shop_m_session");
    if (!time) {
      await dispatch("saveTimeOnLS");
      state.expiresTime = state.maxTime * 60000;
      return state.maxTime * 60000;
    }
    time = parseFloat(time);
    const currentTime = new Date().getTime();
    if (time <= currentTime) {
      await dispatch("saveTimeOnLS");
      state.expiresTime = state.maxTime * 60000;
      return state.maxTime * 60000;
    } else {
      state.expiresTime = time - currentTime;
      return time - currentTime;
    }
  },
  async initTimer({ state, commit, dispatch }, millisecondsProp) {
    try {
      commit("setInProgressTime", true);
      let milliseconds = millisecondsProp;
      if (!milliseconds) {
        milliseconds = await dispatch("getTimeOnLS");
      }
      let totalSeconds = milliseconds / 1000;
      let diff = milliseconds - 1000;

      let hours = Math.floor(totalSeconds / 3600);
      totalSeconds = totalSeconds % 3600;

      let minutes = Math.floor(totalSeconds / 60);
      totalSeconds = totalSeconds % 60;

      let seconds = Math.floor(totalSeconds);

      // Pad the minutes and seconds with leading zeros, if required
      hours = preetyTimeString(hours);
      minutes = preetyTimeString(minutes);
      seconds = preetyTimeString(seconds);

      if (state.isPaused) return;

      if (diff > 0) {
        commit("setInProgressTime", false);
        state.timeOutTimer = setTimeout(() => {
          dispatch("initTimer", diff);
        }, 1000);
      } else {
        clearTimeout(state.timeOutTimer);
        commit("setCurrentTimeString", "00:00:00");
        commit("setInProgressTime", false);
        // destroy changes
        this.dispatch("destroyAdvance");
        return;
      }

      const currentTimeString = hours + ":" + minutes + ":" + seconds;
      commit("setCurrentTimeString", currentTimeString);
      return currentTimeString;
    } catch (error) {}
  },
  pauseTimer({ commit }) {
    commit("setPausedTimer", true);
  },
  unPauseTimer({ commit, dispatch }) {
    commit("setPausedTimer", false);
  },
  cancelTimer({ state, commit }) {
    commit("setCurrentTimeString", "00:00:00");
    commit("setInProgressTime", false);
    localStorage.removeItem("shop_m_session");
    clearTimeout(state.timeOutTimer);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
