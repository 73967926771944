const DOMAINS = require("../assets/top-level-domain-names.json")

export const emailValidator = (v) => {
    const arrEmail = v.split(".")
    const arrEmailFromSymbol = v.split("@")
    const finalStr = arrEmail[arrEmail.length - 1]
    const firstLetters = arrEmailFromSymbol[0]
    const finalLetters = arrEmailFromSymbol[arrEmailFromSymbol.length - 1]
    const lastChar = v.charAt(v.length - 1)

    const isABasicEmail = /.+@.+\..+/.test(v)
    const notFinalNumber = !/.+[0-9]+/.test(finalStr)
    const isAOneSymbol = arrEmailFromSymbol.length <= 2;
    const notSpecialCharBeforeSymbol = !/[`~¡!@#$%^&*()|+\=?;:'",<>☻♥☻♦♣♠◘•○°\{\}\[\]\\\/]/.test(firstLetters)
    const notFinalSpecialChar = !/[`~¡!@#$%^&*()_|+\-=?;:'",<>☻♥☻♦♣♠◘•○°\{\}\[\]\\\/]/.test(finalStr)
    const notFinalSpecialCharAfterSymbol = !/[`~¡!@#$%^&*()_|+\-=?;:'",<>☻♥☻♦♣♠◘•○°\{\}\[\]\\\/]/.test(finalLetters)
    const notFinalDot = lastChar != '.'

    const isInDomainsList = DOMAINS.includes(`.${finalStr}`)

    return isABasicEmail && notFinalNumber && isAOneSymbol && notFinalSpecialChar && notSpecialCharBeforeSymbol && notFinalSpecialCharAfterSymbol && notFinalDot && isInDomainsList
}