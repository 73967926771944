import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { es } from "vuetify/lib/locale";
import { convertRgbToHex } from "../libs/colors";

Vue.use(Vuetify);

export function changeVuetifyThemeColors(colors = {}) {
  const root = document.documentElement;
  const theme = {
    primary_1: colors.COLOR_SISTEMA_1 || "rgb(0, 163, 156)",
    primary_2: colors.COLOR_SISTEMA_2 || "rgb(252, 167, 0)",
    primary_3: colors.COLOR_SISTEMA_3 || "rgb(26, 74, 132)",
    primary_4: colors.COLOR_SISTEMA_4 || "rgb(255, 54, 101)",
  };

  this.$vuetify.theme.themes.dark.primary = convertRgbToHex(theme.primary_1);
  this.$vuetify.theme.themes.light.primary = convertRgbToHex(theme.primary_1);
  this.$vuetify.theme.themes.dark.secondary = convertRgbToHex(theme.primary_2);
  this.$vuetify.theme.themes.light.secondary = convertRgbToHex(theme.primary_2);
  this.$vuetify.theme.themes.dark.tertiary = convertRgbToHex(theme.primary_3);
  this.$vuetify.theme.themes.light.tertiary = convertRgbToHex(theme.primary_3);
  root.style.setProperty("--color-primary-1", theme.primary_1);
  root.style.setProperty("--color-primary-2", theme.primary_2);
  root.style.setProperty("--color-primary-3", theme.primary_3);
  root.style.setProperty("--color-primary-4", theme.primary_4);

  const fnExtractNumbersRgb = (rgb = "") => rgb.replace("rgb(", "").replace(")", "");

  // Seteando los colores rgb como variables numéricas 0,163,156
  root.style.setProperty("--variant-color-primary-1", fnExtractNumbersRgb(theme.primary_1));
  root.style.setProperty("--variant-color-primary-2", fnExtractNumbersRgb(theme.primary_2));
  root.style.setProperty("--variant-color-primary-3", fnExtractNumbersRgb(theme.primary_3));
  root.style.setProperty("--variant-color-primary-4", fnExtractNumbersRgb(theme.primary_4));
}

export default new Vuetify({
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    //dark: true,
    themes: {
      light: {
        primary: convertRgbToHex("rgb(0, 163, 156)"), //Turquesa
        secondary: "#FCA700", //Naranja
        tertiary: "#1A4A84", //Azul
        black: "#000", // Negro
        white: "#fff", //blanco
        error: "#AF0000",
      },
      dark: {
        primary: convertRgbToHex("rgb(0, 163, 156)"), //Turquesa
        secondary: "#FCA700", //Naranja
        tertiary: "#1A4A84", //Azul
        black: "#000", // Negro
        white: "#fff", //blanco
        error: "#AF0000",
      },
    },
  },
});
