<template>
  <v-snackbar v-model="snackbar.value" :timeout="snackbar.timeout"  color="#af0000" elevation="0" class="snackbar-error">
    <div class="d-flex align-items-center">
      <div class="icon">
        <v-icon>error</v-icon>
      </div>
      <ul class="warnings-list">
        <li v-for="(item, idx) in errors" :key="idx + 895658">
            {{ item[lang] }}
        </li>
      </ul>
    </div>
    <template v-slot:action="{ attrs }">
      <v-btn dark icon small v-bind="attrs" @click="$store.commit('changeSnackbar', false)" :title="textBtn[lang]">
        <v-icon small>cancel</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      snackbar: "getSnackbar",
      errors: "getErrorsList",
      lang: "getLanguage",
    }),
  },
  data() {
      return {
          textBtn: {
              es: "Cerrar",
              en: "Close"
          }
      }
  }
};
</script>

<style lang="scss">

.snackbar-error {
  @media screen and (max-width: 600px) {
    .v-snack__wrapper {
      margin-bottom: 45px !important;
    }
  }
}
.warnings-list {
  list-style: none;
  padding-left: 12px !important;
  li {
    font-size: 13px;
    font-family: $primary-font;
  }
}
</style>