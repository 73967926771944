export const verifyDomain = () => {
    const hostname = getDomain();
    const points = hostname.split(".").length;
    return points < 3;
}


export const getDomain = () => {
    let hostname = process.env.VUE_APP_DEFAULT_DOMAIN_PARK || window.location.host; // Dejar vacio el env VUE_APP_DEFAULT_DOMAIN_PARK para producción
    console.log(hostname);
    const haveWWW = hostname.indexOf("www");
    if (haveWWW) {
        hostname.replace("www.", "")
    }
    return hostname;
}