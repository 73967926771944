<template>
  <div
    class="lm--card-site lm--bg-image"
    :style="`background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url( ${
      img ? getImg(img) : 'http://visitalima.pe/wp-content/uploads/2020/09/632x980-CATEDRAL.jpg'
    })`"
  >
    <div class="more-info">
      <button type="button" @click="openModalSite(object)" class="button-info readable" :title="btnInfo[lang]">
        <i class="fas fa-info"></i>
      </button>
    </div>
    <LinkWrapper class="link-inside" :paramsRoute="getOptionsURL(object)">
      <template slot="content">
        <div class="lm--content-card-site">
          <h3 class="lm--card-title">{{ name ? name : "Parque de las leyendas" }}</h3>
          <p class="lm--card-description">
            {{ phrase || "" }}
          </p>
          <div class="lm--card-actions">
            <!-- ENLACE LOCAL -->
            <router-link
              v-if="object.PADRE != '0'"
              class="lm--card-link"
              :to="{ name: 'Parks', params: { parent_name: object.ABREVIATURA } }"
            >
              <span> {{ linkPhase ? linkPhase : "Ver más parques" }}</span>
              <i class="fas fa-external-link-alt"></i>
            </router-link>
            <!-- ENLACE SUBDOMINIO  => CAMBIAR ESTADO DESARROLLO A 2 -->
            <a
              v-if="object.PADRE == '0' && object.IDESTADODESARROLLO == 2" 
              target="_blank"
              :href="uriPay ? `http://${uriPay}` : '#'"
              class="lm--card-link"
            >
              <span> {{ linkPhase ? linkPhase : "Compra en linea aquí" }}</span>
              <i class="fas fa-external-link-alt"></i>
            </a>
            <!-- TEXTOS ALTERNATIVOS -->
            <template>
              <v-chip
                dark
                class="mt-2"
                color="orange"
                small
                v-if="object.PADRE == '0' && object.IDESTADODESARROLLO == 3"
                >{{ maintanceText }}</v-chip
              >
              <v-chip
                dark
                class="mt-2"
                color="green"
                small
                v-if="object.PADRE == '0' && object.IDESTADODESARROLLO == 4"
                >{{ commingText }}</v-chip
              >
            </template>
          </div>
        </div>
        <div class="lm--logo-card">
          <img
            draggable="false"
            :src="
              logo
                ? getImg(logo)
                : 'https://entradaslima.pe/repositorio/parques/patpal/galeria/16122020055618.png'
            "
            alt=""
          />
        </div>
      </template>
    </LinkWrapper>
  </div>
</template>

<script>
import LinkWrapper from "@/components/link/LinkWrapper";
import { imgService } from "@/libs/media"
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LinkWrapper,
  },
  props: {
    img: String,
    name: String,
    logo: String,
    phrase: String,
    uriPay: String,
    linkPhase: String,
    object: Object,
  },
  data() {
    return {
      btnInfo: {
        en: "See more information",
        es: "Ver más información"
      }
    }
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage"
    }),
    ...mapState({
      commingText: (state) => state.statusSite.commingText[state.lang],
      maintanceText: (state) => state.statusSite.maintanceText[state.lang],
    }),
  },
  methods: {
    openModalSite(item) {
      this.$store.dispatch("changeCurrentPark", item);
      this.$store.dispatch("openModal");
    },
    getOptionsURL(obj) {
      if (obj.PADRE != "0") {
        return {
          type: "local",
          externalURL: "#",
          params: { name: "Parks", params: { parent_name: obj.ABREVIATURA } },
        };
      } else if ((obj.PADRE == "0" && obj.IDESTADODESARROLLO == 2) || (obj.PADRE == "0" && obj.SUBDOMINIO.indexOf(".limaentradas.pe")) ) { // CAMBIAR A 2
        /* return {
          type: "external",
          externalURL: this.uriPay ? `//${this.uriPay}` : "#",
          params: "#",
        }; */
        return {
          type: "local",
          externalURL: "#",
          params: { name: "HomeSale", params: { park_slug: obj.SLUG } },
        };
      } else {
        return {
          type: "local",
          externalURL: "#",
          params: "#",
        };
      }
    },
    getImg(filename) {
      return imgService(filename)
    }
  },
};
</script>

<style lang="scss" scoped>
.lm--card-site {
  width: 100%;
  height: 400px;
  
  margin: 0 auto;
  /* display: flex;
  align-items: flex-end;
  padding: 1.55em 1em; */
  border-radius: 15px;
  overflow: hidden;
  font-family: $primary-font;
  transition: all ease-in-out 0.2s;
  text-decoration: none;
  position: relative;
  padding: 0 ;
  @media screen and (max-width: 500px) {
    max-width: 350px;
  }
  &::after,
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: scale3d(0, 0, 1);
    transition: transform 0.3s ease-out 0s;
    background: rgba(0, 163, 156, 0.1);
    pointer-events: none;
  }
  &::before {
    transform-origin: left top;
  }
  &::after {
    transform-origin: right bottom;
  }
  &:hover {
    padding: .8em 0;
    .lm--content-card-site {
      margin-bottom: 0;
      position: relative;
      z-index: 2;
    }
    .button-info {
      animation: shadow-pulse 1s infinite;
    }
    &::before,
    &::after {
      transform: scale3d(1, 1, 1);
    }
  }
  .more-info {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    .button-info {
      outline: none;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: $color-tertiary;
      color: #fff;
      transition: all ease-in-out 0.2s;
      &:hover {
        animation: shadow-pulse-2 1s infinite;
      }
    }
  }
  .lm--content-card-site {
    color: #fff;
    margin-bottom: 0px;
    transition: all ease-in-out 0.25s;

    .lm--card-title {
      font-weight: 600;
    }
    .lm--card-description {
      margin-bottom: 0;
      color: rgba(255, 255, 255, 0.92);
      font-size: 13px;
    }
    .lm--card-actions {
      padding-top: 30px;
      .lm--card-link {
        height: 20px;
        color: $color-secondary;
        /*  &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 100;
          display: block;
        } */
        span {
          font-size: 14px;
          font-weight: 600;
          text-decoration: underline;
        }
        i {
          margin-left: 10px;
        }
      }
    }
  }
  .lm--logo-card {
    position: absolute;
    top: 0;
    background: #fff;
    left: 0;
    padding: 0.8em;
    border-radius: 0 0 15px 0;
    max-height: 60px;
    transition: all ease-in-out 0.25s;
    img {
      max-height: 30px;
    }
  }
}

.lm--bg-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(26, 74, 132, 0.38);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(226, 167, 16, 0);
  }
}

@keyframes shadow-pulse-2 {
  0% {
    box-shadow: 0 0 0 0px rgba(26, 74, 132, 0.58);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(226, 167, 16, 0);
  }
}
</style>
