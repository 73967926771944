<template>
  <!-- PROFILE INFO -->
  <div class="auth-info">
    <div class="info-user">
      <img
        draggable="false"
        :src="
          user && user.URLFOTO
            ? user.URLFOTO
            : 'https://image.freepik.com/vector-gratis/perfil-avatar-hombre-icono-redondo_24640-14044.jpg'
        "
        :alt="user ? `${user.NOMBRES} ${user.APELLIDOS}` : 'Imagen prueba usuario'"
      >
      <div class="user">
        <h6 class="text-dots" style="font-size: 13px">
          {{ user ? `${user.NOMBRES} ${user.APELLIDOS}` : "Incógnito" }}
        </h6>
        <small class="text-dots" style="font-size: 11px">{{
          user ? user.CORREO : "------"
        }}</small>
      </div>
    </div>
    <ul class="pl-0">
      <li>
        <a class="link-auth-menu" @click.prevent="$emit('closeMenu')">
          <p class="mb-1">
            <i class="far fa-user-circle"></i>
            <span>Perfil</span>
          </p>
        </a>
      </li>
    </ul>
    <ul class="btn-list">
      <li>
        <button
          class="btn-menu btn-sign-out"
          @click="$store.dispatch('logout')"
        >
          <i class="fas fa-power-off"></i>
          <span>Cerrar sesión</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    user: Object,
  },
};
</script>

<style lang="scss">
.auth-info {
  @media screen and (max-width: 500px) {
    max-width: 280px;
    margin: 10px auto;
  }
}
</style>